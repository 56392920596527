import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.custom_styles.font_family')

const FONT_FAMILIES = [
  { name: 'Arial', value: 'Arial, sans-serif' },
  { name: 'DM Sans', value: 'DM Sans, "SF Pro Display", "Helvetica Neue", Helvetica, Arial, sans-serif' },
  { name: 'Georgia', value: 'Georgia, serif' },
  { name: 'Helvetica', value: '"Helvetica Neue", Helvetica, sans-serif' },
  { name: 'Impact', value: 'Impact, "SF Pro Display", "Helvetica Neue", Helvetica, Arial, sans-serif' },
  { name: 'Inter', value: '"Inter", "SF Pro Display", "Helvetica Neue", Helvetica, Arial, sans-serif' },
  { name: 'Roboto', value: 'Roboto, "SF Pro Display", "Helvetica Neue", Helvetica, Arial, sans-serif' },
  { name: 'Saira', value: '"Saira", "SF Pro Display", "Helvetica Neue", Helvetica, Arial, sans-serif' },
  { name: 'Segoe UI', value: '"Segoe UI", "SF Pro Display", "Helvetica Neue", Helvetica, Arial, sans-serif' },
  { name: 'Tahoma', value: 'Tahoma, "SF Pro Display", "Helvetica Neue", Helvetica, Arial, sans-serif' },
  { name: 'Times New Roman', value: '"Times New Roman", serif' },
  { name: 'Verdana', value: 'Verdana, "SF Pro Display", "Helvetica Neue", Helvetica, Arial, sans-serif' },
]

interface Props {
  variable: string
  value: string
  onChange: (type: string, property: string, value: string) => void
}

const FontFamilyControl = ({
  variable, value, onChange,
}: Props) => {
  const handleChange = (ev) => {
    onChange('fontFamily', variable, ev.target.value)
  }

  return (
    <div className='FontFamilyControl d-flex justify-content-between align-content-start'>
      <label className='label'>{I18N(_.snakeCase(variable))}</label>
      <span className='control'>
        <select className='form-control' onChange={ev => handleChange(ev)} value={value}>
          {FONT_FAMILIES.map(font => (
            <option key={font.name} value={font.value}>{font.name}</option>
          ))}
        </select>
      </span>
    </div>
  )
}

export default FontFamilyControl
