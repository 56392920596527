import AiGreetingMessage from 'components/ai/chat/ask_ai_modal/aiGreetingMessage'
import { useAskAiContext } from 'components/ai/chat/ask_ai_modal/askAiContext'
import { askAiI18n } from 'components/ai/chat/ask_ai_modal/askAiUtils'
import ChatMessage from 'components/ai/chat/ask_ai_modal/chatMessage'
import ChatTabGreetingHeader from 'components/ai/chat/ask_ai_modal/ChatTabGreetingHeader'
import { useStickyScroll } from 'components/ai/chat/ask_ai_modal/hooks/useStickyScroll'
import CdnSvg from 'components/common/cdnSvg'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import React, { useEffect, useState } from 'react'

const ChatTab = () => {
  const [message, setMessage] = useState('')
  const {
    messages,
    isCreatingMessage,
    isCreatingConversation,
    onNewMessage,
    hasMoreMessages,
    onLoadMoreMessages,
    isLoadingMessages,
    isResponding,
  } = useAskAiContext()

  const showLoadingSpinner = (messages.length === 0 && isLoadingMessages) || hasMoreMessages || isCreatingConversation

  const { scrollableRef, isScrollAtTheTop, scrollingDirection } = useStickyScroll()

  useEffect(() => {
    if (isScrollAtTheTop && hasMoreMessages) {
      onLoadMoreMessages()
    }
  }, [isScrollAtTheTop, hasMoreMessages])


  return (
    <>
      <ChatTabGreetingHeader scrollingDirection={scrollingDirection} />

      <div className='AskAiModal-body flex-grow-1 p-4' ref={scrollableRef}>
        {showLoadingSpinner && <CirclesLoadingIndicator />}

        <AiGreetingMessage />

        {!isCreatingConversation && messages.map(message => (
          <ChatMessage key={message.id} message={message} />
        ))}
      </div>

      <form
        className='AskAiModal-footer p-4'
        onSubmit={(e) => {
          e.preventDefault()

          if (message) {
            setMessage('')
            onNewMessage({
              content: message,
            })
          }
        }}
      >
        <div className='AskAiModal-input-group'>
          <input
            type='text'
            placeholder={askAiI18n('input_placeholder')}
            className='form-control link-color-bordered'
            value={message}
            onChange={e => setMessage(e.target.value)}
            disabled={isResponding}
            ref={ref => ref?.focus()}
          />

          <button
            type='submit'
            disabled={isCreatingMessage || isCreatingConversation || !message || isResponding}
          >
            <CdnSvg src='/images/paperPlaneSendIcon2.svg' />
          </button>
        </div>

        <p className='text-secondary text-center mt-1 text-smallest'>
          {askAiI18n('answers_are_generated_by_ai_and_can_be_inaccurate')}
        </p>
      </form>
    </>
  )
}

export default ChatTab
