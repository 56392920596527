import { Button } from 'components/common/buttons'
import useApi from 'components/common/hooks/useApi'
import React, { useState } from 'react'
import API from 'services/api'
import { useHistory } from 'react-router-dom'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.integrations_page.zendesk_integration_page')

const ZendeskIntegrationPage = () => {
  const history = useHistory()
  const [createSource, { isLoading }] = useApi(API.admin.externalSync.zendesk.sources.create, {
    onSuccess: (data) => {
      history.push(data.displayPath)
    },
  })
  const [helpCenterUrl, setHelpCenterUrl] = useState('')

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const url = new URL(helpCenterUrl.startsWith('http') ? helpCenterUrl : `https://${helpCenterUrl}`)
    const cleanedUrl = `https://${url.hostname}`
    createSource(cleanedUrl)
  }

  return (
    <>
      <header className='AdminHeader'>
        <h3 className='mb-1'>{I18N('title')}</h3>
      </header>
      <main className='AdminContent IntegrationsPage'>
        <form onSubmit={handleSubmit}>
          <p className='text-small text-secondary'>{I18N('note')}</p>
          <label>{I18N('help_center_url')}</label>
          <input
            type='text'
            className='w-50 mb-3'
            value={helpCenterUrl}
            onChange={e => setHelpCenterUrl(e.target.value)}
            placeholder={I18N('help_center_url_placeholder')}
          />
          <Button type='submit' disabled={isLoading || !helpCenterUrl} showLoadingSpinner={isLoading}>
            {I18NCommon('save')}
          </Button>
        </form>
      </main>
    </>
  )
}

export default ZendeskIntegrationPage
