import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import React from 'react'
import { useParams } from 'react-router-dom'
import { i18nPath } from 'utils/i18nHelpers'
import AskAiTab from 'pages/admin/cleary_ai_settings/askAiTab'
import GeneralTab from 'pages/admin/cleary_ai_settings/generalTab'
import SlackBotTab from 'pages/admin/cleary_ai_settings/slackAppTab'
import { present } from 'components/common/utils'

const I18N = i18nPath('views.admin.cleary_ai_settings')

const GENERAL = 'general'
const SLACK_APP = 'slack_app'
const ASK_AI = 'ask_ai'

const ClearyAiSettingsPage = () => {
  const { selectedTab } = useParams()

  const tabs = [
    { id: GENERAL, text: I18N('tabs.general.label'), hidden: false },
    { id: SLACK_APP, text: I18N('tabs.slack_app.label'), hidden: false },
    { id: ASK_AI, text: I18N('tabs.ask_ai.label'), hidden: false },
  ].filter(present)

  return (
    <div className='ClearyAiSettingsPage'>
      <header className='AdminHeader'>
        <h3>{I18N('title')}</h3>
      </header>

      <main className='AdminContent'>
        <HistoryChangingPillTabSelector
          baseUrl='/admin/cleary_ai_settings'
          tabs={tabs}
          className='mb-3'
          historyKeyToChange='pathname'
          pillClasses='text-normal'
        />
        {selectedTab === GENERAL && <GeneralTab />}
        {selectedTab === SLACK_APP && <SlackBotTab />}
        {selectedTab === ASK_AI && <AskAiTab />}
      </main>
    </div>
  )
}

export default ClearyAiSettingsPage
