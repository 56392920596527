import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import PillTabSelector from 'components/common/pillTabSelector'
import useApi from 'components/common/hooks/useApi'
import SmartTable from 'components/common/tables/smartTable'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { present } from 'components/common/utils'
import { UserBillingAccessLevelSnapshotType } from 'types/billing/userBillingAccessLevelSnapshot'
import { UserType } from 'types/user'
import ExportXlsxLink from 'components/common/exportXlsxLink'
import AsyncSearchInput from 'components/common/asyncSearchInput'

const FULL_PLATFORM = 'full_platform'
const ONBOARDING_ONLY = 'onboarding_only'
const NO_ACCESS_VISIBLE = 'no_access_visible'

const I18N = i18nPath('views.billing.detail_page')

const columns = [
  {
    accessor: (user: UserType) => <EmployeeThumbnailPhoto employee={user} size='35px' />,
    style: { width: '10%' },
  },
  {
    header: 'Name',
    accessor: (user: UserType) => user.preferredFullName,
  },
  {
    header: 'Email',
    accessor: (user: UserType) => user.email,
  },
  {
    accessor: (user: UserType) => (
      <Link to={`/admin/users/${user.id}`}>
        {I18N('manager_user')}
      </Link>
    ),
  },
]

const BillingDetailPage = () => {
  const { id } = useParams()
  const currentCompany = useCurrentCompany()
  const isSoftLaunchEnabled = currentCompany.settings.softLaunchWhitelist.enabled

  const [selectedTab, setSelectedTab] = useState(FULL_PLATFORM)
  const [searchQuery, setSearchQuery] = useState('')

  const {
    isLoading: isLoadingSnapshot, data: snapshot,
  } = useFetch<UserBillingAccessLevelSnapshotType>(() => API.admin.userBillingAccessLevelSnapshots.fetch(id))

  const tabs = [
    { text: I18N('full_platform', { count: snapshot?.fullPlatformCount }), onClick: () => setSelectedTab(FULL_PLATFORM) },
    { text: I18N('onboarding_only', { count: snapshot?.onboardingOnlyCount }), onClick: () => setSelectedTab(ONBOARDING_ONLY) },
    isSoftLaunchEnabled && {
      text: I18N('no_access_visible', { count: snapshot?.noAccessVisibleCount }),
      onClick: () => setSelectedTab(NO_ACCESS_VISIBLE),
    },
  ].filter(present)


  const [
    fetchSnapshotUsers,
    { data: users, paginationData, isLoading: isLoadingUsers },
  ] = useApi(API.admin.userSnapshots.fetchAll)

  const fetchUsers = (params = {}) => {
    fetchSnapshotUsers({
      userBillingAccessLevelSnapshotId: id,
      accessLevel: selectedTab,
      page: 1,
      perPage: 20,
      query: searchQuery,
      ...paginationData,
      ...params,
    })
  }

  useEffect(() => {
    fetchUsers({ page: 1 })
  }, [id, selectedTab, searchQuery])


  return (
    <div className='BillingDetailPage'>
      <header className='AdminHeader d-flex justify-content-between align-items-center'>
        <h3>{I18N('title')}</h3>
        <ExportXlsxLink
          endpoint={API.admin.userBillingAccessLevelSnapshots.exportXlsx}
          exportParams={{ id }}
        />
      </header>

      <main className='AdminContent'>
        {isLoadingSnapshot ? (
          <CirclesLoadingIndicator />
        ) : (
          <>
            <div className='mb-2'>
              <label className='label mr-2'>{I18N('date')}</label>
              <span>{i18nMoment(snapshot?.snapshotDate).format('LLLL')}</span>
            </div>

            <PillTabSelector
              tabs={tabs}
              defaultSelectedIndex={0}
              className='mb-4'
            />

            <AsyncSearchInput
              placeholder={I18N('search_placeholder')}
              onKeyUp={setSearchQuery}
              defaultValue={searchQuery}
            />

            <SmartTable
              columns={columns}
              data={users}
              className='white-bg-table'
              showPagination
              page={paginationData.page}
              pages={paginationData.totalPages}
              totalCount={paginationData.total}
              perPage={paginationData.perPage}
              onPaginationClick={data => fetchUsers(data)}
              isLoading={isLoadingUsers}
              LoadingComponent={<CirclesLoadingIndicator />}
            />
          </>
        )}
      </main>
    </div>
  )
}

export default BillingDetailPage
