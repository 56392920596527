import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import { SimpleUserType } from 'types/user'
import { I18NCommon } from 'utils/i18nHelpers'

type Props = {
  user: SimpleUserType | null | undefined
  fontWeight?: '400' | '500' | '600'
}

const UserOrUnassigned = ({ user, fontWeight = '400' }: Props) => {
  if (!user) {
    return (
      <>
        <CdnSvg src='/images/personIcon.svg' className='UserOrUnassigned-UnassignedIcon mr-1' />
        <span className={`text-small font-weight-${fontWeight}`}>{I18NCommon('unassigned')}</span>
      </>
    )
  }

  return (
    <>
      <EmployeeThumbnailPhoto employee={user} size='22px' className='mr-1' />
      <span className={`text-small font-weight-${fontWeight}`}>{user.preferredFullName}</span>
    </>
  )
}

export default UserOrUnassigned
