import React, { useCallback, useState } from 'react'
import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import API from 'services/api'
import { Workflow } from 'services/api/admin/workflows'
import useFetch from 'components/common/hooks/useFetch'
import { Button } from 'components/common/buttons'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import SmartTable from 'components/common/tables/smartTable'
import useApi from 'components/common/hooks/useApi'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import CdnSvg from 'components/common/cdnSvg'
import AsyncSearchInput from 'components/common/asyncSearchInput'
import ConfirmationModal from 'components/common/modals/confirmationModal'
import RoundedAvatars from 'components/common/roundedAvatars'
import AddWorkflowModal from './workflowListPage/addWorkflowModal'

const I18N = i18nPath('views.admin.workflows.list_page')

const columns = (setShowDeleteModal, setSelectedWorkflow) => [
  {
    header: I18N('table.headers.name'),
    accessor: d => d.name,
  },
  {
    header: I18N('table.headers.trigger'),
    accessor: d => d.description,
  },
  {
    header: I18N('table.headers.creator'),
    accessor: d => <RoundedAvatars users={[d.user]} avatarSize='30px' />,
  },
  {
    header: I18N('table.headers.last_modified'),
    accessor: d => i18nMoment(d.updatedAt).format('lll'),
  },
  {
    header: '',
    accessor: d => (
      <DropdownMenuContainer className='default-dropdown' menuType='kebab' closeMenuOnItemClick>
        <section>
          <CardDropdownMenuItem
            link={d.displayPath}
            primaryText={I18N('table.actions.history')}
            icon={<CdnSvg className='mr-2' src='/images/clockIcon.svg' />}
          />
        </section>
        <section>
          <CardDropdownMenuItem
            link={`${d.displayPath}/edit`}
            primaryText={I18N('table.actions.edit')}
            icon={<CdnSvg className='mr-2' src='/images/pencilIcon.svg' />}
          />
        </section>
        <section>
          <CardDropdownMenuItem
            className='Card-dropdownMenuItem--danger'
            onClick={() => {
              setShowDeleteModal(true)
              setSelectedWorkflow(d)
            }}
            primaryText={I18N('table.actions.delete')}
            icon={<CdnSvg src='/images/trashIcon.svg' className='mr-2' />}
          />
        </section>
      </DropdownMenuContainer>
    ),
  },
]

const WorkflowListPage = () => {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | null>(null)

  const {
    data,
    isLoading,
    callApi: fetchWorkflows,
    paginationData: { page, totalPages },
    removeItem,
  } = useFetch<Workflow[]>(API.admin.workflows.fetchAll)

  const [deleteWorkflow] = useApi(API.admin.workflows.destroy, {
    onSuccess: workflow => removeItem(workflow.id),
    toastSuccessMessage: I18N('workflow_deleted'),
  })

  const handleSearch = useCallback(
    value => fetchWorkflows({ q: value }),
    [fetchWorkflows]
  )

  return (
    <div className='WorkflowListPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>{I18N('title')}</h3>
        <div className='AdminButtonContainer'>
          <Button onClick={() => setShowCreateModal(true)}>
            {I18N('new_workflow')}
          </Button>
        </div>
      </header>

      <main className='AdminContent'>
        <AsyncSearchInput
          placeholder={I18N('title_search_placeholder')}
          icon={<CdnSvg src='/images/searchIcon.svg' />}
          onKeyUp={handleSearch}
          className='mb-4'
        />
        <TableLoadingContainer isLoading={isLoading}>
          <SmartTable
            data={data}
            showPagination={page !== null && totalPages !== null}
            columns={columns(setShowDeleteModal, setSelectedWorkflow)}
            page={page}
            pages={totalPages}
            onPaginationClick={fetchWorkflows}
            className='white-bg-table'
          />
        </TableLoadingContainer>
      </main>

      {showCreateModal && <AddWorkflowModal onCancel={() => setShowCreateModal(false)} />}

      {showDeleteModal && (
        <ConfirmationModal
          visible
          toggle={() => setShowDeleteModal(false)}
          title={I18NCommon('confirmation_modal.title')}
          description={I18NCommon('confirmation_modal.description')}
          onConfirmClick={() => {
            deleteWorkflow(selectedWorkflow)
            setShowDeleteModal(false)
          }}
        />
      )}
    </div>
  )
}

export default WorkflowListPage
