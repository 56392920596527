import React, { useEffect, useState } from 'react'
import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import API from 'services/api'
import StatusLabel from 'components/common/status_label'
import CardWithPaginatedTable from 'components/analytics/common/cardWithPaginatedTable'
import useQueryParams from 'components/common/hooks/useQueryParams'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import useFetch from 'components/common/hooks/useFetch'
import UserLink from 'components/common/userLink'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'
import { useDebounce } from 'usehooks-ts'
import { RootSidebar } from 'components/common/sidebar'
import AiAnswerSidebarTitle from 'components/analytics/ai_answers/aiAnswerSidebarTitle'
import AiAnswersOrigin from 'components/analytics/ai_answers/aiAnswersOrigin'
import AiAnswerSidebar from 'components/analytics/ai_answers/aiAnswerSidebar'
import useApi from 'components/common/hooks/useApi'
import useDebouncedClose from 'hooks/shared/useDebouncedClose'
import { useIsGlobalAnalytics } from 'pages/admin/global_analytics/globalAnalyticsProvider'
import { AssistantAnswerType } from 'types/ai/assistantAnswer'
import { present } from 'components/common/utils'
import ExportXlsxButton from 'components/analytics/common/exportXlsxButton'

const I18N = i18nPath('views.platform_analytics.ai_answers.answers')

const statusLabelColor = {
  answered_correct: 'green',
  answered_incorrect: 'red',
  not_answered: 'orange',
  answered_no_feedback: 'blue',
}

export const AnswerStatusLabel = ({ answer }) => (
  <StatusLabel color={statusLabelColor[answer?.status]}>
    {I18N(`statuses.${answer?.status}`)}
  </StatusLabel>
)

const tabs = [
  {
    id: 'all',
    text: I18N('tabs.show_all'),
  },
  {
    id: 'answered_correct',
    text: I18N('tabs.answered_correct'),
  },
  {
    id: 'answered_incorrect',
    text: I18N('tabs.answered_incorrect'),
  },
  {
    id: 'answered_no_feedback',
    text: I18N('tabs.answered_no_feedback'),
  },
  {
    id: 'not_answered',
    text: I18N('tabs.not_answered'),
  },
]

const columns = (setSelectedAnswerId: (answerId: string) => void, isGlobal: boolean) => ([
  isGlobal && {
    header: I18N('table.headers.company'),
    col: 'col-company',
    style: { width: 150 },
    accessor: (answer: AssistantAnswerType) => answer.company?.name,
  },
  {
    header: I18N('table.headers.status'),
    col: 'col-status',
    accessor: answer => <AnswerStatusLabel answer={answer} />,
    style: { width: '15%' },
  },
  {
    header: I18N('table.headers.question'),
    col: 'col-question',
    accessor: answer => (
      <div
        className='AiAnswersTab__Answers__query truncate-text-at-2-lines'
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()

          setSelectedAnswerId(answer.id)
        }}
      >
        {answer.queryWithoutMentions}
      </div>
    ),
  },
  {
    header: I18N('table.headers.origin'),
    col: 'col-origin',
    accessor: (answer: any) => <AiAnswersOrigin answer={answer} />,
  },
  {
    header: I18N('table.headers.submitted_by'),
    style: { width: '20%' },
    col: 'col-author',
    accessor: answer => <UserLink user={answer.user} />,
  },
  {
    header: I18N('table.headers.date'),
    style: { width: 200 },
    col: 'col-date',
    id: 'created_at',
    accessor: answer => i18nMoment(answer.createdAt).format('lll'),
  },
].filter(present))

interface AnswersProps {
  params: {
    period?: {
      periodStart: string
      periodEnd?: string
    }
    topicId?: string
    companyId?: string
  }
  showTabs?: boolean
  title?: string
}

const Answers = ({
  params,
  showTabs = true,
  title = I18N('title'),
}: AnswersProps) => {
  const isGlobal = useIsGlobalAnalytics()

  const { selectedTab } = useQueryParams()
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query, 500)

  const [pagination, setPagination] = useState({ page: 1, sortBy: 'created_at', sortDir: 'desc' })
  const status = selectedTab === 'all' ? undefined : selectedTab

  const {
    data: answers,
    isLoading,
    paginationData,
  } = useFetch(
    () => API.admin.analytics.aiAnswers.fetchAnswers({
      ...pagination,
      status,
      query: debouncedQuery,
      isGlobal,
      ...params,
    }),
    [
      pagination.sortBy,
      pagination.sortDir,
      pagination.page,
      debouncedQuery,
      JSON.stringify(params),
      selectedTab,
    ]
  )

  const [selectedAnswerId, setSelectedAnswerId] = useQueryParamState<string>({ param: 'answerId', initialValue: '' })
  const [selectedAnswer, setSelectedAnswer] = useState<any>(null)
  const [fetchAnswer] = useApi(API.admin.analytics.aiAnswers.fetchAnswer, { onSuccess: setSelectedAnswer })

  useEffect(() => {
    if (!selectedAnswerId) {
      setSelectedAnswer(null)
      return
    }

    const answer = answers?.find(a => a.id === selectedAnswerId)
    if (answer) {
      setSelectedAnswer(answer)
    } else {
      fetchAnswer(selectedAnswerId, { isGlobal })
    }
  }, [selectedAnswerId])

  /**
   * We don't remove the answer immediately in the onClose of the sidebar
   * because a user may click a different answer (outside the sidebar so calling onClose)
   * and we don't want to remove the newly selected answer in that case
   */
  const [isClosingSidebar, onCloseSidebar] = useDebouncedClose(() => setSelectedAnswerId(''))

  const onExport = async (email: string) => {
    await API.admin.analytics.aiAnswers.exportXlsx({
      email,
      ...pagination,
      status,
      query: debouncedQuery,
      isGlobal,
      ...params,
    })
  }

  return (
    <div className='AiAnswersTab__Answers grid-span-12'>
      <CardWithPaginatedTable
        title={title}
        tableColumns={columns(setSelectedAnswerId, isGlobal)}
        tableData={answers}
        isLoading={isLoading}
        showPagination={(paginationData.totalPages || 0) > 1}
        page={paginationData.page || 1}
        totalPages={paginationData.totalPages || 0}
        onPaginationClick={setPagination}
        onSortChange={setPagination}
        sortKey='created_at'
        sortedAsc={false}
        headerActions={(
          <div className='d-flex justify-content-end align-items-center'>
            <input
              type='text'
              placeholder={I18NCommon('search')}
              onChange={e => setQuery(e.target.value)}
            />
            <ExportXlsxButton
              className='ml-1'
              onSubmit={onExport}
            />
          </div>
        )}
      >
        {showTabs && (
          <HistoryChangingPillTabSelector
            pillClasses='text-normal'
            className='mb-3'
            tabs={tabs}
          />
        )}
      </CardWithPaginatedTable>

      <RootSidebar
        onClose={onCloseSidebar}
        title={
          selectedAnswer && (
            <AiAnswerSidebarTitle
              answer={selectedAnswer}
              key={selectedAnswer?.id}
            />
          )
        }
        isOpen={!!selectedAnswerId && !isClosingSidebar}
        className='AiAnswersTab__RootSidebar'
      >
        <AiAnswerSidebar answer={selectedAnswer} />
      </RootSidebar>
    </div>
  )
}

export default Answers
