import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import { i18nPath } from 'utils/i18nHelpers'
import { useParams } from 'react-router-dom'
import useQueryParams from 'components/common/hooks/useQueryParams'
import ArticleSummaryEditor from 'components/admin/articles/articleSummaryEditor'
import ArticleContentEditor from 'components/admin/articles/content_editor/articleContentEditor'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import articleTypeSlice from 'redux/slices/articleTypes'
import tagSlice from 'redux/slices/tags'
import ArticleAudienceAndSettingsEditor from 'components/admin/articles/audience_and_settings_editor/articleAudienceAndSettingsEditor'
import displayAuthorSlice from 'redux/slices/displayAuthors'
import ArticleTemplatesSidebar from 'components/admin/articles/templates/articleTemplatesSidebar'
import useIsGroupPost from 'components/admin/articles/hooks/useIsGroupPost'

export const CONTENT = 'content'
export const SUMMARY = 'summary'
export const SETTINGS = 'settings'

const I18N = i18nPath('views.admin.article_editor')

type Props = {
  article?: any // required when editing an article
  workingCopy: any
  updateWorkingCopy: (any) => void
  isLoading: boolean
  showTemplatesSidebar?: boolean
  closeTemplatesSidebar?: () => void
  banners?: React.ReactNode[]
  isEditingTemplate?: boolean
}

const ArticleOrTemplateEditor = ({
  article,
  workingCopy,
  updateWorkingCopy,
  isLoading,
  showTemplatesSidebar,
  closeTemplatesSidebar,
  banners = [],
  isEditingTemplate = false,
}: Props) => {
  const dispatch = useDispatch()
  const { selectedTab } = useQueryParams()
  const isGroupPost = useIsGroupPost(workingCopy)
  const { articleId: urlArticleId } = useParams<{ articleId: string }>()

  useEffect(() => {
    if (!isGroupPost) {
      const onlyActive = urlArticleId === 'new'

      // only fetch active article types when creating a new article
      // fetch all for editing an existing article because it can be using a non-active article type
      dispatch(articleTypeSlice.asyncActions.admin.fetchAll({ onlyActive }))
      dispatch(displayAuthorSlice.asyncActions.admin.fetchAll())
    }

    dispatch(tagSlice.asyncActions.fetchTags())
  }, [])

  return (
    <div className='grid-container'>
      <div className='grid-header'>
        <div className='ArticleEditorTabs'>
          <HistoryChangingPillTabSelector
            tabs={[
              { id: CONTENT, text: I18N('content') },
              { id: SUMMARY, text: I18N('summary_card') },
              { id: SETTINGS, text: I18N('audience_and_settings') },
            ]}
            pillClasses='text-normal'
            variant='PageTab'
          />
        </div>
        <div className='ArticleAdminBanners'>
          {banners}
        </div>
      </div>
      {isLoading || _.isEmpty(workingCopy) ? (
        <CirclesLoadingIndicator />
      ) : (
        <>
          {showTemplatesSidebar && (
            <ArticleTemplatesSidebar
              closeTemplatesSidebar={closeTemplatesSidebar}
            />
          )}
          <div className='ArticleEditor'>
            {/* hide instead of removing from dom so that we can scroll to elements in here */}
            <div className={selectedTab !== CONTENT ? 'd-none' : ''}>
              <ArticleContentEditor
                workingCopy={workingCopy}
                updateWorkingCopy={updateWorkingCopy}
                isEditingTemplate={isEditingTemplate}
                isVisible={selectedTab === CONTENT}
              />
            </div>
            {selectedTab === SUMMARY && (
              // wrapper div is needed for the padding to work
              <div>
                <ArticleSummaryEditor
                  workingCopy={workingCopy}
                  updateWorkingCopy={updateWorkingCopy}
                  isEditingTemplate={isEditingTemplate}
                />
              </div>
            )}
            {selectedTab === SETTINGS && (
              // wrapper div is needed for the padding to work
              <div>
                <ArticleAudienceAndSettingsEditor
                  article={article}
                  workingCopy={workingCopy}
                  updateWorkingCopy={updateWorkingCopy}
                  isEditingTemplate={isEditingTemplate}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default ArticleOrTemplateEditor
