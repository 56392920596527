import React, { useEffect, useRef } from 'react'

import LoadingIndicator from 'components/common/circlesLoadingIndicator'

import useDidUpdate from 'components/common/hooks/useDidUpdate'

const InfiniteScroller = (props) => {
  const {
    className,
    children,
    isFetching,
    hasMoreContent,
    fetchThreshold,
    onFetch,
    onFetchParam = null,
    usePlaceholderSpace = false,
    anchorSelector = '.AppContent',
  } = props
  const scrollDivRef = useRef()
  const containerInitialHeight = useRef()

  const checkForContent = () => {
    if (isFetching) {
      return
    }

    if (!hasMoreContent()) {
      // don't remove scroll listener here because the type of content
      // being fetched might change (ie: news vs events)
      return
    }

    const boundingRect = scrollDivRef.current.getBoundingClientRect()
    const bottom = boundingRect.bottom

    const isScrollPositionBelowThreshold = Math.abs(window.innerHeight - bottom) <= fetchThreshold
    const shouldLoadMoreUntilPageFull = window.innerHeight > Math.abs(bottom - fetchThreshold)

    if (isScrollPositionBelowThreshold || shouldLoadMoreUntilPageFull) {
      onFetch(onFetchParam)
    }
  }

  useEffect(() => {
    const element = document.querySelector(anchorSelector) || window
    element.addEventListener('scroll', checkForContent)

    return () => element.removeEventListener('scroll', checkForContent)
  })

  useEffect(() => {
    if (scrollDivRef.current) {
      containerInitialHeight.current = scrollDivRef.current.clientHeight
    }
  }, [scrollDivRef.current])

  useDidUpdate(() => {
    const innerHeight = window.innerHeight
    const scrollHeight = document.documentElement.scrollHeight

    // Checks if there is more content to be loaded and if the page have more room to display it
    // If there is more content, it will keep doing that while the viewport's height is equals to <html>'s height
    if (hasMoreContent() && innerHeight === scrollHeight) {
      checkForContent()
    }
  })

  return (
    <div
      ref={scrollDivRef}
      className={className}
      style={usePlaceholderSpace && isFetching ? { height: containerInitialHeight.current } : {}}
    >
      {children}
      {isFetching && <LoadingIndicator />}
    </div>
  )
}

InfiniteScroller.defaultProps = {
  fetchThreshold: 200, // distance in pixels,
}

export default InfiniteScroller
