import useHideNavbar from 'components/navbar/hooks/useHideNavbar'
import React from 'react'

interface Props {
  header?: any
  sidebar?: any
  sidebarPosition?: 'left' | 'right'
  children: any
}

const FullPageEditor = ({
  children,
  header,
  sidebar,
  sidebarPosition = 'left',
}: Props) => {
  useHideNavbar()

  return (
    <div className={`FullPageEditor FullPageEditor--sidebar-${sidebarPosition}`}>
      {header && <div className='FullPageEditor__Header'>{header}</div>}
      <div className='FullPageEditor__Main'>
        {children}
      </div>
      {sidebar && <div className='FullPageEditor__Sidebar'>{sidebar}</div>}
    </div>
  )
}

export default FullPageEditor
