import React, { useEffect } from 'react'
import API from 'services/api'
import useApi from 'components/common/hooks/useApi'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import Sidebar from './dashboardPage/sidebar'
import MainContent from './dashboardPage/mainContent'

const AiPlatformDashboardPage = () => {
  const [fetchKnowledgeIngestions, { data: knowledgeIngestions, isLoading }] = useApi(
    API.ai.assistant.knowledgeIngestions.fetchAll, {
      defaultIsLoading: true,
    }
  )

  useEffect(() => {
    fetchKnowledgeIngestions()
  }, [])

  return (
    <div className='AiPlatform AiPlatformDashboardPage d-flex justify-content-center mt-5'>
      <div className='AiPlatformDashboardPage__wrapper d-flex gap-4'>
        <div className='AiPlatformDashboardPage__sidebar-wrapper'>
          {knowledgeIngestions && (
            <Sidebar knowledgeIngestions={knowledgeIngestions} />
          )}
        </div>
        <div className='AiPlatformDashboardPage__main-content-wrapper'>
          {isLoading ? (
            <CirclesLoadingIndicator />
          ) : (
            <MainContent
              knowledgeIngestions={knowledgeIngestions}
              fetchKnowledgeIngestions={fetchKnowledgeIngestions}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default AiPlatformDashboardPage
