import React, { useState } from 'react'

import useApi from 'components/common/hooks/useApi'
import useCompanyConfigurationForm from 'components/common/hooks/useCompanyConfigurationForm'
import { I18NCommon } from 'utils/i18nHelpers'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import Card from 'components/common/card'
import { Button } from 'components/common/buttons'
import SocialShareSettings from 'pages/admin/articles/settings/socialShareSettings'
import RestrictedArticlesDefaultSetting from 'pages/admin/articles/settings/restrictedArticlesDefaultSetting'
import TemplateDisplayOrderSettings from 'pages/admin/articles/settings/templateDisplayOrderSettings'
import NewsSubscriptionSettings from 'pages/admin/articles/settings/newsSubscriptionSettings'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import { TagType } from 'types/article/tag'

const GeneralArticleSettings = () => {
  const {
    connect, onSubmit: companyConfigurationFormSubmit, isLoading, isSaving,
  } = useCompanyConfigurationForm('news')

  const [subscribedTagsWorkingCopy, setSubscribedTagsWorkingCopy] = useState<TagType[]>([])
  const { data: subscribedTags, isLoading: isFetchingSubscribedTags } = useFetch(
    API.admin.tagSubscriptions.fetchAll, [],
    { onSuccess: setSubscribedTagsWorkingCopy }
  )
  const [updateTagSubscriptions] = useApi(API.admin.tagSubscriptions.updateCompanyAndUsersTagsSubscriptions)

  if (isLoading || isFetchingSubscribedTags) {
    return <section><CirclesLoadingIndicator /></section>
  }

  const onSubmit = () => {
    const workingCopyTagIds = subscribedTagsWorkingCopy.map(tag => tag.id)
    const initialTagIds = subscribedTags.map(tag => tag.id)

    if (!_.isEqual(_.sortBy(workingCopyTagIds), _.sortBy(initialTagIds))) {
      updateTagSubscriptions({ tagIds: workingCopyTagIds })
    }
    companyConfigurationFormSubmit()
  }

  return (
    <form onSubmit={onSubmit}>
      <Card className='SocialShareSettings'>
        <RestrictedArticlesDefaultSetting connect={connect} />

        <hr />

        <SocialShareSettings connect={connect} />

        <hr />

        <TemplateDisplayOrderSettings connect={connect} />

        <hr />

        <NewsSubscriptionSettings
          workingCopy={subscribedTagsWorkingCopy}
          setWorkingCopy={setSubscribedTagsWorkingCopy}
        />
      </Card>

      <div className='d-flex justify-content-end mt-5'>
        <Button
          type='submit'
          disabled={isSaving}
          showLoadingSpinner={isSaving}
        >
          {I18NCommon('save_changes')}
        </Button>
      </div>
    </form>
  )
}

export default GeneralArticleSettings
