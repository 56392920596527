import React, { createContext, useContext } from 'react'

export type GlobalAnalyticsContextProps = {
  isGlobal: boolean
}

export const GlobalAnalyticsContext = createContext<GlobalAnalyticsContextProps>({
  isGlobal: false,
})

export const useGlobalAnalyticsContext = () => useContext(GlobalAnalyticsContext)

export const useIsGlobalAnalytics = () => {
  const { isGlobal } = useGlobalAnalyticsContext()

  return isGlobal
}

type GlobalAnalyticsProviderProps = {
  isGlobal: boolean
  children: React.ReactNode
}

export const GlobalAnalyticsProvider = ({ isGlobal, children }: GlobalAnalyticsProviderProps) => (
  <GlobalAnalyticsContext.Provider value={{ isGlobal }}>
    {children}
  </GlobalAnalyticsContext.Provider>
)
