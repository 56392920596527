import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { components } from 'react-select'

import { i18nFormat, i18nPath } from 'utils/i18nHelpers'

import searchSlice, { PAGE_SIZES } from 'redux/slices/search'
import { trackEvent } from 'services/tracker'
import classNames from 'classnames'

import ReactSelect from 'components/common/react_select'
import ErrorBoundary from 'components/errorBoundaries'
import ArticleOption from 'components/search/option_renderers/articleOption'
import GoLinkOption from 'components/search/option_renderers/golinkOption'
import GoogleDriveOption from 'components/search/option_renderers/googleDriveOption'
import ConfluenceOption from 'components/search/option_renderers/confluenceOption'
import BloomfireOption from 'components/search/option_renderers/bloomfireOption'
import TeamOption from 'components/search/option_renderers/teamOption'
import PageOption from 'components/search/option_renderers/pageOption'
import QnaAnswerOption from 'components/search/option_renderers/qnaAnswerOption'
import QnaEventOption from 'components/search/option_renderers/qnaEventOption'
import QnaQuestionOption from 'components/search/option_renderers/qnaQuestionOption'
import SeeMoreOption from 'components/search/option_renderers/seeMoreOption'
import ShowAllResults from 'components/search/option_renderers/showAllResults'
import UserOption from 'components/search/option_renderers/userOption'
import UserSkillOption from 'components/search/option_renderers/userSkillOption'
import AppOption from 'components/search/option_renderers/appOption'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import NotionOption from 'components/search/option_renderers/notionOption'
import PageWorkspaceOption from 'components/search/option_renderers/pageWorkspaceOption'
import CdnSvg from 'components/common/cdnSvg'
import OneDriveOption from 'components/search/option_renderers/oneDriveOption'
import useIsAiAssistantAnswersEnabled from 'hooks/ai/useIsAssistantAnswersEnabled'
import { extractHighlights } from 'components/common/utils'
import qs from 'qs'
import snakeCaseKeys from 'utils/snakeCaseKeys'
import AttachedFileContentOption from 'components/search/option_renderers/attachedFileContentOption'
import PageFAQOption from 'components/search/option_renderers/pageFAQOption'

const I18N = i18nPath('views.search.global_search')
const ENTER_KEY_ASCII_CODE = 13

const ValueContainer = ({ children, ...props }) => {
  const isAiAssistantAnswersEnabled = useIsAiAssistantAnswersEnabled()

  return (
    components.ValueContainer && (
      <components.ValueContainer {...props} className='pl-5'>
        <CdnSvg
          src={isAiAssistantAnswersEnabled ? '/images/aiSearchIcon.svg' : '/images/searchIcon.svg'}
          className='GlobalSearchIcon'
        />
        {children}
      </components.ValueContainer>
    )
  )
}

const isSearchGoLink = value => value.startsWith('go/')

const GroupHeading = props => (
  <div className='GlobalSearch-GroupHeading'>
    <components.GroupHeading {...props} />
  </div>
)

const getOptions = (resultOptions, count, label = null) => {
  // in some cases, count may not be defined, in that case we can use the total number of results
  const totalResults = count || resultOptions.length
  if (totalResults <= PAGE_SIZES.dropdown || !label) {
    return [...resultOptions]
  }

  return [...resultOptions, { type: 'see_more', label }]
}

const Option = (props) => {
  const { data } = props

  const { type } = data
  let optionRenderer = null

  switch (type) {
  case 'user':
    optionRenderer = <UserOption option={data} />
    break
  case 'go_link':
    optionRenderer = <GoLinkOption option={data} />
    break
  case 'go_link/alias':
    optionRenderer = <GoLinkOption option={data} />
    break
  case 'article':
    optionRenderer = <ArticleOption option={data} />
    break
  case 'user_skill':
    optionRenderer = <UserSkillOption option={data} />
    break
  case 'page':
    optionRenderer = <PageOption option={data} />
    break
  case 'page/workspace':
    optionRenderer = <PageWorkspaceOption option={data} />
    break
  case 'group':
    optionRenderer = <TeamOption option={data} />
    break
  case 'qna/event':
    optionRenderer = <QnaEventOption option={data} />
    break
  case 'qna/question':
    optionRenderer = <QnaQuestionOption option={data} />
    break
  case 'qna/answer':
    optionRenderer = <QnaAnswerOption option={data} />
    break
  case 'app_launcher/app':
    optionRenderer = <AppOption option={data} />
    break
  case 'attached_file_content':
    optionRenderer = <AttachedFileContentOption option={data} />
    break
  case 'page/faq':
    optionRenderer = <PageFAQOption option={data} />
    break
  case 'google_drive':
    optionRenderer = <GoogleDriveOption option={data} />
    break
  case 'confluence':
    optionRenderer = <ConfluenceOption option={data} />
    break
  case 'bloomfire':
    optionRenderer = <BloomfireOption option={data} />
    break
  case 'one_drive':
    optionRenderer = <OneDriveOption option={data} />
    break
  case 'notion':
    optionRenderer = <NotionOption option={data} />
    break
  case 'see_all':
    optionRenderer = <ShowAllResults />
    break
  case 'see_more':
    optionRenderer = <SeeMoreOption option={data} />
    break
  default:
    optionRenderer = null
  }

  // Wrapping with components.Option so that
  // hover/active states on the options are handled
  // properly by the react-select library
  return optionRenderer ? <components.Option {...props}>{optionRenderer}</components.Option> : null
}

// Placing outside so the reference is not created with every render.
// Could also be done with useCallback so that we don't need to pass in dispatch
// and isGoogleDriveSearchEnabled
const fetchResults = _.debounce((
  inputValue,
  dispatch,
  isGoogleDriveSearchEnabled,
  isConfluenceSearchEnabled,
  isNotionSearchEnabled,
  isBloomfireSearchEnabled,
  isOneDriveSearchEnabled
) => {
  // searchLocation refers to the component that is doing the search, it can either be 'dropdown' or 'page'
  // searchOrigin is used for Amplitude tracking, and it reflects what the user was doing that triggered the search
  // it can be: 'dropdown', 'results_page', or 'golink_not_found'
  const params = {
    query: inputValue,
    type: 'global',
    searchGoogleDrive: isGoogleDriveSearchEnabled,
    searchConfluence: isConfluenceSearchEnabled,
    searchBloomfire: isBloomfireSearchEnabled,
    searchNotion: isNotionSearchEnabled,
    searchOneDrive: isOneDriveSearchEnabled,
    searchLocation: 'dropdown',
    searchOrigin: 'dropdown',
    highlightFragmentSize: 40,
  }

  if (isSearchGoLink(inputValue)) {
    params.query = inputValue.replace('go/', '')
    params.type = 'go_link'
    params.searchGoogleDrive = false
    params.searchConfluence = false
    params.searchBloomfire = false
    params.searchNotion = false
    params.searchOneDrive = false
  }

  dispatch(searchSlice.asyncActions.fetchResults(params))
}, 500)

const GlobalSearch = () => {
  const selectRef = useRef()
  const [inputValue, setInputValue] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const currentCompany = useCurrentCompany()
  const isGoogleDriveSearchEnabled = currentCompany.settings.googleDriveSearch?.enabled
  const isOneDriveSearchEnabled = currentCompany.settings.oneDrive?.searchEnabled
  const isConfluenceSearchEnabled = currentCompany.settings.confluenceSearch?.enabled
  const isBloomfireSearchEnabled = currentCompany.settings.bloomfireSearch?.enabled
  const isNotionSearchEnabled = currentCompany.settings.notionSearch?.enabled
  const searchLocation = 'dropdown'
  const searchTerm = useSelector(searchSlice.selectors.getSearchTerm(searchLocation))
  const googleSearchResults = useSelector(searchSlice.selectors.getGoogleSearchResults(searchLocation))
  const oneDriveSearchResults = useSelector(searchSlice.selectors.getOneDriveSearchResults(searchLocation))
  const confluenceSearchResults = useSelector(searchSlice.selectors.getConfluenceSearchResults(searchLocation))
  const bloomfireSearchResults = useSelector(searchSlice.selectors.getBloomfireSearchResults(searchLocation))
  const notionSearchResults = useSelector(searchSlice.selectors.getNotionSearchResults(searchLocation))
  const clearySearchResults = useSelector(searchSlice.selectors.getClearySearchResults(searchLocation))
  const searchSessionId = useSelector(searchSlice.selectors.getSessionId)
  // The purpose of this flag is to determine the behavior of the 'enter' key on the search bar.
  // When the user is typing, it will be set to true. Once the search results are loaded,
  // it will be changed to false to allow the default action of selecting an item from the menu to occur.
  // When set to true, pressing the 'enter' key will direct the user to the search page.
  const enterGoesToSearchPage = useRef(true)
  const {
    isLoading, isLoadingGoogleDrive, isLoadingConfluence, isLoadingBloomfire,
    isLoadingNotion, isLoadingOneDrive, totalResults, totalResultsByType, searchStartedAt,
  } = useSelector(
    searchSlice.selectors.getMetaData(searchLocation)
  )

  const hydratedSearchResults = () => {
    if (!clearySearchResults) return null

    // We receive all google results in search and we only want to show the top 5
    const googleResultsSliced = googleSearchResults.slice(0, PAGE_SIZES.dropdown)

    const googleDriveLabel = isLoadingGoogleDrive ? (
      <span>
        Google Drive
        <CirclesLoadingIndicator className='LoadingIndicator' />
      </span>
    ) : (
      'Google Drive'
    )

    const googleDriveOptions = isLoadingGoogleDrive
      ? [{}]
      : getOptions(googleResultsSliced, totalResultsByType.googleDrive, 'google_drive')

    // We receive all confluence results in search and we only want to show the top 5
    const confluenceResultsSliced = confluenceSearchResults.slice(0, PAGE_SIZES.dropdown)

    const confluenceLabel = isLoadingConfluence ? (
      <span>
        Confluence
        <CirclesLoadingIndicator className='LoadingIndicator' />
      </span>
    ) : (
      'Confluence'
    )

    const confluenceOptions = isLoadingConfluence
      ? [{}]
      : getOptions(confluenceResultsSliced, totalResultsByType.confluence, 'confluence')

    // We receive all bloomfire results in search and we only want to show the top 5
    const bloomfireResultsSliced = bloomfireSearchResults.slice(0, PAGE_SIZES.dropdown)
    const bloomfireLabelText = currentCompany.settings.bloomfireSearch?.integrationName?.split('_').join(' ') || 'Bloomfire'
    const bloomfireLabel = isLoadingBloomfire ? (
      <span>
        {bloomfireLabelText}
        <CirclesLoadingIndicator className='LoadingIndicator' />
      </span>
    ) : (
      bloomfireLabelText
    )

    const bloomfireOptions = isLoadingBloomfire
      ? [{}]
      : getOptions(bloomfireResultsSliced, totalResultsByType.bloomfire, 'bloomfire')

    // We receive all notion results in search and we only want to show the top 5
    const notionResultsSliced = notionSearchResults.slice(0, PAGE_SIZES.dropdown)

    const notionLabel = isLoadingNotion ? (
      <span>
        Notion
        <CirclesLoadingIndicator className='LoadingIndicator' />
      </span>
    ) : (
      'Notion'
    )

    const notionOptions = isLoadingNotion
      ? [{}]
      : getOptions(notionResultsSliced, totalResultsByType.notion, 'notion')

    // We receive all notion results in search and we only want to show the top 5
    const oneDriveResultsSliced = oneDriveSearchResults.slice(0, PAGE_SIZES.dropdown)

    const oneDriveLabel = isLoadingOneDrive ? (
      <span>
        OneDrive
        <CirclesLoadingIndicator className='LoadingIndicator' />
      </span>
    ) : (
      'OneDrive'
    )

    const oneDriveOptions = isLoadingOneDrive
      ? [{}]
      : getOptions(oneDriveResultsSliced, totalResultsByType.oneDrive, 'one_drive')

    const externalResults = [
      { label: googleDriveLabel, options: googleDriveOptions },
      { label: confluenceLabel, options: confluenceOptions },
      { label: bloomfireLabel, options: bloomfireOptions },
      { label: notionLabel, options: notionOptions },
      { label: oneDriveLabel, options: oneDriveOptions },
    ]

    const searchResults = [
      { label: I18N('top_results'), options: getOptions(clearySearchResults) },
      ...externalResults,
    ]

    const seeAllResults = { type: 'see_all' }

    if (totalResults === 0 || isLoading) return []
    if (isSearchGoLink(inputValue)) return searchResults

    return [seeAllResults, ...searchResults]
  }

  // Without setting value/label, options won't render
  const filterOption = (option, filter) => ({
    label: option.data.value,
    value: option.data.value,
    ...option.data,
  })

  const noOptionHandler = ({ inputValue }) => {
    if (!isLoading && !_.isEmpty(inputValue) && _.isEmpty(hydratedSearchResults())) {
      return I18N('no_results_found')
    }
    return null
  }

  const handleInputChange = (newValue) => {
    enterGoesToSearchPage.current = true
    fetchResults(
      newValue,
      dispatch,
      isGoogleDriveSearchEnabled,
      isConfluenceSearchEnabled,
      isNotionSearchEnabled,
      isBloomfireSearchEnabled,
      isOneDriveSearchEnabled,
      searchSessionId
    )
    setInputValue(newValue)
  }

  const handleFocus = () => {
    setIsFocused(true)
    dispatch(searchSlice.actions.setSessionId(window.crypto.randomUUID()))
  }

  // The clearSearchResults variable is updated on every render.
  // We construct a string by combining all the result IDs to determine
  // if there have been any changes in the results.
  const clearySearchResultsKey = clearySearchResults.map(({ id }) => id).join('-')

  useEffect(() => {
    // when new results appear
    if (clearySearchResultsKey) {
      // allow the default action of selecting an item from the menu.
      enterGoesToSearchPage.current = false
    }
  }, [clearySearchResultsKey])

  const goToSearchPage = (searchTerm) => {
    // Removes the "go/" prefix if present and redirects the user to golinks results tab
    if (isSearchGoLink(searchTerm)) {
      const golinkInputValue = searchTerm.replace('go/', '')
      history.push(`/search?filter=links&type=go_link&query=${golinkInputValue}`)

      return
    }

    history.push(`/search?query=${searchTerm}`, { searchSessionId })
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY_ASCII_CODE && enterGoesToSearchPage.current && inputValue) {
      e.preventDefault()
      selectRef.current.blur()
      goToSearchPage(inputValue)
    }
  }

  const trackResult = (searchResult) => {
    const { type } = searchResult

    // we don't need to track see_more
    // the inner event type will be tracked in search:results after we load the
    // see more page
    if (type === 'see_more') { return }

    if (type !== 'see_all') {
      dispatch(searchSlice.asyncActions.trackResultClicked('dropdown', searchResult, searchSessionId))
      return
    }

    // see_all tracking
    let searchConversionTime = 0

    if (searchStartedAt) {
      const searchEndedAt = new Date()
      searchConversionTime = (searchEndedAt - searchStartedAt)
    }

    const allResults = hydratedSearchResults().filter(result => result.value !== 'label')

    const resultsTypesSet = new Set(
      allResults.map(r => r.type).sort((a, b) => a.localeCompare(b))
    )

    resultsTypesSet.delete('see_all')

    trackEvent('search:clicked_see_all', {
      search_conversion_time: searchConversionTime,
      query: searchTerm,
      results_count: allResults.length - 1, // removing see_all
      results_types: [...resultsTypesSet],
      session_id: searchSessionId,
      top_results_enabled: true,
    })
  }

  const navigateToResult = (searchResult) => {
    const { type } = searchResult

    switch (type) {
    case 'go_link':
    case 'go_link/alias':
      // need to set window location since this is an actual redirect to our backend
      window.location = searchResult.displayPath
      break
    case 'google_drive':
      window.open(searchResult.link)
      break
    case 'one_drive':
      window.open(searchResult.contentUrl)
      break
    case 'confluence':
    case 'bloomfire':
    case 'notion':
      window.open(searchResult.url)
      break
    case 'app_launcher/app':
      window.open(searchResult.displayPath)
      break
    case 'see_more':
      const filter = searchResult.label || 'all'
      history.push(`/search?filter=${filter}&query=${searchTerm}`, { searchSessionId })
      break
    case 'see_all':
      goToSearchPage(searchTerm)
      break
    case 'attached_file_content':
      window.open(searchResult.displayPath)
      break
    default:
      const searchHighlights = extractHighlights(Object.values(searchResult.highlights))

      history.push(`${searchResult.displayPath}?${qs.stringify(snakeCaseKeys(searchHighlights))}`)
      break
    }
  }

  const handleGlobalSearchResultSelect = (searchResult) => {
    trackResult(searchResult)
    selectRef.current.blur()
    navigateToResult(searchResult)
  }

  return (
    <ErrorBoundary
      history={history}
      className='GlobalSearch__ErrorBoundary p-2'
      showTitle={false}
      onErrorRender={i18nFormat(
        I18N('error_description'),
        <Link className='nav-link' to={`/search?query=${searchTerm}`}>
          {I18N('error_click_here')}
        </Link>
      )}
    >
      <div className={classNames('GlobalSearch', { isFocused })}>
        <ReactSelect
          value={null}
          ref={selectRef}
          isLoading={isLoading || isLoadingGoogleDrive || isLoadingConfluence || isLoadingNotion || isLoadingBloomfire}
          onKeyDown={handleKeyDown}
          onInputChange={handleInputChange}
          options={hydratedSearchResults()}
          onChange={handleGlobalSearchResultSelect}
          filterOption={filterOption}
          components={{
            GroupHeading,
            Option,
            ValueContainer,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          classNamePrefix='GlobalSearch-Dropdown'
          maxMenuHeight={window.innerHeight - 150}
          placeholder={<div className='text-secondary'>{I18N('search_placeholder')}</div>}
          noOptionsMessage={noOptionHandler}
          onFocus={handleFocus}
          onBlur={() => setIsFocused(false)}
          // !! TESTING PROPS !!
          // menuIsOpen
          // isFocused
          // autoFocus
          // blurInputOnSelect={false}
          // openMenuOnFocus
          // closeMenuOnSelect={false}
          // inputValue={inputValue}
          // value={inputValue}
        />
      </div>
    </ErrorBoundary>
  )
}

export default GlobalSearch
