import { GROUP_BY_DAY } from 'components/analytics/common/groupByTabs'
import { DEFAULT_DAILY_OPTION } from 'components/analytics/common/timeRangeFilter'
import useCompletedTargetingRules from 'components/analytics/helpers/useCompletedTargetingRules'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'
import { useMemo, useRef } from 'react'
import TargetingRulesType from 'types/audience/targetingRules'
import { TARGET_NO_ONE } from 'utils/normalizeTargetingRules'

export const TAB_HEADER_QUERY_PARAMS = ['timeRange', 'periodStart', 'periodEnd', 'groupBy', 'targetingRules']

const useTabHeader = () => {
  const locationSearchRef = useRef<string>('')

  const [timeRange, setTimeRange] = useQueryParamState({
    ref: locationSearchRef,
    param: 'timeRange',
    initialValue: DEFAULT_DAILY_OPTION,
    asJson: true,
  })

  const [periodStart, setPeriodStart] = useQueryParamState({
    ref: locationSearchRef,
    param: 'periodStart',
    initialValue: DEFAULT_DAILY_OPTION.value,
    asJson: true,
  })

  const [periodEnd, setPeriodEnd] = useQueryParamState<string | undefined>({
    ref: locationSearchRef,
    param: 'periodEnd',
    initialValue: undefined,
  })

  const [groupBy, setGroupBy] = useQueryParamState({
    ref: locationSearchRef,
    param: 'groupBy',
    initialValue: GROUP_BY_DAY,
  })

  const [companyId, setCompanyId] = useQueryParamState<string | undefined>({
    ref: locationSearchRef,
    param: 'companyId',
    initialValue: undefined,
  })

  const initialTargetingRules: TargetingRulesType = useMemo(() => (
    {
      ...TARGET_NO_ONE,
      // always start with one rule in it
      // react random key will prevent to remove an item and keep the same previous
      // state, since we're using the index of the array as the key
      rules: [{ reactKey: 'initialKey' }],
    }
  ), [])

  const [targetingRules, setTargetingRulesState] = useQueryParamState({
    ref: locationSearchRef,
    param: 'targetingRules',
    initialValue: initialTargetingRules,
    asJson: true,
  })

  const setTargetingRules = (targetingRules: TargetingRulesType) => {
    // if rules is empty aray, let's unset the rules from the url
    // this will make the usage of initialTargetingRules
    if (targetingRules.rules.length === 0) {
      setTargetingRulesState(undefined)
    } else {
      setTargetingRulesState(targetingRules)
    }
  }

  const completedTargetingRules = useCompletedTargetingRules(targetingRules)

  return {
    targetingRules,
    completedTargetingRules,
    setTargetingRules,
    setGroupBy,
    periodStart,
    setPeriodStart,
    periodEnd,
    setPeriodEnd,
    groupBy,
    timeRange,
    setTimeRange,
    companyId,
    setCompanyId,
  }
}

export default useTabHeader
