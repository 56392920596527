import { useRef, useState } from 'react'

const useDebouncedClose = (onClose: () => void, delay = 500) => {
  const [isClosing, setIsClosing] = useState(false)

  const closeTimeout = useRef<any>()
  const handleClose = () => {
    clearTimeout(closeTimeout.current)
    setIsClosing(true)

    closeTimeout.current = setTimeout(() => {
      onClose()
      setIsClosing(false)
    }, delay)
  }

  return [isClosing, handleClose] as const
}

export default useDebouncedClose
