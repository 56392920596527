import ReactSelect from 'components/common/react_select'
import TicketStatusLabel from 'components/ticketing/ticketStatusLabel'
import { components } from 'react-select'
import React from 'react'
import { TicketStatusEnum } from 'types/ticketing/ticket'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.ticketing')


const wrapTicketStatusLabelWithSelectComponent = Component => props => (
  <Component {...props}>
    <TicketStatusLabel status={props.data.id} />
  </Component>
)

const Option = wrapTicketStatusLabelWithSelectComponent(components.Option)
const SingleValue = wrapTicketStatusLabelWithSelectComponent(components.SingleValue)
const MultiValue = wrapTicketStatusLabelWithSelectComponent(components.MultiValue)

const options = [TicketStatusEnum.New, TicketStatusEnum.InProgress].map(status => ({
  id: status,
}))

interface Props {
  value?: TicketStatusEnum
  onChange?: (value: TicketStatusEnum | undefined) => void
  includeOverdue?: boolean
}

const TicketStatusSelect = ({ value, onChange, includeOverdue = false }: Props) => {
  const allOptions = includeOverdue ? [...options, { id: 'overdue' }] : options

  return (
    <ReactSelect
      className='TicketStatusSelect'
      options={allOptions}
      getOptionValue={option => option.id}
      value={allOptions.find(option => option.id === value)}
      onChange={option => onChange?.(option?.id)}
      isClearable
      placeholder={I18N('status_select.filter_by_status')}
      components={{
        Option,
        SingleValue,
        MultiValue,
      }}
    />
  )
}

export default TicketStatusSelect
