import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ReactSelect, { Creatable } from 'components/common/react_select'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import displayAuthorSlice from 'redux/slices/displayAuthors'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'
import adminArticleSlice from 'redux/slices/admin/articles'
import { present } from 'components/common/utils'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import navigationBlockerSlice from 'redux/slices/navigationBlocker'
import AppModal from 'components/common/modal'
import { Button } from 'components/common/buttons'

const I18N = i18nPath('views.admin.article_editor.by_line')

const TEXT_DISPLAY_MODE = 'text'
const USERS_DISPLAY_MODE = 'users'

const ArticleCreatorByLine = ({ user }) => (
  <div className='ArticleCreatorByLine mb-3'>
    <div className='d-flex align-items-center'>
      <span className='text-secondary text-small'>{I18N('by')}&nbsp;</span>
      <span className='text-primary'>
        <EmployeeLinkWithPopover user={user} />
      </span>
    </div>
  </div>
)

const ArticleManagerByLine = ({ workingCopy, updateWorkingCopy }) => {
  const { displayAuthorText, authors } = workingCopy

  const [displayAuthorMode, setDisplayAuthorMode] = useState(displayAuthorText ? TEXT_DISPLAY_MODE : USERS_DISPLAY_MODE)
  const displayAuthors = useSelector(displayAuthorSlice.selectors.getDisplayAuthors)

  const toggleAuthorMode = () => {
    if (displayAuthorMode === TEXT_DISPLAY_MODE) {
      updateWorkingCopy({ displayAuthorText: null })
      setDisplayAuthorMode(USERS_DISPLAY_MODE)
    } else {
      updateWorkingCopy({ authors: [] })
      setDisplayAuthorMode(TEXT_DISPLAY_MODE)
    }
  }

  return (
    <div className='ArticleByLineEditor mb-3'>
      <div className='d-flex align-items-center'>
        <span className='text-secondary'>{I18N('by')}</span>
        {displayAuthorMode === TEXT_DISPLAY_MODE ? (
          <Creatable
            className='ml-2 w-50'
            value={{ label: workingCopy.displayAuthorText, value: workingCopy.displayAuthorText }}
            onChange={option => updateWorkingCopy({ displayAuthorText: option?.value })}
            placeholder='hola'
            options={displayAuthors.map(author => ({ value: author, label: author }))}
            isClearable
          />
        ) : (
          <EmployeeSearch
            isMulti
            className='ml-2 w-50'
            onChange={options => updateWorkingCopy({ authors: options })}
            selectedEmployees={authors}
          />
        )}
      </div>
      <div className='text-small text-secondary mt-1'>
        <span
          className='font-weight-700 pointer mr-1'
          onClick={toggleAuthorMode}
        >
          {I18N('click_here')}
        </span>
        <span>{I18N(`change_from_${displayAuthorMode}_mode`)}</span>
      </div>
    </div>
  )
}

const ArticleByLineEditor = ({ workingCopy, updateWorkingCopy }) => {
  const { authors } = workingCopy
  const { permissions } = useCurrentUser()

  return (
    <>
      {permissions.articleManager ? (
        <ArticleManagerByLine workingCopy={workingCopy} updateWorkingCopy={updateWorkingCopy} />
      ) : (
        <ArticleCreatorByLine user={authors[0]} />
      )}
    </>
  )
}

const WITH_GROUP_TEMPLATE = 'with_group_template_modal'
const WITHOUT_GROUP_TEMPLATE = 'without_group_template_modal'

type TemplateWarningValue = typeof WITHOUT_GROUP_TEMPLATE | typeof WITH_GROUP_TEMPLATE | null

export const GroupArticleByLineEditor = ({ workingCopy, updateWorkingCopy }) => {
  const {
    id, groupId, authors, groupInformation, publishedAt,
  } = workingCopy
  const currentGroup = groupInformation?.group
  const editableGroups = useSelector(adminArticleSlice.selectors.getEditableGroups())
  const isDraft = !publishedAt
  const dispatch = useDispatch()
  const history = useHistory()
  const [modal, setModal] = useState<TemplateWarningValue>(null)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [newGroup, setNewGroup] = useState<any>(null)

  const [createArticle] = useApi(API.admin.articles.create, {
    toastErrorMessage: I18N('error_creating_article'),
    onSuccess: (article) => {
      dispatch(navigationBlockerSlice.asyncActions.performWithoutBlocking(() => {
        history.push(`/posts/${article.id}`)
      }))
    },
  })

  const [destroyArticle] = useApi(API.admin.articles.destroy)

  const handleGroupChange = async (group) => {
    if (!id && !group.defaultArticleTemplateId) {
      // we need to fix the article serializer so we don't have that groupInformation key
      // for now, I'd rather be consistent with how we get data from the backend
      updateWorkingCopy({
        groupId: group?.id,
        groupInformation: { group },
        notificationChannels: [group.msTeamChannel, group.slackChannel].filter(present),
      })

      return
    }

    setNewGroup(group)

    if (group.defaultArticleTemplateId) {
      setModal(WITH_GROUP_TEMPLATE)
    } else {
      setModal(WITHOUT_GROUP_TEMPLATE)
    }
  }

  const createDraftArticleForGroup = async (id, groupId, templateId) => {
    if (id) {
      await destroyArticle({ id })
    }

    if (modal === WITHOUT_GROUP_TEMPLATE) {
      history.push(`/posts/new?groupId=${groupId}`)
    } else {
      await createArticle({ groupId, templateId })
    }
  }

  const onModalConfirm = async () => {
    if (!modal) return

    setIsModalLoading(true)

    await createDraftArticleForGroup(id, newGroup.id, newGroup.defaultArticleTemplateId)

    setIsModalLoading(false)
    setModal(null)
  }

  return (
    <div className='ArticleByLineEditor mb-3'>
      <div className='d-flex align-items-center'>
        <span className='text-secondary text-small'>{I18N('by')}&nbsp;</span>
        <span className='text-primary'>
          {!_.isEmpty(authors) && <EmployeeLinkWithPopover user={authors[0]} />}
        </span>
        <span className='text-secondary text-small'>&nbsp;{I18N('to')}&nbsp;</span>
        {isDraft ? (
          <ReactSelect
            className='ClearyReactSelect w-30'
            options={editableGroups}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            value={editableGroups.find(group => group.id === groupId)}
            onChange={handleGroupChange}
          />
        ) : (
          <span className='text-primary font-weight-600 text-small'>{currentGroup?.name}</span>
        )}
      </div>

      {modal && (
        <AppModal
          title={I18N(`${modal}.title`)}
          toggle={() => setModal(null)}
          className='ArticleByLineEditor__modal'
          visible
        >
          <div className='text-center text-secondary'>
            {I18N(`${modal}.description`)}
          </div>

          <div className='text-center mt-4 text-small'>
            <Button variant='link' onClick={() => setModal(null)}>{I18NCommon('cancel')}</Button>
            <Button onClick={onModalConfirm} showLoadingSpinner={isModalLoading}>
              {I18N(`${modal}.confirm`)}
            </Button>
          </div>
        </AppModal>
      )}
    </div>
  )
}


export default ArticleByLineEditor
