import React, { useContext } from 'react'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import EmployeeSidebarCard from 'components/analytics/ai_answers/employeeSidebarCard'
import { UserType } from 'types/user'
import TicketStatusLabel from 'components/ticketing/ticketStatusLabel'
import DatePicker from 'components/form_fields/datePicker'
import moment from 'moment'
import { TicketType } from 'types/ticketing/ticket'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { ButtonNarrow } from 'components/common/buttons'
import TicketActivity from 'components/ticketing/view_ticket_sidebar/ticketActivity'
import AssigneeSelect from 'components/admin/ticketing/assigneeSelect'
import TicketDropdown from 'components/ticketing/view_ticket_sidebar/ticketDropdown'
import TicketingContext from 'components/ticketing/ticketingContext'

type Props = {
  ticket: TicketType
}

const I18N = i18nPath('views.ticketing.view_ticket_sidebar')

const EditableTicketDetailsPanel = ({ ticket }: Props) => {
  const { fetchCounts } = useContext(TicketingContext)
  const [updateTicket, { isLoading: isUpdating }] = useApi(API.admin.ticketing.tickets.update, {
    updateEntitySlice: true,
    toastSuccessMessage: I18N('ticket_successfully_saved'),
    toastErrorMessage: I18NCommon('generic_error'),
  })
  const [closeTicket, { isLoading: isClosing }] = useApi(API.admin.ticketing.tickets.close, {
    onSuccess: fetchCounts,
    updateEntitySlice: true,
    toastSuccessMessage: I18N('ticket_closed'),
    toastErrorMessage: I18NCommon('generic_error'),
  })
  const [reopenTicket, { isLoading: isReopening }] = useApi(API.admin.ticketing.tickets.reopen, {
    onSuccess: fetchCounts,
    updateEntitySlice: true,
    toastSuccessMessage: I18N('ticket_reopened'),
    toastErrorMessage: I18NCommon('generic_error'),
  })

  const isClosed = ticket.status === 'closed'

  return (
    <>
      <div className='TicketRequester border-bottom p-3'>
        <div className='text-small font-weight-600 mb-3'>{I18N('requester_details')}</div>
        <EmployeeSidebarCard
          user={ticket.user as UserType}
          isBordered={false}
          imageSize='44px'
        />
      </div>
      <div className='TicketDetails position-relative border-bottom p-3 d-flex flex-column gap-3'>
        <TicketDropdown ticket={ticket} isAdmin className='position-absolute right-0 mr-1' />
        <div className='text-small font-weight-600 mb-3'>{I18N('ticket_details')}</div>
        <div>
          <label className='text-small text-secondary'>{I18N('assigned_to')}</label>
          <AssigneeSelect ticket={ticket} />
        </div>
        <div>
          <label className='text-small text-secondary'>{I18N('status')}</label>
          <div>
            <TicketStatusLabel status={ticket.status} />
          </div>
        </div>
        <div>
          <label className='text-small text-secondary'>{I18N('id')}</label>
          <div>#{ticket.number}</div>
        </div>
        <div>
          <label className='text-small text-secondary'>{I18N('due_date')}</label>
          {isUpdating ? (
            <CirclesLoadingIndicator />
          ) : (
            <DatePicker
              selected={ticket.dueDate ? moment(ticket.dueDate) : undefined}
              onChange={date => updateTicket({ id: ticket.id, dueDate: date?.toISOString() || null })}
              className='w-100'
              isDisabled={isClosed}
            />
          )}
        </div>
        <div>
          {isClosing || isReopening ? (
            <CirclesLoadingIndicator />
          ) : (
            <ButtonNarrow
              onClick={isClosed ? () => reopenTicket(ticket.id) : () => closeTicket(ticket.id)}
              variant={isClosed ? 'secondary' : 'secondary-danger'}
            >
              {isClosed ? I18N('reopen_ticket') : I18N('close_ticket')}
            </ButtonNarrow>
          )}
        </div>
      </div>
      <TicketActivity ticket={ticket} isAdmin />
    </>
  )
}

export default EditableTicketDetailsPanel
