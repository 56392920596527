import ChatMessage from 'components/ai/chat/ask_ai_modal/chatMessage'
import useStickyScroll from 'components/ai/chat/ask_ai_modal/hooks/useStickyScroll'
import { UserMessageType, AiMessageType, ConversationType } from 'components/ai/chat/ask_ai_modal/types'
import EmployeeSidebarCard from 'components/analytics/ai_answers/employeeSidebarCard'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'
import useCursorPagination from 'components/common/hooks/useCursorPagination'
import useFetch from 'components/common/hooks/useFetch'
import moment from 'moment'
import { useIsGlobalAnalytics } from 'pages/admin/global_analytics/globalAnalyticsProvider'
import React, { useEffect, useMemo } from 'react'
import API from 'services/api'
import { UserType } from 'types/user'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'

const I18N = i18nPath('views.platform_analytics.ai_answers')

type ConversationHistoryProps = {
  conversationId: string
}

type EnhancedConversationType = ConversationType & {
  user: UserType
}

const ConversationHistory = ({ conversationId }: ConversationHistoryProps) => {
  const isGlobal = useIsGlobalAnalytics()

  const {
    data: conversation,
  } = useFetch<EnhancedConversationType>(
    () => API.admin.ai.chat.conversations.fetch(conversationId, { isGlobal }),
    [conversationId]
  )

  const {
    data: rawMessages,
    isLoading: isLoadingMessages,
    onLoadMore: onLoadMoreMessages,
    hasMore: hasMoreMessages,
  } = useCursorPagination<AiMessageType | UserMessageType>(
    (conversationId, params) => API.admin.ai.chat.messages.fetchAll(conversationId, { ...params, isGlobal }),
    [conversationId]
  )

  const messages = useMemo(
    () => _.sortBy(rawMessages, message => moment(message.createdAt).unix()),
    [rawMessages]
  )

  const showLoadingSpinner = (messages.length === 0 && isLoadingMessages) || hasMoreMessages

  const { scrollableRef, isScrollAtTheTop } = useStickyScroll()

  useEffect(() => {
    if (isScrollAtTheTop && hasMoreMessages) {
      onLoadMoreMessages()
    }
  }, [isScrollAtTheTop, hasMoreMessages])

  return (
    <div className='d-flex flex-column h-100'>
      {conversation && (
        <div className='border-shadow p-3'>
          <div className='d-flex align-items-center gap-1 mt-4'>
            <span className='text-secondary text-small'>{I18N('sidebar.submitted_by')}</span>
            <EmployeeLinkWithPopover user={conversation.user} />
            <span>•</span>
            <span className='text-secondary text-small'>
              {i18nMoment(new Date(conversation.createdAt)).format('lll')}
            </span>
          </div>
        </div>
      )}

      <div className='ConversationHistory__Content flex-grow-1 border-shadow p-3' ref={scrollableRef}>
        {showLoadingSpinner && <CirclesLoadingIndicator />}

        {messages.map(message => (
          <ChatMessage key={message.id} message={message} />
        ))}
      </div>

      {conversation && (
        <div className='px-3 my-4'>
          <EmployeeSidebarCard user={conversation.user} />
        </div>
      )}
    </div>
  )
}

export default ConversationHistory
