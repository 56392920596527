import StatusLabel from 'components/common/status_label'
import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

const STATUS_COLOR_MAP = {
  new: 'green',
  in_progress: 'blue',
  closed: 'gray',
  overdue: 'red',
}

const I18N = i18nPath('views.ticketing.status')

interface Props {
  status: string
}

const TicketStatusLabel = ({ status }: Props) => (
  <StatusLabel color={STATUS_COLOR_MAP[status]}>
    {I18N(status)}
  </StatusLabel>
)

export default TicketStatusLabel
