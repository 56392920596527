import React, { useEffect, useState } from 'react'
import {
  Background,
  Handle,
  Position,
  ReactFlow
} from '@xyflow/react'
import { RootSidebar } from 'components/common/sidebar'
import CdnSvg from 'components/common/cdnSvg'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import ActionForm from './actionForm'
import CreateActionModal from './createActionModal'

const ActionNode = ({ data }) => (
  <>
    {!data.isFirst && (
      <Handle
        type='target'
        position={Position.Top}
        style={{
          backgroundColor: 'transparent',
          border: '0',
        }}
      />
    )}
    <div className='react-flow__node-default'>
      <DropdownMenuContainer
        className='position-absolute default-dropdown'
        menuType='kebab'
        closeMenuOnItemClick
      >
        <section>
          <CardDropdownMenuItem
            primaryText='Delete'
            onClick={data.onDelete}
            icon={<CdnSvg className='mr-2' src='/images/trashIcon.svg' />}
          />
        </section>
      </DropdownMenuContainer>
      {data.workflowAction.name}
    </div>
    <Handle
      type='source'
      position={Position.Bottom}
      style={{
        backgroundColor: 'transparent',
        border: '0',
      }}
    />
  </>
)

const nodeTypes = {
  action: ActionNode,
}

const Actions = ({ workflow, fetchWorkflow }) => {
  const [selectedAction, setSelectedAction] = useState<any>(null)
  const [showCreateActionModal, setShowCreateActionModal] = useState(false)
  const [nodes, setNodes] = useState<any[]>([])
  const [edges, setEdges] = useState<any[]>([])

  const [deleteAction] = useApi(API.admin.workflows.actions.destroy, {
    onSuccess: () => {
      fetchWorkflow(workflow.id)
      setSelectedAction(null)
    },
  })

  const [updateAction, { isLoading: isUpdatingAction }] = useApi(
    API.admin.workflows.actions.update,
    {
      onSuccess: setSelectedAction,
    }
  )

  const handleNodeClick = (_event, node) => {
    if (node.id === 'add-action') {
      setShowCreateActionModal(true)
    } else {
      setSelectedAction(node.data.workflowAction)
    }
  }

  const handleCreateAction = (action) => {
    setShowCreateActionModal(false)
    setSelectedAction(action)
    fetchWorkflow(workflow.id)
  }

  const handleUpdateAction = (settings, configuration) => {
    updateAction(workflow.id, selectedAction.id, { settings, configuration })
  }

  useEffect(() => {
    if (!workflow) return

    const newNodes: any[] = []
    const newEdges: any[] = []
    setNodes([])
    setEdges([])

    workflow.actions.forEach((action, index) => {
      newNodes.push({
        id: action.id,
        type: 'action',
        data: {
          label: action.name,
          workflowAction: action,
          isFirst: index === 0,
          onDelete: () => deleteAction(workflow.id, action.id),
        },
        position: { x: 0, y: index * 100 },
      })
    })

    newNodes.push({
      id: 'add-action',
      type: workflow.actions.length > 0 ? 'output' : 'input',
      data: { label: 'Add Action' },
      position: { x: 0, y: workflow.actions.length * 100 },
    })

    for (let i = 1; i < workflow.actions.length; i += 1) {
      newEdges.push({
        id: `${workflow.actions[i - 1].id}-${workflow.actions[i].id}`,
        source: workflow.actions[i - 1].id,
        target: workflow.actions[i].id,
      })
    }

    if (workflow.actions.length > 0) {
      newEdges.push({
        id: `${workflow.actions[workflow.actions.length - 1].id}-add-action`,
        source: workflow.actions[workflow.actions.length - 1].id,
        target: 'add-action',
      })
    }

    setNodes(newNodes)
    setEdges(newEdges)
  }, [workflow])

  return (
    <>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodeClick={handleNodeClick}
        nodeTypes={nodeTypes}
        fitView
        fitViewOptions={{
          padding: 0.2,
          maxZoom: 1.0,
          minZoom: 0.1,
        }}
      >
        <Background />
      </ReactFlow>

      {selectedAction && (
        <RootSidebar
          onClose={() => setSelectedAction(null)}
          title={<div>Action</div>}
          isOpen={!!selectedAction}
          className='WorkflowEditPage__RootSidebar'
        >
          <ActionForm
            action={selectedAction}
            isLoading={isUpdatingAction}
            onSave={handleUpdateAction}
          />
        </RootSidebar>
      )}

      {showCreateActionModal && (
        <CreateActionModal
          workflowId={workflow.id}
          onCancel={() => setShowCreateActionModal(false)}
          onCreate={handleCreateAction}
        />
      )}
    </>
  )
}

export default Actions
