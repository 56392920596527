import React from 'react'
import classNames from 'classnames'
import Tooltip from 'components/common/clearyTooltip'
import { Placement } from 'tippy.js'

type StatusLabelColor = 'gray' | 'red' | 'green' | 'yellow' | 'blue' | 'red-secondary'

type Props = {
  color?: StatusLabelColor
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

const StatusLabel = ({
  color = 'gray',
  children,
  className = 'text-small font-weight-500',
  style = {},
}: Props) => (
  <span style={style} className={classNames(`StatusLabel d-inline-flex align-items-center justify-content-center status-${color}`, className)}>
    {children}
  </span>
)

type StatusLabelWithTooltipProps = Props & {
  statusLabelClassName?: string
  text: string
  tooltipClassName?: string
  placement?: Placement
}

export const StatusLabelWithTooltip = ({
  color = 'gray' as StatusLabelColor,
  children,
  statusLabelClassName = 'text-small font-weight-500',
  tooltipClassName = '',
  text = '',
  placement = 'bottom' as Placement,
}: StatusLabelWithTooltipProps) => (
  <Tooltip
    className={tooltipClassName}
    placement={placement}
    content={(
      <StatusLabel color={color} className={statusLabelClassName}>
        {children}
      </StatusLabel>
    )}
  >
    {text}
  </Tooltip>
)

export default StatusLabel
