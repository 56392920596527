import { Button } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import useApi from 'components/common/hooks/useApi'
import useFetch from 'components/common/hooks/useFetch'
import TipTapRichTextView from 'components/common/tiptap/tiptapRichTextView'
import React from 'react'
import API from 'services/api'
import { SuggestedMessageType } from 'services/api/admin/ticketing'
import { TicketingMessageType } from 'types/ticketing/message'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.ticketing.view_ticket_sidebar.current_suggested_message')

type CurrentSuggestedMessageProps = {
  addMessage: (message: TicketingMessageType) => void
  updateContentMessage: (content: string) => void
  ticketId: string
}

const CurrentSuggestedMessage = ({ ticketId, addMessage, updateContentMessage }: CurrentSuggestedMessageProps) => {
  const {
    data: suggestedMessage,
    callApi: fetchSuggestedMessage,
  } = useFetch<SuggestedMessageType>(
    API.admin.ticketing.tickets.suggestedMessage.fetch,
    [ticketId]
  )

  const handleSuccess = (message?: TicketingMessageType) => {
    if (message) {
      addMessage(message)
    }

    fetchSuggestedMessage(ticketId)
  }

  const [sendReply, { isLoading: isSendingReply }] = useApi(
    API.admin.ticketing.tickets.suggestedMessage.sendReply,
    {
      onSuccess: handleSuccess,
    }
  )

  const [editReply, { isLoading: isEditingReply }] = useApi(
    API.admin.ticketing.tickets.suggestedMessage.editReply,
    {
      onSuccess: () => {
        updateContentMessage(suggestedMessage!.content)
        handleSuccess()
      },
    }
  )

  const isLoading = isSendingReply || isEditingReply

  if (!suggestedMessage) {
    return null
  }

  return (
    <div className='CurrentSuggestedMessage border-color-ai'>
      <div className='CurrentSuggestedMessage-wrapper'>
        <div className='mb-4 d-flex align-items-center'>
          <CdnSvg className='mr-3' src='/images/illustrations/aiGradientIcon.svg' />
          <span className='text-normal font-weight-400'>{I18N('title')}</span>
        </div>
        <TipTapRichTextView html={suggestedMessage.content} />

        <div className='mt-3 d-flex gap-3'>
          <Button
            onClick={() => sendReply(ticketId)}
            showLoadingSpinner={isLoading}
            disabled={isLoading}
            className='btn-ai'
          >
            {I18N('send_reply')}
          </Button>

          <Button
            variant='secondary'
            onClick={() => editReply(ticketId)}
            showLoadingSpinner={isLoading}
            disabled={isLoading}
          >
            {I18N('edit_reply')}
          </Button>

        </div>

        <hr className='my-4' />

        <div className='d-flex align-items-center gap-2'>
          <span role='img' aria-label='warning'>⚠️</span>
          <span className='font-weight-400 text-secondary text-small'>{I18N('helper')}</span>
        </div>
      </div>
    </div>
  )
}

export default CurrentSuggestedMessage
