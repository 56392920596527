import React, { useEffect, useRef } from 'react'
import CdnSvg from 'components/common/cdnSvg'
import classNames from 'classnames'
import useClickOutside from 'components/common/hooks/useClickOutside'
import LoadingSpinner from 'components/common/loadingSpinner'
import { createPortal } from 'react-dom'
import useAnimationState from 'components/common/hooks/useAnimationState'

interface RootSidebarProps {
  children: React.ReactNode
  title: string | React.ReactNode
  onClose: () => void
  loading?: boolean
  isOpen?: boolean
  className?: string
}

export const RootSidebar = ({
  title,
  children,
  onClose,
  loading = false,
  isOpen = false,
  className = '',
}: RootSidebarProps) => {
  const animationState = useAnimationState(isOpen, 300)
  const sidebarRef = useRef<HTMLDivElement>(null)

  useClickOutside({
    ref: sidebarRef,
    enableClickOutside: isOpen,
    onClickOutside: onClose,
  })

  useEffect(() => {
    if (isOpen) {
      document.documentElement.classList.add('hide-main-scrollbar')
    } else {
      document.documentElement.classList.remove('hide-main-scrollbar')
    }

    return () => {
      document.documentElement.classList.remove('hide-main-scrollbar')
    }
  }, [isOpen])

  if (animationState === 'hidden') return null

  return createPortal(
    <div
      className={classNames('RootSidebar', animationState, className)}
      tabIndex={-1}
      ref={sidebarRef}
    >
      <Sidebar
        title={title}
        onClose={onClose}
        loading={loading}
        visible
      >
        <div className='RootSidebarContent'>
          {children}
        </div>
      </Sidebar>
    </div>,
    document.body
  )
}

interface Props {
  title: string | React.ReactNode
  onClose?: () => void
  floating?: boolean
  fixed?: boolean
  loading?: boolean
  children: any
  visible: boolean
  className?: string
}

const Sidebar = ({
  title,
  onClose,
  children,
  floating = false,
  loading = false,
  visible,
  className,
}: Props) => {
  const sidebarRef = useRef(null)

  useClickOutside({
    ref: sidebarRef,
    enableClickOutside: floating && visible && !!onClose,
    onClickOutside: onClose || (() => {}),
  })

  return (
    <div
      ref={sidebarRef}
      className={classNames('Sidebar py-4 px-3', {
        'Sidebar--floating': floating,
      }, className)}
    >
      <div className='Sidebar__header mb-4 d-flex align-items-center justify-content-between position-relative'>
        <h5>{title}</h5>

        {onClose && (
          <div
            data-testid='close-button'
            className='pointer position-absolute top-0 right-0 p-1 close-button'
            onClick={onClose}
          >
            <CdnSvg src='/images/xIcon.svg' data-testid='close-icon' />
          </div>
        )}
      </div>

      {children}

      {loading && (
        <div className='Sidebar__loading position-absolute w-100 h-100 left-0 top-0 d-flex align-items-center justify-content-center'>
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

export default Sidebar
