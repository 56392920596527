import { createContext } from 'react'
import { TicketType } from 'types/ticketing/ticket'

interface TicketingContextProps {
  fetchCounts: () => void
  removeTicket: (ticket: TicketType) => void
}

const TicketingContext = createContext<TicketingContextProps>({
  fetchCounts: () => {},
  removeTicket: () => {},
})

export default TicketingContext


