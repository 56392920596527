import React from 'react'
import { Link, Redirect } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'

import useQueryParams from 'components/common/hooks/useQueryParams'
import { Button } from 'components/common/buttons'
import ArticlesTable from 'components/admin/articles/articlesTable'
import ArticleStatsTable from 'components/admin/articles/articleStatsTable'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import TemplatesListTable from 'components/admin/articles/templates/templatesListTable'
import GearIcon from 'components/icons/gearIcon'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import ButtonWithIcon from 'components/common/buttons/buttonWithIcon'

const I18N = i18nPath('views.admin.article_list')

const TABS = {
  Articles: 'articles',
  Analytics: 'analytics',
  Templates: 'templates',
}

const ArticleListPage = () => {
  const { selectedTab } = useQueryParams()
  const { permissions } = useCurrentUser()

  const isArticlesTab = !selectedTab || selectedTab === TABS.Articles
  const isAnalyticsTab = selectedTab === TABS.Analytics
  const isTemplatesTab = selectedTab === TABS.Templates

  if (!selectedTab) {
    return <Redirect exact from='/admin/articles' to={`/admin/articles?selectedTab=${TABS.Articles}`} />
  }

  return (
    <div className='ArticleListPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>{I18N('articles')}</h3>
        <div className='AdminButtonContainer'>
          <Link to='/admin/articles/new'>
            <ButtonWithIcon>
              {I18N('create_new_article')}
            </ButtonWithIcon>
          </Link>

          {permissions.articleManager && (
            <Link to='/admin/articles/settings'>
              <div className='pointer ml-2'>
                <GearIcon fillColor='var(--link-color)' />
              </div>
            </Link>
          )}
        </div>
      </header>

      <main className='AdminContent'>
        <HistoryChangingPillTabSelector
          pillClasses='text-normal'
          className='mb-3'
          tabs={[
            {
              id: TABS.Articles,
              text: I18N('articles'),
            },
            {
              id: TABS.Templates,
              text: I18N('templates'),
            },
            {
              id: TABS.Analytics,
              text: I18N('analytics'),
            },
          ]}
        />

        {isArticlesTab && (
          <ArticlesTable />
        )}

        {isTemplatesTab && <TemplatesListTable />}

        {isAnalyticsTab && (
          <ArticleStatsTable />
        )}
      </main>
    </div>
  )
}

export default ArticleListPage
