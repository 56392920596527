import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

import groupSlice from 'redux/slices/groups'
import { i18nPath } from 'utils/i18nHelpers'
import FeedItem from 'components/home/feedItem'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import InfiniteScroller from 'components/common/infiniteScroller'
import ButtonWithIcon from 'components/common/buttons/buttonWithIcon'
import Card from 'components/common/card'
import Tag from 'components/common/tag'
import CdnSvg from 'components/common/cdnSvg'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'

const I18N = i18nPath('views.groups.group_page')
const womanTypingImagePath = '/images/illustrations/womanTyping.svg'

const CreatePostButton = ({ group, variant = 'primary' }) => {
  const currentCompany = useCurrentCompany()
  const history = useHistory()

  const [createDraftPost, { isLoading: isCreatingArticle }] = useApi(
    API.admin.articles.create, {
      onSuccess: (post) => {
        history.push(`/posts/${post.id}`)
      },
    }
  )

  const canCreatePost = group.permittedActions.editFields

  if (!canCreatePost) return null

  const onClickCreatePost = (e) => {
    if (!group.defaultArticleTemplateId) return

    e.preventDefault()

    createDraftPost({
      templateId: group.defaultArticleTemplateId,
      groupId: group.id,
    })
  }

  return (
    <a href={`/posts/new?groupId=${group.id}`} onClick={onClickCreatePost} className='flex-shrink-0'>
      <ButtonWithIcon
        className='Group-CreatePost'
        showLoadingSpinner={isCreatingArticle}
        variant={variant}
      >
        {I18N('plus_post', { groupDisplayName: currentCompany.groupDisplayName })}
      </ButtonWithIcon>
    </a>
  )
}

const EmptyFeedCard = ({ group }) => {
  const canCreatePost = group.permittedActions.editFields

  return (
    <Card>
      <div className='EmptyFeedCard d-flex justify-content-between align-items-center gap-3 mx-4 my-2 '>
        <CdnSvg src={womanTypingImagePath} />

        <div className={classNames('text-secondary', { 'text-center w-100': !canCreatePost })}>
          {canCreatePost ? I18N('posts_empty_state_cta') : I18N('posts_empty_state')}
        </div>

        <CreatePostButton group={group} variant='secondary' />
      </div>
    </Card>
  )
}

const Posts = ({ group }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const currentUser = useCurrentUser()
  const currentCompany = useCurrentCompany()

  const searchParams = new URLSearchParams(location.search)
  const tagId = searchParams.get('tagId')
  const tagName = searchParams.get('tagName')

  const [selectedTag, setSelectedTag] = useState(tagId ? { id: tagId, name: tagName } : null)

  const newsFeed = useSelector(groupSlice.selectors.getNewsFeed())
  const { isFetchingNewsFeed, newsFeedQueryParams } = useSelector(groupSlice.selectors.getMetaData())

  const handleTagChange = (tag) => {
    const tagId = tag ? tag.id : null

    // tag name must be encoded by encodeURIComponent so ampersand becomes %26
    // For example, a tag named 'Analytics & Reporting' if not encoded would be interpreted
    // as 2 different query parameters
    const queryParam = tagId ? `?tagId=${tagId}&tagName=${encodeURIComponent(tag.name)}` : ''
    history.push({ search: queryParam })

    dispatch(groupSlice.actions.resetNewsFeedIds([]))
    dispatch(groupSlice.asyncActions.fetchNewsFeed(group.id, tagId))
    setSelectedTag(tag)
  }

  const onFetch = () => {
    dispatch(groupSlice.asyncActions.fetchNewsFeed(group.id, tagId, { page: newsFeedQueryParams.page + 1 }))
  }

  const hasMoreContent = () => newsFeedQueryParams.page < newsFeedQueryParams.totalPages

  const isFeedEnabled = currentCompany.teamPostsEnabled
  const isFeedEmpty = !isFetchingNewsFeed && newsFeed.length === 0
  const showHeaderCreateButton = !isFeedEmpty

  useEffect(() => {
    if (isFeedEnabled) {
      dispatch(groupSlice.asyncActions.fetchNewsFeed(group.id, tagId, newsFeedQueryParams))
    }
  }, [isFeedEnabled])


  if (!isFeedEnabled) return null

  return (
    <section className='GroupPosts mb-4'>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <h5 className='text-secondary m-0'>{I18N('news')}</h5>
        {showHeaderCreateButton && (
          <CreatePostButton group={group} />
        )}
      </div>

      {selectedTag && (
        <div className='tag-filter mb-2'>
          <span className='label mr-2'>{I18N('filter_by')}</span>
          <Tag text={selectedTag.name} key={selectedTag.id} deleteIcon onClick={() => handleTagChange(null)} />
        </div>
      )}

      {isFeedEmpty && <EmptyFeedCard group={group} />}

      {!isFeedEmpty && (
        <div className='posts-list'>
          <InfiniteScroller isFetching={isFetchingNewsFeed} onFetch={onFetch} hasMoreContent={hasMoreContent}>
            {newsFeed.map(item => (
              <FeedItem
                key={`feed-item-${item.id}`}
                feedItem={item}
                currentUser={currentUser}
                companyName={currentCompany.name}
                selectedTag={selectedTag}
                onTagClick={handleTagChange}
              />
            ))}
          </InfiniteScroller>
        </div>
      )}
    </section>
  )
}

export default Posts
