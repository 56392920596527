

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import tagSlice from 'redux/slices/tags'
import ReactSelect, { Creatable } from 'components/common/react_select'
import classNames from 'classnames'

const I18N = i18nPath('views.admin.article_editor')

const TagsSelect = ({
  value, onChange, className = 'w-100', autoFocus = false, creatable = false, shouldFetchTags = false,
}, ref) => {
  const dispatch = useDispatch()
  const tags = useSelector(tagSlice.selectors.getTags())

  const SelectComponent = creatable ? Creatable : ReactSelect

  useEffect(() => {
    if (shouldFetchTags) dispatch(tagSlice.asyncActions.admin.fetchTags())
  }, [])

  return (
    <SelectComponent
      className={classNames('TagsSelect mr-5', className)}
      ref={ref}
      isMulti
      isClearable
      value={value}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      onChange={options => onChange(options)}
      options={tags}
      placeholder={I18N('select_tags')}
      menuPlacement='top'
      openMenuOnFocus
      autoFocus={autoFocus}
      getNewOptionData={(inputValue, optionLabel) => ({
        name: optionLabel,
        id: `new-${inputValue}`,
        __isNew__: true,
      })}
    />
  )
}

export default React.forwardRef(TagsSelect)
