import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import Sources from 'components/search/ai/sources'
import AiAnswerContent from 'components/search/ai/aiAnswerContent'
import EmployeeSidebarCard from 'components/analytics/ai_answers/employeeSidebarCard'

const I18N = i18nPath('views.platform_analytics.ai_answers')
const optionsI18N = i18nPath('views.additional_information_modal.ai_assistant_answers')

const AiAnswerSidebar = ({ answer }) => (
  <>
    <div className='d-flex align-items-center gap-2 mb-1'>
      <CdnSvg src='/images/illustrations/aiGradientIcon.svg' />
      <div className='font-weight-600 text-ai'>{I18N('sidebar.answer')}</div>
    </div>

    <AiAnswerContent content={answer?.answer} />

    <Sources sources={answer?.sources} />

    {answer?.user && (
      <div className='my-4'>
        <EmployeeSidebarCard user={answer.user} />
      </div>
    )}

    {answer?.answerFeedback && (
      <>
        <hr />
        <div className='text-primary text-large font-weight-600'>{I18N('sidebar.feedback')}</div>
        {answer.answerFeedback.options.map(option => (
          <li key={option}>{optionsI18N(option)}</li>
        ))}

        {answer.answerFeedback.comment && (
          <div className='mt-3 text-normal'>{answer.answerFeedback.comment}</div>
        )}
      </>
    )}
  </>
)

export default AiAnswerSidebar
