import CdnSvg from 'components/common/cdnSvg'
import useFetch from 'components/common/hooks/useFetch'
import React, { useState } from 'react'
import API from 'services/api'
import { i18nFormat, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.ai_assistant.scanning_channels_bottom_banner')

const ScanningChannelsBottomBanner = () => {
  const [hidden, setHidden] = useState(false)

  const { data, isLoading } = useFetch(
    () => API.ai.assistant.knowledgeIngestions.fetchAll({ onlyInProgress: true })
  )

  if (hidden || isLoading || data.length === 0) {
    return null
  }

  const channels = data[0].slackChannelNames.join(', ')

  return (
    <div
      className='ScanningChannelsBottomBanner d-flex border-radius-top card-shadow bg-white'
    >
      <div className='close-icon' onClick={() => setHidden(true)}>
        <CdnSvg src='/images/xIcon.svg' />
      </div>
      <div className='bg-tile p-3'>
        <img src='/images/illustrations/ai_platform_wizard_04.gif' />
      </div>
      <div className='content p-3'>
        <h3 className='text-normal bold m-0'>{I18N('title')}</h3>
        <p className='text-normal m-0'>{i18nFormat(I18N('description'), <span className='bold'>{channels}</span>)}</p>
      </div>
    </div>
  )
}

export default ScanningChannelsBottomBanner
