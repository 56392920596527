import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { TableLoadingContainer } from 'components/common/loadingContainer'
import SmartTable from 'components/common/tables/smartTable'
import ellipsis from 'utils/ellipsis'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import linkSlice from 'redux/slices/links'
import SuggestedIcon from 'components/common/suggestedIcon'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import AsyncSearchInput from 'components/common/asyncSearchInput'
import { Button } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import GoLinkDropdown from 'components/links/goLinkDropdown'
import useWindowResize from 'components/common/hooks/useWindowResize'
import classNames from 'classnames'

const I18N = i18nPath('views.golinks_list')
const searchIconPath = '/images/searchIcon.svg'

const LinksTabs = ({ filter }) => {
  const dispatch = useDispatch()
  const { isSmallScreen } = useWindowResize()

  const currentUser = useCurrentUser()

  const {
    isLoading,
    isNotFound,
    queryParams,
  } = useSelector(linkSlice.selectors.getMetaData())

  const {
    page, totalPages, total, perPage,
  } = queryParams
  const goLinks = useSelector(linkSlice.selectors.getGoLinks())
  const filterParams = filter === 'all' ? queryParams : { ...queryParams, userId: currentUser.id }

  useEffect(() => {
    dispatch(linkSlice.asyncActions.fetchGoLinks(filterParams))
  }, [filter])


  const rowClassName = (row) => {
    const hasAliases = row.aliases?.length > 0

    if (hasAliases) {
      return 'with-aliases'
    } else {
      return ''
    }
  }

  const columns = [
    {
      header: I18N('name'),
      col: 'col-name',
      id: 'name',
      accessor: golink => (
        <div className='d-flex flex-column GoLinksList_Name'>
          <div className='d-flex align-items-center'>
            <SuggestedIcon name={golink.displayName} url={golink.url} iconUrl={golink.iconUrl} height={24} width={24} />
            <Link to={`/golinks/${golink.name}`} className='text-truncate'>
              go/{golink.name}
            </Link>
          </div>
          {golink.aliases?.length > 0 && (
            <div className='mt-1 text-secondary text-small-important text-nowrap'>
              <span className='font-weight-bold mr-1'>{I18N('aliases')}</span>
              {golink.aliases.slice(0, 3).map((alias, index) => (
                <span key={index} className='mr-2'>
                  go/{alias.name}
                </span>
              ))}
              {golink.aliases.length > 3 && (
                <Link to={`/golinks/${golink.name}`} className='ml-1'>
                  {I18N('plus_more', { count: golink.aliases.length - 3 })}
                </Link>
              )}
            </div>
          )}
        </div>
      ),
    },
    {
      header: I18N('url'),
      col: 'col-url',
      accessor: golink => (
        <a href={golink.url}
          target='_blank'
          rel='noopener noreferrer'
          className='GoLinksList_Url'
          title={golink.url}
        >
          {ellipsis(golink.url, 25)}
        </a>
      ),
    },
    {
      header: I18N('description'),
      col: 'col-description',
      accessor: golink => <div className='GoLinksList_Description text-truncate'>{golink.description}</div>,
    },
    {
      header: I18N('num_visits'),
      col: 'col-num_visits',
      id: 'num_visits',
      accessor: golink => golink.numVisits,
    },
    {
      header: I18N('created_at'),
      col: 'col-created_at',
      id: 'created_at',
      accessor: golink => i18nMoment(golink.createdAt).format('ll'),
    },
    {
      header: '',
      accessor: d => <GoLinkDropdown goLink={d} />,
    },
  ]

  const handlePaginationClick = (params) => {
    const filter = {
      ...filterParams,
      ...params,
    }

    dispatch(linkSlice.asyncActions.fetchGoLinks(filter))
  }

  const handleSortChange = (sortParams) => {
    const filter = { ...filterParams, ...sortParams }

    dispatch(linkSlice.asyncActions.fetchGoLinks(filter))
  }

  const handleNameSearch = (value) => {
    // If the user is searching for a golink starting with go/ remove this
    // from the search value
    if (value.startsWith('go/')) {
      value = value.replace('go/', '')
    }

    const filter = _.isEmpty(value) ? { ...filterParams, page: 1 } : { ...filterParams, q: value, page: 1 }

    dispatch(linkSlice.asyncActions.fetchGoLinks(filter))
  }

  return (
    <div className='row mb-4 GolinksList_Grid'>
      <div className='col-12'>
        <div className='mb-3 d-md-flex justify-content-between'>
          <AsyncSearchInput
            className={classNames({ 'w-100': isSmallScreen })}
            placeholder={I18N('name_search_placeholder')}
            onKeyUp={handleNameSearch}
            icon={<CdnSvg src={searchIconPath} />}
          />
          <div className={classNames('AdminButtonContainer', { 'AdminButtonContainer-OnMobile': isSmallScreen })}>
            <Link to='/golinks/new'>
              <Button>
                {I18N('create_new_golink')}
              </Button>
            </Link>
          </div>
        </div>
        {(filter === 'all' || filter === 'mine') && (
          <TableLoadingContainer isLoading={isLoading} isNotFound={isNotFound} useCirclesLoadingIndicator>
            <SmartTable
              data={goLinks}
              sortedAsc={false}
              sortKey='num_visits'
              showPagination={page !== null && totalPages !== null}
              columns={columns}
              page={page}
              pages={totalPages}
              totalCount={total}
              perPage={perPage}
              onPaginationClick={handlePaginationClick}
              onSortChange={handleSortChange}
              className='white-bg-table'
              rowClassName={rowClassName}
            />
          </TableLoadingContainer>
        )}
      </div>
    </div>
  )
}

export default LinksTabs
