import useNewMessage from 'components/ai/chat/ask_ai_modal/hooks/useNewMessage'
import { NewConversationTrackingType } from 'components/ai/chat/ask_ai_modal/types'
import useQueryParams from 'components/common/hooks/useQueryParams'
import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

type Props = {
  onNewMessage: ReturnType<typeof useNewMessage>['onNewMessage']
  setShowAskAiModal: (show: boolean) => void
  newConversationTrackingRef: React.MutableRefObject<NewConversationTrackingType | undefined>
  isDisabled: boolean
}

const useCreateConversationFromQueryParam = ({
  onNewMessage,
  setShowAskAiModal,
  newConversationTrackingRef,
  isDisabled,
}: Props) => {
  const history = useHistory()
  const {
    newChatConversationMessage, origin, searchQuery,
  } = useQueryParams()

  // used to prevent creating a new conversation when a follow up message is created
  const isConversationAlreadyCreatedRef = useRef(false)

  useEffect(() => {
    if (newChatConversationMessage) {
      if (!isDisabled) {
        newConversationTrackingRef.current = origin ? {
          origin,
          searchQuery,
          prompt: newChatConversationMessage,
        } : undefined

        onNewMessage({
          content: newChatConversationMessage,
          forceNewConversation: !isConversationAlreadyCreatedRef.current,
        })

        setShowAskAiModal(true)

        isConversationAlreadyCreatedRef.current = true
      }

      const searchParams = new URLSearchParams(window.location.search)
      searchParams.delete('newChatConversationMessage')
      searchParams.delete('origin')
      searchParams.delete('searchQuery')
      history.replace({ search: searchParams.toString() })
    }
  }, [newChatConversationMessage, isDisabled])
}

export default useCreateConversationFromQueryParam
