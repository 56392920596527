import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import DangerousHTML from 'components/common/dangerousHTML'
import useFetch from 'components/common/hooks/useFetch'
import React from 'react'
import API from 'services/api'
import { TicketType } from 'types/ticketing/ticket'
import { TicketActivityType } from 'types/ticketing/ticketActivity'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'

type Props = {
  ticket: TicketType
  isAdmin?: boolean
}

const I18N = i18nPath('views.ticketing.view_ticket_sidebar')

const TicketActivity = ({ ticket, isAdmin = false }: Props) => {
  const ticketingApi = isAdmin ? API.admin.ticketing : API.ticketing
  const { data: ticketActivities, isLoaded } = useFetch<TicketActivityType[]>(
    () => ticketingApi.ticketActivities.fetchAll(ticket.id),
    [ticket.id, ticket.updatedAt]
  )

  return (
    <div className='TicketActivity p-3 border-bottom overflow-hidden d-flex flex-column'>
      <div className='text-small font-weight-600 mb-3'>{I18N('ticket_activity')}</div>
      <div className='d-flex flex-column gap-3 overflow-y-auto'>
        {!isLoaded ? (
          <CirclesLoadingIndicator />
        ) : (
          ticketActivities?.map(activity => (
            <TicketActivityItem activity={activity} key={activity.id} />
          ))
        )}
      </div>
    </div>
  )
}

const TicketActivityItem = ({ activity }: { activity: TicketActivityType }) => (
  <div className='TicketActivityItem d-flex gap-1'>
    <CdnSvg src={activity.iconPath} className={classNames('TicketActivityItemIcon', activity.color)} />
    <DangerousHTML className='text-smallest'>{activity.label}</DangerousHTML>
    <div className='text-smallest text-secondary'>{i18nMoment(activity.createdAt).fromNow()}</div>
  </div>
)

export default TicketActivity
