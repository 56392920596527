import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { i18nPath } from 'utils/i18nHelpers'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import GlobeIcon from 'components/icons/globeIcon'
import ToggleGroup from 'components/common/toggleGroup'
import journeySlice from 'redux/slices/journeys'
import JourneysEstimatedUsers from 'components/admin/journeys/audience/journeysEstimatedUsers'
import CsvAudienceUploader from 'components/common/csv/csvAudienceUploader'
import AudienceRulesSelector from 'components/common/audience/audienceRulesSelector'
import normalizeTargetingRules from 'utils/normalizeTargetingRules'
import journeyBlueprintSlice from 'redux/slices/journeyBlueprints'
import { present } from 'components/common/utils'
import CdnSvg from 'components/common/cdnSvg'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'

const I18N = i18nPath('views.admin.journeys.journey_blueprints.add_new_hires_manually_modal')

const SPECIFIC_PEOPLE = 'specific_people'
const ENTIRE_COMPANY = 'entire_company'
const SPECIFIC_GROUPS = 'specific_groups'
const UPLOAD_CSV = 'upload_csv'

export const useManuallyAddEmployees = (journeyBlueprintId) => {
  const dispatch = useDispatch()
  const [selectedToggle, setSelectedToggle] = useState(SPECIFIC_PEOPLE)
  const [selectedEmployees, setSelectedEmployees] = useState<any[]>([])
  const [targetingRules, setTargetingRules] = useState({
    targetEntireCompany: false,
    rules: [],
    users: [],
    userIds: [],
  })

  const onAddEmployees = ({ onSuccess = (usersWithErrors) => {} } = {}) => {
    const params: Record<string, any> = { journeyBlueprintId }

    if (selectedToggle === ENTIRE_COMPANY) {
      params.entireCompany = true
    } else if ([SPECIFIC_PEOPLE, UPLOAD_CSV].includes(selectedToggle)) {
      params.userIds = selectedEmployees.map(user => user.id)
    } else if (selectedToggle === SPECIFIC_GROUPS) {
      params.targetingRules = normalizeTargetingRules(targetingRules)
    }

    dispatch(journeySlice.asyncActions.admin.batchCreate(params, onSuccess))
  }

  return {
    selectedEmployees,
    setSelectedEmployees,
    selectedToggle,
    setSelectedToggle,
    targetingRules,
    setTargetingRules,
    onAddEmployees,
  }
}

interface ManuallyAddedEmployeesSelectorProps {
  selectedEmployees?: any[]
  setSelectedEmployees: (selectedEmployees: any) => void
  selectedToggle?: any
  setSelectedToggle: (selectedToggle: any) => void
  targetingRules?: any
  setTargetingRules: (targetingRules: any) => void
  journeyBlueprint?: any
  subtitleText?: string
  className?: string
}

const ManuallyAddedEmployeesSelector = ({
  selectedEmployees,
  setSelectedEmployees,
  selectedToggle,
  setSelectedToggle,
  targetingRules,
  setTargetingRules,
  journeyBlueprint,
  subtitleText,
  className,
}: ManuallyAddedEmployeesSelectorProps) => {
  const journeyBlueprintType = journeyBlueprint.type
  const isOnboardingJourney = journeyBlueprintType === 'onboarding'

  const subtitle = subtitleText || I18N(`${journeyBlueprintType}.select_employees`)
  const targetingOptions = useTargetingOptions()

  const simpleJourneys = useSelector(journeyBlueprintSlice.selectors.getSimpleJourneys(journeyBlueprint?.id))
  const currentlyAssignedUsersIds = simpleJourneys.map(item => item.userId)

  const changeToggle = (value) => {
    setSelectedEmployees([])
    setSelectedToggle(value)
  }

  const toggles = [
    {
      value: SPECIFIC_PEOPLE,
      icon: <CdnSvg src='/images/personIcon.svg' />,
      title: I18N(`toggles.${SPECIFIC_PEOPLE}`),
    },
    journeyBlueprintType === 'custom' && {
      value: ENTIRE_COMPANY,
      icon: <GlobeIcon />,
      title: I18N(`toggles.${ENTIRE_COMPANY}`),
    },
    journeyBlueprintType === 'custom' && {
      value: SPECIFIC_GROUPS,
      icon: <CdnSvg src='/images/personsIcon.svg' />,
      title: I18N(`toggles.${SPECIFIC_GROUPS}`),
    },
    {
      value: UPLOAD_CSV,
      icon: <CdnSvg src='/images/uploadIcon.svg' />,
      title: I18N(`toggles.${UPLOAD_CSV}`),
    },
  ].filter(present)

  return (
    <div className={classNames('ManuallyAddedEmployeesSelector', className)}>
      <h5 className='subtitle'>{subtitle}</h5>
      <ToggleGroup
        onChange={changeToggle}
        selectedValue={selectedToggle}
        className={classNames('mb-3', `toggles-${toggles.length}`)}
        toggles={toggles}
      />
      {selectedToggle === SPECIFIC_PEOPLE && (
        <EmployeeSearch
          className='mb-1'
          selectedEmployees={selectedEmployees}
          onChange={setSelectedEmployees}
          isMulti
          includeHiddenUsers
          excludedUserIds={currentlyAssignedUsersIds}
        />
      )}
      {selectedToggle === SPECIFIC_GROUPS && (
        <AudienceRulesSelector
          targetingRules={targetingRules}
          onChange={setTargetingRules}
          targetingOptions={targetingOptions}
          canTargetSpecificUsers={false}
        />
      )}
      {selectedToggle === UPLOAD_CSV && (
        <CsvAudienceUploader
          users={selectedEmployees}
          setUsers={setSelectedEmployees}
        />
      )}
      {selectedToggle === SPECIFIC_GROUPS && (
        <JourneysEstimatedUsers
          journeyBlueprintId={journeyBlueprint.id}
          journeyBlueprintType={journeyBlueprintType}
          targetingRules={selectedToggle === SPECIFIC_GROUPS ? targetingRules : {}}
          automaticallyAddUsersDaysAfterMilestone={journeyBlueprint.automaticallyAddUsersDaysAfterMilestone}
          onlyUsersWithoutJourney
        />
      )}
      {isOnboardingJourney && (
        <span className='text-secondary select-disclaimer'>{I18N('onboarding.cant_find_who_you_are_looking_for')}
          <Link className='ml-1' to='/admin/users/new'>
            {I18N('onboarding.click_here_to_add_new_hires_to_cleary')}
          </Link>
        </span>
      )}
    </div>
  )
}

export default ManuallyAddedEmployeesSelector
