import { MessageType, NewConversationTrackingType } from 'components/ai/chat/ask_ai_modal/types'
import useApi from 'components/common/hooks/useApi'
import React, { useRef } from 'react'
import API from 'services/api'

interface UseNewMessageProps {
  currentConversationId?: string
  setCurrentConversationId: (conversationId: string) => void
  updateLastMessageSentAt: () => void
  setMessages: React.Dispatch<React.SetStateAction<MessageType[]>>
  setIsResponding: React.Dispatch<React.SetStateAction<boolean>>
  newConversationTrackingRef: React.MutableRefObject<NewConversationTrackingType | undefined>
}

export const useNewMessage = ({
  currentConversationId,
  setCurrentConversationId,
  updateLastMessageSentAt,
  setMessages,
  setIsResponding,
  newConversationTrackingRef,
}: UseNewMessageProps) => {
  const messageToCreateRef = useRef<string>('')

  const [createConversation, { isLoading: isCreatingConversation }] = useApi(
    API.ai.chats.conversations.create, {
      onSuccess: (data) => {
        setCurrentConversationId(data.id)
        if (messageToCreateRef.current) {
          const content = messageToCreateRef.current
          messageToCreateRef.current = ''
          createMessage(data.id, { content })
        }
      },
    }
  )

  const [createMessage, { isLoading: isCreatingMessage }] = useApi(
    API.ai.chats.messages.create,
    {
      onSuccess: (data) => {
        updateLastMessageSentAt()
        setMessages(messages => [...messages, data])
        setIsResponding(true)
      },
    }
  )

  type NewMessageParams = {
    content: string
    forceNewConversation?: boolean
  }

  const onNewMessage = ({ content, forceNewConversation = false }: NewMessageParams) => {
    if (!currentConversationId || forceNewConversation) {
      messageToCreateRef.current = content
      createConversation({ title: content, tracking: newConversationTrackingRef.current })
    } else {
      createMessage(currentConversationId, { content })
    }
  }

  return {
    onNewMessage,
    isCreatingConversation,
    isCreatingMessage,
  }
}

export default useNewMessage
