import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import API from 'services/api'
import { WorkflowRun } from 'services/api/admin/workflows/runs'
import useFetch from 'components/common/hooks/useFetch'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { Workflow } from 'services/api/admin/workflows'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import DataList from 'components/common/dataList'
import TriggerableLabel from 'components/workflows/triggerableLabel'
import StatusLabel from 'components/workflows/statusLabel'
import EmployeeSidebarCard from 'components/analytics/ai_answers/employeeSidebarCard'
import CdnSvg from 'components/common/cdnSvg'
import { Accordion, AccordionContext, useAccordionToggle } from 'react-bootstrap'

const I18N = i18nPath('views.admin.workflows.run_page')

const ACTION_ICONS = {
  suggest_ticket_message: '/images/tiptap/pencil.svg',
  generate_pdf: '/images/illustrations/pdfIcon.svg',
  hris_salary_information: '/images/integrations/workdayLogo.svg',
}

const ActionDetailsHeader = ({ actionRun }) => {
  const toggle = useAccordionToggle(actionRun.id)
  const currentEventKey = useContext(AccordionContext)

  return (
    <div className='d-flex flex-column pointer' key={actionRun.id} onClick={toggle}>
      <div className='w-100 d-flex justify-content-between'>
        <div className='d-flex align-items-center gap-4'>
          <div className='d-flex align-items-center'>
            <CdnSvg src={ACTION_ICONS[actionRun.action.name]} />
            <h6 className='mb-0 ml-2'>
              {i18nPath('views.admin.workflows.actions')(actionRun.action.name)}
            </h6>
          </div>
          <div>
            <p className='mb-0 text-small'>
              <span className='bold'>{I18N('data_list.started')}:</span>
              {i18nMoment(actionRun.createdAt).format('lll')}
            </p>
          </div>
        </div>
        <div>
          <StatusLabel status={actionRun.status} />
          <CdnSvg
            src={
              currentEventKey === actionRun.id
                ? '/images/chevronDownOutline.svg'
                : '/images/chevronRightOutline.svg'
            }
            className='ml-4 arrow-icon'
          />
        </div>
      </div>
    </div>
  )
}

const WorkflowRunPage = () => {
  const { workflowId, runId } = useParams()

  const { data: workflow, isLoading: isLoadingWorkflow } = useFetch<Workflow>(
    API.admin.workflows.fetch,
    [workflowId]
  )

  const { data: run, isLoading } = useFetch<WorkflowRun>(
    API.admin.workflows.runs.fetch,
    [workflowId, runId]
  )

  if (isLoadingWorkflow || !workflow || isLoading || !run) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div className='WorkflowRunPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <div>
          <h4 className='mb-0'>{I18N('title')}</h4>
        </div>
      </header>

      <main className='AdminContent'>
        <DataList
          data={[
            { dataTitle: I18N('data_list.workflow'), dataValue: workflow.name },
            { dataTitle: I18N('data_list.trigger'), dataValue: <TriggerableLabel triggerableType={run.triggerableType} /> },
            { dataTitle: I18N('data_list.started'), dataValue: i18nMoment(run.createdAt).format('lll') },
            { dataTitle: I18N('data_list.status'), dataValue: <StatusLabel status={run.status} /> },
          ]}
        />
        <DataList
          data={[
            { dataTitle: I18N('data_list.requested_by'), dataValue: <EmployeeSidebarCard user={run.triggerable.user} /> },
          ]}
        />

        <h5 className='text-small text-secondary mb-2 bold'>{I18N('data_list.actions_overview')}</h5>

        <Accordion className='d-flex flex-column gap-4'>
          {run.actionRuns.map(actionRun => (
            <div className='bordered gap-4 py-3 px-3' key={actionRun.id}>
              <ActionDetailsHeader actionRun={actionRun} />
              <Accordion.Collapse eventKey={actionRun.id} className='mt-4'>
                <div className='d-flex gap-4'>
                  This is the action details
                </div>
              </Accordion.Collapse>
            </div>
          ))}
        </Accordion>
      </main>
    </div>
  )
}

export default WorkflowRunPage
