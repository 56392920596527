import React from 'react'
import classNames from 'classnames'
import { Button as BootstrapButton } from 'react-bootstrap'

import LoadingSpinnerText from 'components/common/loadingSpinnerText'
import CdnSvg from 'components/common/cdnSvg'
import { ButtonProps, extractConfirmationProps, useConfirmationModal } from 'components/common/buttons/button'
import ConfirmationModal from 'components/common/modals/confirmationModal'

interface ButtonWithIconProps extends ButtonProps {
  iconSrc?: string
  iconClassName?: string
}

const ButtonWithIcon = React.forwardRef<any, ButtonWithIconProps>(({
  className,
  id,
  style,
  disabled,
  type,
  onClick,
  onMouseEnter,
  onMouseLeave,
  dataTestId,
  children,
  variant = 'primary',
  showLoadingSpinner = false,
  confirm = false,
  iconSrc = '/images/plusIcon.svg',
  iconClassName,
}, ref) => {
  const {
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
    handleClick,
  } = useConfirmationModal(confirm, onClick)

  return (
    <>
      <BootstrapButton
        data-testid={dataTestId}
        className={classNames('btn-with-icon', className)}
        variant={variant}
        id={id}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{ ...style, fontSize: '1rem' }}
        onClick={handleClick}
        disabled={disabled}
        type={type}
        ref={ref}
      >
        <LoadingSpinnerText showLoadingSpinner={showLoadingSpinner}>
          <>
            <CdnSvg src={iconSrc} className={iconClassName} />
            {children}
          </>
        </LoadingSpinnerText>
      </BootstrapButton>

      {isConfirmationModalOpen && (
        <ConfirmationModal
          visible
          toggle={() => setIsConfirmationModalOpen(!isConfirmationModalOpen)}
          onConfirmClick={(e) => {
            onClick?.(e)
            setIsConfirmationModalOpen(false)
          }}
          {...extractConfirmationProps(confirm)}
        />
      )}
    </>
  )
})


export default ButtonWithIcon
