import React from 'react'

import { AsyncSelect } from 'components/common/react_select'
import API from 'services/api'
import { I18NCommon } from 'utils/i18nHelpers'
import { CompanyType } from 'types/company'


interface Props {
  id?: string
  value?: CompanyType | null
  onChange: (value: CompanyType | null) => void
  [key: string]: any
}

function CompaniesAsyncSelect({
  value, onChange, id = 'cy_companies_select', ...props
}: Props) {
  const isMulti = Array.isArray(value)

  const buildCompany = ({ attributes }) => attributes

  let loadCompanies = (query, callback) => {
    API.admin.company.fetchAll({ q: query })
      .then(response => callback(response.data.data.map(buildCompany)))
  }

  loadCompanies = _.debounce(loadCompanies, 300)

  return (
    <>
      <AsyncSelect
        id={id}
        aria-label='Type to search for companies'
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        value={value}
        onChange={company => onChange(company)}
        loadOptions={loadCompanies}
        backspaceRemovesValue
        isClearable
        isMulti={isMulti}
        defaultOptions
        placeholder={I18NCommon('select_or_type_to_search_company')}
        {...props}
      />
    </>
  )
}

export default CompaniesAsyncSelect
