import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { PaginationData } from 'components/common/hooks/useApi'
import StatusLabel from 'components/common/status_label'
import DateCell from 'components/common/tables/dateCell'
import SmartTable from 'components/common/tables/smartTable'
import UserLink from 'components/common/userLink'
import { present } from 'components/common/utils'
import AssignTicketPopover from 'components/ticketing/assignTicketPopover'
import TicketStatusLabel from 'components/ticketing/ticketStatusLabel'
import UserOrUnassigned from 'components/ticketing/userOrUnassigned'
import TicketDropdown from 'components/ticketing/view_ticket_sidebar/ticketDropdown'
import React from 'react'
import { TicketType } from 'types/ticketing/ticket'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.ticketing')

const columns = (isAdminTable: boolean, setSelectedTicketNumber: (ticketNumber: string) => void) => ([
  {
    header: I18N('table.headers.id'),
    style: { width: '70px' },
    col: 'col-number',
    accessor: (ticket: TicketType) => (
      <div>
        #{ticket.number}
      </div>
    ),
  },
  {
    header: I18N('table.headers.status'),
    style: { width: '160px' },
    col: 'col-status',
    accessor: (ticket: TicketType) => <TicketStatusLabel status={ticket.status} />,
  },
  {
    header: I18N('table.headers.subject'),
    col: 'col-title',
    accessor: (ticket: TicketType) => (
      <div>
        {isAdminTable ? (
          <>
            <span>{ticket.title}</span>
            {ticket.overdue && (
              <StatusLabel color='red-secondary' className='text-smallest py-1 line-height-1 ml-2'>{I18N('status.overdue')}</StatusLabel>
            )}
          </>
        ) : (
          ticket.title
        )}
      </div>
    ),
  },
  isAdminTable && {
    header: I18N('table.headers.requester'),
    col: 'col-requester',
    style: { width: '200px' },
    accessor: (ticket: TicketType) => <UserLink user={ticket.user} customClassName='text-small text-primary font-weight-400' />,
  },
  {
    header: I18N('table.headers.requested_at'),
    col: 'col-requested-at',
    style: { width: '200px' },
    accessor: (ticket: TicketType) => <DateCell date={ticket.createdAt} />,
  },
  {
    header: I18N('table.headers.assigned_to'),
    col: 'col-assigned-to',
    style: { width: '200px' },
    accessor: (ticket: TicketType) => (
      isAdminTable ? <AssignTicketPopover ticket={ticket} /> : <UserOrUnassigned user={ticket.assignee} />
    ),
  },
  {
    style: { width: '30px' },
    accessor: (ticket: TicketType) => (
      <TicketDropdown ticket={ticket} isAdmin={isAdminTable} />
    ),
  },
].filter(present))


interface Props {
  isLoading: boolean
  tickets: TicketType[]
  paginationData: PaginationData
  onPaginationClick: (data: any) => void
  isAdminTable?: boolean
  setSelectedTicketNumber: (ticketNumber: string) => void
}

const TicketTable = ({
  isLoading,
  tickets,
  paginationData,
  onPaginationClick,
  isAdminTable = true,
  setSelectedTicketNumber,
}: Props) => (
  <SmartTable
    isLoading={isLoading}
    LoadingComponent={<CirclesLoadingIndicator className='TableLoadingIndicator' />}
    columns={columns(isAdminTable, setSelectedTicketNumber)}
    data={tickets}
    className='white-bg-table table-with-row-hover'
    showPagination
    page={paginationData.page}
    pages={paginationData.totalPages}
    onPaginationClick={onPaginationClick}
    onSortChange={() => {}}
    sortKey='created_at'
    sortedAsc={false}
    onRowClick={row => setSelectedTicketNumber(row.number)}
  />
)

export default TicketTable
