import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import CommonSidebar from 'components/common/sidebar'

const I18N = i18nPath('views.admin.workflows.edit_page.sidebar')

const Sidebar = ({ workingCopy, setWorkingCopy }) => {
  const connect = field => ({
    onChange: e => setWorkingCopy(prev => ({ ...prev, [field]: e.target.value })),
    value: workingCopy[field],
  })

  return (
    <CommonSidebar
      title={I18N('title')}
      visible
      className='WorkflowEditPage__sidebar'
    >
      <div className='d-flex flex-column gap-4'>
        <div>
          <label className='text-secondary text-small'>{I18N('form.name_input.label')}</label>
          <input
            {...connect('name')}
            placeholder={I18N('form.name_input.placeholder')}
            className='w-100'
          />
        </div>

        <div>
          <label className='text-secondary text-small'>{I18N('form.description_input.label')}</label>
          <textarea
            {...connect('description')}
            placeholder={I18N('form.description_input.placeholder')}
            className='w-100'
          />
        </div>
      </div>
    </CommonSidebar>
  )
}

export default Sidebar
