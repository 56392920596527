import useConversations from 'components/ai/chat/ask_ai_modal/hooks/useConversations'
import useCreateConversationFromQueryParam from 'components/ai/chat/ask_ai_modal/hooks/useCreateConversationFromQueryParam'
import useCurrentConversation from 'components/ai/chat/ask_ai_modal/hooks/useCurrentConversation'
import useGenerateBotResponse from 'components/ai/chat/ask_ai_modal/hooks/useGenerateBotResponse'
import useMyPrompts from 'components/ai/chat/ask_ai_modal/hooks/useMyPrompts'
import useNewMessage from 'components/ai/chat/ask_ai_modal/hooks/useNewMessage'
import {
  AskAiTab, ConversationType, MessageType, NewConversationOrigin, NewConversationTrackingType
} from 'components/ai/chat/ask_ai_modal/types'
import useQueryParams from 'components/common/hooks/useQueryParams'
import React, {
  createContext, useContext, useRef, useState
} from 'react'

export interface AskAiContextProps {
  conversations: ConversationType[]
  currentConversationId?: string
  hasMoreConversations: boolean
  hasMoreMessages: boolean
  isCreatingConversation: boolean
  isCreatingMessage: boolean
  isIgnoringOutsideClick: boolean
  isLoadingConversations: boolean
  isLoadingMessages: boolean
  isLoadingMyPrompts: boolean
  isResponding: boolean
  messages: MessageType[]
  myPrompts?: string[]
  onLoadMoreConversations: () => void
  onLoadMoreMessages: () => void
  onNewConversation: () => void
  onNewMessage: ReturnType<typeof useNewMessage>['onNewMessage']
  setCurrentConversationId: (id: string) => void
  setIsIgnoringOutsideClick: (ignore: boolean) => void
  setShowAskAiModal: (show: boolean) => void
  setTab: (tab: AskAiTab) => void
  showAskAiModal: boolean
  tab: AskAiTab
  newConversationTrackingRef: React.MutableRefObject<NewConversationTrackingType | undefined>
  inChat: boolean
}

const AskAiContext = createContext<AskAiContextProps>({
  conversations: [],
  currentConversationId: undefined,
  hasMoreConversations: false,
  hasMoreMessages: false,
  isCreatingConversation: false,
  isCreatingMessage: false,
  isIgnoringOutsideClick: false,
  isLoadingConversations: false,
  isLoadingMessages: false,
  isLoadingMyPrompts: false,
  isResponding: false,
  messages: [],
  myPrompts: [],
  onLoadMoreConversations: () => {},
  onLoadMoreMessages: () => {},
  onNewConversation: () => {},
  onNewMessage: () => {},
  setCurrentConversationId: () => {},
  setIsIgnoringOutsideClick: () => {},
  setShowAskAiModal: () => {},
  setTab: () => {},
  showAskAiModal: false,
  tab: AskAiTab.CHAT,
  newConversationTrackingRef: { current: undefined },
  inChat: false,
})

const useAskAiContext = () => useContext(AskAiContext)

interface AskAiProviderProps {
  children: React.ReactNode
}

const AskAiProvider = ({ children }: AskAiProviderProps) => {
  const {
    showAskAiModal: showAskAiModalQueryParam,
    tab: tabQueryParam,
  } = useQueryParams()
  const [showAskAiModal, setShowAskAiModal] = useState(showAskAiModalQueryParam)
  const [tab, setTab] = useState(tabQueryParam || AskAiTab.CHAT)
  const [isResponding, setIsResponding] = useState(false)
  const [isIgnoringOutsideClick, setIsIgnoringOutsideClick] = useState(false)
  const newConversationTrackingRef = useRef<NewConversationTrackingType | undefined>()

  const {
    currentConversationId,
    setCurrentConversationId,
    updateLastMessageSentAt,
    messages,
    setMessages,
    hasMoreMessages,
    onLoadMoreMessages,
    isLoadingMessages,
    onNewConversation,
  } = useCurrentConversation()

  const {
    onNewMessage,
    isCreatingConversation,
    isCreatingMessage,
  } = useNewMessage({
    currentConversationId,
    setCurrentConversationId,
    updateLastMessageSentAt,
    setMessages,
    setIsResponding,
    newConversationTrackingRef,
  })

  useGenerateBotResponse({
    isResponding,
    setIsResponding,
    setMessages,
    currentConversationId,
  })

  const {
    data: conversations,
    isLoading: isLoadingConversations,
    hasMore: hasMoreConversations,
    onLoadMore: onLoadMoreConversations,
  } = useConversations({
    fetchConversations: tab === AskAiTab.HISTORY,
  })

  const {
    data: myPrompts,
    isLoading: isLoadingMyPrompts,
  } = useMyPrompts({
    showAskAiModal,
  })

  useCreateConversationFromQueryParam({
    onNewMessage,
    setShowAskAiModal,
    newConversationTrackingRef,
    isDisabled: isResponding || isCreatingConversation || isCreatingMessage,
  })

  return (
    <AskAiContext.Provider
      value={{
        conversations,
        currentConversationId,
        hasMoreConversations,
        hasMoreMessages,
        isCreatingConversation,
        isCreatingMessage,
        isIgnoringOutsideClick,
        isLoadingConversations,
        isLoadingMessages,
        isLoadingMyPrompts,
        isResponding,
        messages,
        myPrompts,
        newConversationTrackingRef,
        onLoadMoreConversations,
        onLoadMoreMessages,
        onNewConversation,
        onNewMessage,
        setCurrentConversationId,
        setIsIgnoringOutsideClick,
        setShowAskAiModal,
        setTab,
        showAskAiModal,
        tab,
        inChat: true,
      }}
    >
      {children}
    </AskAiContext.Provider>
  )
}

export { AskAiContext, useAskAiContext, AskAiProvider }
