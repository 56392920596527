import React, { ReactElement } from 'react'
import { useCustomStyles, useVariables } from 'pages/admin/custom_styles/context'
import { PropertyListValue, getPropertyListWithValues } from 'components/admin/customStyles/utils'
import BoxShadowToggle from './boxShadowToggle'

interface Props {
  type: string
}

const BoxShadowSection = ({ type }: Props): ReactElement => {
  const { handlePropertyChanged } = useCustomStyles()
  const boxShadow = useVariables('boxShadow')
  const [variable, value] = getPropertyListWithValues({ variables: boxShadow, type: 'boxShadow' }).find(
    ([variable, _]) => variable === type
  ) as PropertyListValue

  const defaultValues = {
    navbarBoxShadow: '0 2px 4px 1px #d9dbdc',
    cardBoxShadow: '0 0 0 1px rgba(0, 0, 0, 0.08)',
  }

  return (
    <div className='row'>
      <div className='col-12'>
        <div>
          <BoxShadowToggle
            key={variable}
            variable={variable}
            value={value}
            onChange={handlePropertyChanged}
            defaultValues={defaultValues}
          />
        </div>
      </div>
    </div>
  )
}

export default BoxShadowSection
