import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import AsyncSearchInput from 'components/common/asyncSearchInput'
import useQueryParams from 'components/common/hooks/useQueryParams'
import snakeCaseKeys from 'utils/snakeCaseKeys'
import classNames from 'classnames'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import useIsAiAssistantAnswersEnabled from 'hooks/ai/useIsAssistantAnswersEnabled'

const searchIconPath = '/images/searchIcon.svg'

const I18N = i18nPath('views.search.global_search')

const SearchBar = () => {
  const history = useHistory()
  const queryParams = useQueryParams()
  const { type } = queryParams
  const { pathname } = useLocation()
  const isGolinkSearch = type === 'go_link'
  const goPrefix = isGolinkSearch ? <span>go/</span> : null

  const onQueryChange = (value) => {
    delete queryParams.golinkNotFound

    history.push({
      pathname,
      search: '?' + new URLSearchParams(snakeCaseKeys({ ...queryParams, query: value })),
    })
  }

  const isAiAssistantAnswersEnabled = useIsAiAssistantAnswersEnabled()

  return (
    <AsyncSearchInput
      key={queryParams.query} // This is to force the component to re-render when the query changes
      inputGroupClassName={classNames('flex-grow-1', { AsyncSearchInputGolink: isGolinkSearch })}
      className='SearchBarInput'
      defaultValue={queryParams.query}
      onKeyUp={onQueryChange}
      placeholder={I18N('search_placeholder')}
      icon={(
        <>
          <CdnSvg src={searchIconPath} /> {goPrefix}
        </>
      )}
      debounceWait={isAiAssistantAnswersEnabled ? 500 : 200}
    />
  )
}

export default SearchBar
