import React, { useState } from 'react'
import classNames from 'classnames'

import ToggleLeftNavButton from 'components/common/toggleLeftNavButton'
import useLeftNavItems from 'components/navbar/hooks/useLeftNavItems'
import LeftNavItem from 'components/navbar/leftNavItem'
import useWindowResize from 'components/common/hooks/useWindowResize'

const LeftNavbar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const navItems = useLeftNavItems()
  const { isXXLScreen } = useWindowResize()

  return (
    <>
      {isXXLScreen && (
        <ToggleLeftNavButton
          isOpen={isOpen}
          onClick={() => setIsOpen(!isOpen)}
          useNewUi
        />
      )}

      <div className={classNames('LeftNavbar', { isOpen })}>
        {navItems.map(item => (
          <LeftNavItem key={item.label} {...item} isLeftNavOpen={isOpen} />
        ))}
      </div>
    </>
  )
}

export default LeftNavbar
