import React, { useContext, useState } from 'react'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import CdnSvg from 'components/common/cdnSvg'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import DeleteModal from 'components/common/modals/deleteModal'
import { TicketType } from 'types/ticketing/ticket'
import TicketingContext from 'components/ticketing/ticketingContext'

const I18N = i18nPath('views.ticketing.ticket_dropdown')

type Props = {
  ticket: TicketType
  isAdmin?: boolean
  className?: string
}

const TicketDropdown = ({
  ticket,
  isAdmin = false,
  className = '',
}: Props) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const { removeTicket } = useContext(TicketingContext)

  const api = isAdmin ? API.admin.ticketing.tickets : API.ticketing.tickets
  const [deleteTicket, { isLoading: isDeleting }] = useApi(api.destroy, {
    onSuccess: () => {
      setIsDeleteModalOpen(false)
      removeTicket(ticket)
    },
    toastSuccessMessage: I18N('ticket_deleted'),
    toastErrorMessage: I18NCommon('generic_error'),
  })

  return (
    <div
      className={className}
      onClick={e => e.stopPropagation()}
    >
      <DropdownMenuContainer className='default-dropdown' menuType='kebab' closeMenuOnItemClick>
        <CardDropdownMenuItem
          className='Card-dropdownMenuItem--danger'
          onClick={() => setIsDeleteModalOpen(true)}
          primaryText={I18NCommon('delete')}
          icon={<CdnSvg src='/images/trashIcon.svg' className='mr-2' />}
        />
      </DropdownMenuContainer>
      {isDeleteModalOpen && (
        <DeleteModal
          deleteText={I18N('delete_ticket')}
          deleteSecondaryText={I18N('delete_ticket_warning')}
          deleteConfirm={() => deleteTicket({ id: ticket.id })}
          showDeleteModal={isDeleteModalOpen}
          closeDeleteModal={() => setIsDeleteModalOpen(false)}
          isDeleting={isDeleting}
        />
      )}
    </div>
  )
}

export default TicketDropdown
