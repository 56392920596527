import React from 'react'
import classNames from 'classnames'

import CdnSvg from 'components/common/cdnSvg'

const ToggleLeftNavButton = ({
  onClick,
  className = '',
  isOpen = true,
  useNewUi = false,
}) => {
  const iconSrc = useNewUi ? '/images/navbar/toggleIcon.svg' : '/images/doubleArrowheadLeftIcon.svg'

  return (
    <button
      className={classNames('ToggleLeftNavButton', { isOpen, useNewUi }, className)}
      onClick={onClick}
    >
      <CdnSvg src={iconSrc} />
    </button>
  )
}

export default ToggleLeftNavButton
