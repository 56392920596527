import CelebrationListPageWidget from 'pages/people/celebrations_list_page/celebrationListPageWidget'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import { ButtonLink } from 'components/common/buttons'
import ButtonWithIcon from 'components/common/buttons/buttonWithIcon'
import CelebrationsList from 'pages/people/celebrations_list_page/celebrationsList'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { trackAmplitudeEvent } from 'services/tracker'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const CARDS_TO_SIGN = 'cards_to_sign'
const SIGNED_CARDS = 'signed_cards'
const CREATED_CARDS = 'created_cards'

const I18N = i18nPath('views.celebrations.list_page')


const CelebrationsListPage = () => {
  const { selectedTab } = useQueryParams()
  const { settings } = useCurrentCompany()

  const onCreateButtonClicked = () => {
    trackAmplitudeEvent('create_new_celebration_card_clicked')
  }

  return (
    <div className='CelebrationsListPage container'>
      <header className='d-flex justify-content-between align-items-center my-4'>
        <div>
          <h1>{I18N('title')}</h1>
        </div>

        {settings.celebrations.manualCelebrations.enabled && (
          <ButtonLink
            to='/people/celebrations/new'
            Component={ButtonWithIcon}
            onClick={onCreateButtonClicked}
          >
            {I18N('create_celebration_button')}
          </ButtonLink>
        )}
      </header>

      {settings.celebrations.manualCelebrations.enabled && (
        <div className='widgets d-flex flex-md-nowrap flex-wrap gap-4 mb-4'>
          <CelebrationListPageWidget type='farewell' />
          <CelebrationListPageWidget type='achievement' />
          <CelebrationListPageWidget type='new_parent' />
        </div>
      )}

      <HistoryChangingPillTabSelector
        tabs={[
          { id: CARDS_TO_SIGN, text: I18N('tabs.cards_to_sign') },
          { id: SIGNED_CARDS, text: I18N('tabs.signed_cards') },
          { id: CREATED_CARDS, text: I18N('tabs.created_cards') },
        ]}
        pillClasses='text-normal'
      />

      <CelebrationsList filter={selectedTab} />
    </div>
  )
}

export default CelebrationsListPage
