import { AiMessageType } from 'components/ai/chat/ask_ai_modal/types'
import React, { useMemo, useRef } from 'react'

import classNames from 'classnames'
import Card from 'components/common/card'
import AiAnswerContent from 'components/search/ai/aiAnswerContent'
import Feedback from 'components/search/ai/feedback'
import Sources from 'components/search/ai/sources'
import { AssistantAnswerStatuses } from 'types/ai/assistantAnswer'
import CdnSvg from 'components/common/cdnSvg'
import useCopyToClipboard from 'components/common/hooks/useCopyToClipboard'
import { ButtonSmallNarrow } from 'components/common/buttons'
import Tooltip from 'components/common/clearyTooltip'
import { I18NCommon } from 'utils/i18nHelpers'


type AiMessageProps = {
  message: AiMessageType
}

export const AiMessageWrapper = ({
  children,
  footer,
  aiBorder = true,
}: {
  children: React.ReactNode
  footer?: React.ReactNode
  aiBorder?: boolean
}) => (
  <div className='AiMessage d-flex mb-4'>
    <div className='flex-shrink-0 mr-3'>
      <CdnSvg className='svg-highlight' src='/images/illustrations/aiGradientIcon.svg' />
    </div>

    <div className='flex-grow-1 AiOverview__container'>
      <Card
        className={classNames('AiOverview d-inline-block', {
          'border-color-ai': aiBorder,
          'bg-highlight-color-tint-90': !aiBorder,
        })}
      >
        <div className='p-3-5'>{children}</div>
      </Card>

      {footer}
    </div>
  </div>
)

const AiMessage = ({ message }: AiMessageProps) => {
  const copyElementRef = useRef<HTMLDivElement>(null)

  const { handleCopyClick, isCopied } = useCopyToClipboard({
    copyElement: copyElementRef.current,
  })

  // remove html tags from the content
  const clipboardText = useMemo(() => {
    const div = document.createElement('div')
    div.innerHTML = message.content
    // remove any sub and sup tags
    // sup tags are used for mentions, we are currently not displaying that to end users
    // sub tag returns the confidence score, we don't want to show it in the text
    div.querySelectorAll('sub, sup').forEach((node) => {
      node.remove()
    })
    return div.textContent || div.innerText || ''
  }, [message.content])

  return (
    <AiMessageWrapper>
      <div ref={copyElementRef} data-clipboard-text={clipboardText}>
        <AiAnswerContent content={message.content} />
        {message.aiAssistantAnswer && message.aiAssistantAnswer.status !== AssistantAnswerStatuses.NotAnswered && (
          <>
            <Sources sources={message.aiAssistantAnswer.sources} />

            <div className='mt-4 d-flex justify-content-between'>
              <Feedback assistantAnswer={message.aiAssistantAnswer} setAssistantAnswer={() => {}} />

              <ButtonSmallNarrow
                variant='link'
                onClick={handleCopyClick}
                className='CopyButtonSmallNarrow'
              >
                <Tooltip
                  content={<CdnSvg src='/images/duplicateIcon.svg' />}
                  placement='bottom'
                  hide={0}
                >
                  {isCopied ? I18NCommon('copied_with_bang') : I18NCommon('copy')}
                </Tooltip>
              </ButtonSmallNarrow>
            </div>
          </>
        )}

      </div>
    </AiMessageWrapper>
  )
}

export default AiMessage
