import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'

import isElementVisible from 'utils/isElementVisible'
import { i18nPath, i18nFormat, I18NCommon } from 'utils/i18nHelpers'
import API from 'services/api'
import { articlePath } from 'utils/routeHelpers'

import ArticleType from 'components/articles/articleType'
import ArticleDropdownMenu from 'components/articles/articleDropdownMenu'
import ArticleContent from 'components/articles/articleContent'
import ArticleByLine from 'components/articles/articleByLine'
import Tag from 'components/common/tag'
import ArticleSocial from 'components/articles/articleSocial'
import Card from 'components/common/card'
import AcknowledgementBanner from 'components/articles/acknowledgementBanner'
import { PENDING, OVERDUE } from 'types/acknowledgement'
import { trackEvent } from 'services/tracker'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import CdnSvg from 'components/common/cdnSvg'
import { ARTICLES_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'
import isHtmlStringEmpty from 'utils/isHtmlStringEmpty'
import classNames from 'classnames'
import ClearyTooltip from 'components/common/clearyTooltip'

const I18N = i18nPath('views.article')

const impressionTimeMS = 3000

const ExcludedCardConfirmation = ({ tags = [] } : { tags?: any[] }) => {
  const articleTags = <strong>{tags.map(tag => tag.name).join(', ')}</strong>
  const settingsLink = <Link to='/settings/news'>{I18N('dropdown_menu.settings')}</Link>

  return (
    <Card>
      <div className='pb-3'>{i18nFormat(I18N('dropdown_menu.excluded_confirmation'), articleTags)}</div>
      <div>{i18nFormat(I18N('dropdown_menu.excluded_confirmation_settings_link'), settingsLink)}</div>
    </Card>
  )
}

interface Props {
  article: any
  feedItem?: any
  onTagClick?: (tag: any) => void
  onChangeCard?: (html: string) => void
  tracking?: boolean
  readOnly?: boolean
  isEditingTemplate?: boolean
  togglePreview?: () => void
  isPreviewing?: boolean
  regenerateSummary?: () => void
}

const ArticleCard = ({
  article,
  feedItem,
  onTagClick,
  onChangeCard = () => {},
  tracking = true,
  readOnly = true,
  isEditingTemplate = false,
  togglePreview = undefined,
  isPreviewing = false,
  regenerateSummary,
}: Props) => {
  const [showExcludedConfirmation, setShowExcludedConfirmation] = useState(false)
  const [isVisible, setIsVisible] = useState<boolean | null>(false)
  const [isHandlingScroll, setIsHandlingScroll] = useState(false)
  const [hasImpressionBeenSent, setImpressionSent] = useState(false)
  const articleCardElement = useRef<any>(null)
  const visibilityTimer = useRef<any>(null)
  const impressionTime = useRef<any>(null)

  const trackImpression = async () => {
    if (impressionTime.current === null) {
      impressionTime.current = moment.now()
      await trackEvent('impression', { trackable_id: article.id, trackable_type: 'Article' })
      setImpressionSent(true)
    }
  }

  const checkImpression = () => {
    const isVisibleInViewport = isElementVisible(articleCardElement.current)

    if (isVisible !== isVisibleInViewport) {
      setIsVisible(isVisibleInViewport)

      if (isVisibleInViewport) {
        visibilityTimer.current = setTimeout(trackImpression, impressionTimeMS)
      } else {
        clearTimeout(visibilityTimer.current)
      }
    }
  }

  const handleScroll = () => {
    if (!isHandlingScroll) {
      setIsHandlingScroll(true)
      const scrollRateLimitMS = 50
      setTimeout(() => {
        setIsHandlingScroll(false)
      }, scrollRateLimitMS)

      checkImpression()
    }
  }

  useEffect(() => {
    if (tracking) {
      window.addEventListener('scroll', handleScroll)
      window.addEventListener('resize', checkImpression)
      checkImpression()
    }
    return () => {
      if (tracking) {
        clearTimeout(visibilityTimer.current)
        window.removeEventListener('scroll', handleScroll)
        window.removeEventListener('resize', checkImpression)
      }
    }
  }, [])

  // Stop cheking for scrolling/resizing movements if the impression for the article has been sent
  useEffect(() => {
    if (hasImpressionBeenSent) {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', checkImpression)
    }
  }, [hasImpressionBeenSent])

  const handleExcludeArticleFromFeed = () => {
    API.home.excludeFromFeed(feedItem)
    setShowExcludedConfirmation(true)
  }

  const handleTagClick = (tag) => {
    if (!onTagClick) return
    onTagClick(tag)
  }

  const { settings } = useCurrentCompany()
  const { facebook, twitter, linkedin } = settings.news.socialSharing
  const socialShareEnabled = facebook || twitter || linkedin

  if (showExcludedConfirmation) {
    return (
      <div ref={articleCardElement}>
        <ExcludedCardConfirmation />
      </div>
    )
  }

  const {
    id, title, cardContentId, cardContent, articleType, acknowledgementInfo,
  } = article

  const articleRoute = articlePath(article)
  const previewDisabled = !title && isHtmlStringEmpty(cardContent)

  return (
    <div ref={articleCardElement}>
      <Card className='ArticleCard' cardBodyClassName={readOnly ? '' : 'pb-0'}>
        {readOnly && [PENDING, OVERDUE].includes(acknowledgementInfo?.status) && (
          <AcknowledgementBanner article={article} />
        )}
        <div className='position-relative mb-2'>
          {readOnly && articleType ? (
            <Link className='Article-typeLink' to={'/news/' + articleType.nameParam}>
              {articleType && <ArticleType articleType={articleType} className='text-large' />}
            </Link>
          ) : (
            <span>{articleType && <ArticleType articleType={articleType} className='text-large' />}</span>
          )}

          <div className='position-absolute top-0 right-0 d-flex align-items-center'>
            {regenerateSummary && (
              <ClearyTooltip
                placement='bottom'
                content={(
                  <>
                    <div className='pointer' onClick={regenerateSummary}>
                      <CdnSvg className='PreviewIcon' src='/images/syncIcon.svg' />
                      <span className='ml-1 link-color text-small'>{I18N('regenerate_summary')}</span>
                    </div>
                  </>
                )}
              >
                {I18N('regenerate_summary_tooltip')}
              </ClearyTooltip>
            )}

            {togglePreview ? (
              <div className={classNames('pointer ml-3', { 'disabled-link': previewDisabled })} onClick={togglePreview}>
                <CdnSvg className='PreviewIcon' src={isPreviewing ? '/images/pencilIcon.svg' : '/images/eyeOpenIcon.svg'} />
                <span className='ml-1 link-color text-small'>
                  {isPreviewing ? I18NCommon('edit') : I18NCommon('preview')}
                </span>
              </div>
            ) : (
              <ArticleDropdownMenu
                article={article}
                handleExcludeArticleFromFeed={feedItem && handleExcludeArticleFromFeed}
              />
            )}
          </div>
        </div>

        <h3 className='mb-0'>
          {readOnly ? (
            <Link className='text-primary-link font-weight-700' to={articleRoute}>
              {title}
            </Link>
          ) : (
            title
          )}
        </h3>

        <div className='mt-2 mb-3'>
          <ArticleByLine article={article} isCard />
        </div>

        {readOnly || isPreviewing ? (
          <ArticleContent
            article={article}
            richTextKey='cardContent'
            isPreviewing={isPreviewing}
            includeReadMoreLink
          />
        ) : (
          <TiptapEditor
            key={id}
            onChange={onChangeCard}
            html={cardContent}
            configuration={{
              stickyToolbar: true,
              videosEnabled: true,
              isZoomEnabled: settings.zoom?.enabled,
              aiEnabled: settings.aiTextGeneration?.enabled,
              socialShareEnabled,
              isEditingTemplate,
            }}
            richTextId={cardContentId}
            toolbarItems={ARTICLES_TOOLBAR}
          />
        )}

        {!isEmpty(article.tags) && (
          <div className='position-relative mt-3 d-flex align-items-start' style={{ minHeight: '1rem' }}>
            <div className='Articles__TagList text-left'>
              {article.tags.map(tag => (
                <Tag text={tag.name} key={tag.id} onClick={() => handleTagClick(tag)} />
              ))}
            </div>
          </div>
        )}

        {/* articles are read only when not editing, */}
        {/* so we want to disable actions when an article is not readonly (someone is editing the article) */}
        {/* or it's previewing */}
        <ArticleSocial disableActions={!readOnly} article={article} />
      </Card>
    </div>
  )
}

export default ArticleCard
