import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import CopyArticleModal from 'components/admin/articles/copyArticleModal'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import CdnSvg from 'components/common/cdnSvg'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import adminArticleSlice from 'redux/slices/admin/articles'
import DeleteModal from 'components/common/modals/deleteModal'
import { SendPreviewEmailModal } from 'components/admin/sendPreviewEmailButtonAndModal'
import ConfirmationModal from 'components/common/modals/confirmationModal'
import SlugInput from 'components/common/slugInput'
import { buildArticleTemplatePayload } from 'redux/slices/articleTemplates'
import useArticlesBasePath from 'components/admin/articles/hooks/useArticlesBasePath'
import useIsGroupPost from 'components/admin/articles/hooks/useIsGroupPost'
import ToggleSwitch from 'components/common/toggleSwitch'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

const VIEW_ARTICLE = 'view_article'
const EDIT_ARTICLE = 'edit_article'
const COPY_ARTICLE = 'copy_article'
const SEND_PREVIEW_EMAIL = 'send_preview_email'
const RESEND_NOTIFICATIONS = 'resend_notifications'
const UPDATE_SLUG = 'update_slug'
const BROWSE_TEMPLATES = 'browse_templates'
const SAVE_AS_TEMPLATE = 'save_as_template'
const UNPUBLISH_ARTICLE = 'unpublish_article'
const VIEW_ANALYTICS = 'view_analytics'
const DELETE_ARTICLE = 'delete_article'
const CHANGE_EDIT_ACCESS = 'change_edit_access'

const ITEM_COMBINATIONS = {
  editor: {
    regular: {
      new: [BROWSE_TEMPLATES, SAVE_AS_TEMPLATE],
      draft: [
        COPY_ARTICLE, SEND_PREVIEW_EMAIL, UPDATE_SLUG, BROWSE_TEMPLATES,
        SAVE_AS_TEMPLATE, DELETE_ARTICLE, CHANGE_EDIT_ACCESS,
      ],
      scheduled: [
        VIEW_ARTICLE, COPY_ARTICLE, SEND_PREVIEW_EMAIL, UPDATE_SLUG,
        SAVE_AS_TEMPLATE, UNPUBLISH_ARTICLE, DELETE_ARTICLE, CHANGE_EDIT_ACCESS,
      ],
      pending: [
        VIEW_ARTICLE, COPY_ARTICLE, SEND_PREVIEW_EMAIL, UPDATE_SLUG,
        SAVE_AS_TEMPLATE, UNPUBLISH_ARTICLE, DELETE_ARTICLE, CHANGE_EDIT_ACCESS,
      ],
      published: [
        VIEW_ARTICLE, COPY_ARTICLE, SEND_PREVIEW_EMAIL, RESEND_NOTIFICATIONS, UPDATE_SLUG,
        SAVE_AS_TEMPLATE, UNPUBLISH_ARTICLE, VIEW_ANALYTICS, DELETE_ARTICLE, CHANGE_EDIT_ACCESS,
      ],
    },
    group: {
      new: [],
      draft: [COPY_ARTICLE, SEND_PREVIEW_EMAIL, UPDATE_SLUG, DELETE_ARTICLE],
      scheduled: [VIEW_ARTICLE, COPY_ARTICLE, SEND_PREVIEW_EMAIL, UPDATE_SLUG, DELETE_ARTICLE],
      pending: [VIEW_ARTICLE, COPY_ARTICLE, SEND_PREVIEW_EMAIL, UPDATE_SLUG, DELETE_ARTICLE],
      published: [VIEW_ARTICLE, COPY_ARTICLE, SEND_PREVIEW_EMAIL, UPDATE_SLUG, VIEW_ANALYTICS, DELETE_ARTICLE],
    },
  },
  list: {
    regular: {
      draft: [EDIT_ARTICLE, COPY_ARTICLE, DELETE_ARTICLE],
      scheduled: [VIEW_ARTICLE, EDIT_ARTICLE, COPY_ARTICLE, DELETE_ARTICLE],
      pending: [VIEW_ARTICLE, EDIT_ARTICLE, COPY_ARTICLE, DELETE_ARTICLE],
      published: [VIEW_ARTICLE, EDIT_ARTICLE, COPY_ARTICLE, VIEW_ANALYTICS, DELETE_ARTICLE],
    },
    group: {
      draft: [EDIT_ARTICLE, COPY_ARTICLE, DELETE_ARTICLE],
      scheduled: [VIEW_ARTICLE, EDIT_ARTICLE, COPY_ARTICLE, DELETE_ARTICLE],
      pending: [VIEW_ARTICLE, EDIT_ARTICLE, COPY_ARTICLE, DELETE_ARTICLE],
      published: [VIEW_ARTICLE, EDIT_ARTICLE, COPY_ARTICLE, VIEW_ANALYTICS, DELETE_ARTICLE],
    },
  },
}

const DELETE_MODAL = 'DELETE_MODAL'
const COPY_MODAL = 'COPY_MODAL'
const PREVIEW_EMAIL_MODAL = 'PREVIEW_EMAIL_MODAL'
const RESEND_NOTIFICATIONS_MODAL = 'RESEND_NOTIFICATIONS_MODAL'
const UNPUBLISH_MODAL = 'UNPUBLISH_MODAL'
const UPDATE_SLUG_MODAL = 'UPDATE_SLUG_MODAL'
const AI_NODES_WARNING_MODAL = 'AI_NODES_WARNING_MODAL'

type Props = {
  article: any
  // we use this in the editors and the lists some different buttons
  location: 'list' | 'editor'
  workingCopy?: any
  showTemplatesSidebar?: boolean
  setShowTemplatesSidebar?: (show: boolean) => void
  setCreatedTemplateId?: (id: string) => void
}

const ArticleDropdown = ({
  article,
  location,
  workingCopy = {},
  showTemplatesSidebar,
  setShowTemplatesSidebar = () => {},
  setCreatedTemplateId = () => {},
}: Props) => {
  const currentUser = useCurrentUser()
  const canMakeArticlePrivate = currentUser.permissions.superAdmin || currentUser.id === article?.creator?.id
  const history = useHistory()
  const basePath = useArticlesBasePath()
  const dispatch = useDispatch()
  const [currentOpenModal, setCurrentOpenModal] = useState<string | null>(null)
  const closeModal = () => setCurrentOpenModal(null)

  const inEditor = location === 'editor'
  const isGroupPost = useIsGroupPost(article)
  const I18N = i18nPath(`views.admin.article_list.item_dropdown.${isGroupPost ? 'group_post' : 'article'}`)

  const [copyArticle, { isLoading: isCopying }] = useApi(API.admin.articles.copyArticle, {
    toastSuccessMessage: I18N('copy_article_success_message'),
    toastErrorMessage: I18N('copy_article_error_message'),
    onSuccess: (newArticle) => {
      history.push(`${basePath}/${newArticle.id}`)
    },
    onFinally: closeModal,
  })

  const [deleteArticle, { isLoading: isDeleting }] = useApi(API.admin.articles.destroy, {
    toastSuccessMessage: I18N('delete_article_success_message'),
    toastErrorMessage: I18N('delete_article_error_message'),
    onSuccess: () => {
      dispatch(adminArticleSlice.actions.removeFromArticleIds(article.id))
      if (inEditor) {
        history.push(basePath)
      }
    },
    onFinally: closeModal,
  })

  const [sendPreviewEmail, { isLoading: isSendingPreviewEmail }] = useApi(API.admin.articles.sendPreviewEmail, {
    toastSuccessMessage: I18N('send_preview_email_success_message'),
    toastErrorMessage: I18N('send_preview_email_error_message'),
    onFinally: closeModal,
  })

  const [resendNotifications, { isLoading: isResendingNotifications }] = useApi(
    API.admin.articles.resendNotifications, {
      toastSuccessMessage: I18N('resend_notifications_success_message'),
      toastErrorMessage: I18N('resend_notifications_error_message'),
      onFinally: closeModal,
    }
  )

  const [unpublishArticle, { isLoading: isUnpublishingArticle }] = useApi(
    () => API.admin.articles.update({ id: article.id, draft: true, publishedAt: null }), {
      updateEntitySlice: true,
      toastSuccessMessage: I18N('unpublish_article_success_message'),
      toastErrorMessage: I18N('unpublish_article_error_message'),
      onFinally: closeModal,
    }
  )

  const [changeEditAccess] = useApi(
    () => API.admin.articles.update({ id: article.id, privateEditAccess: !article.privateEditAccess }), {
      updateEntitySlice: true,
      toastSuccessMessage: I18N(article?.privateEditAccess ? 'change_to_public_success_message' : 'change_to_private_success_message'),
    }
  )

  const [newSlug, setNewSlug] = useState(article?.slug)
  const [updateSlug, { isLoading: isUpdatingSlug, error: slugError }] = useApi(
    () => API.admin.articles.update({ id: article.id, slug: newSlug }), {
      updateEntitySlice: true,
      toastSuccessMessage: I18N('update_slug_success_message'),
      onSuccess: closeModal,
    }
  )

  const [createTemplate, { isLoading: isCreatingTemplate }] = useApi(API.admin.article.templates.create, {
    toastSuccessMessage: I18N('save_as_template_success_message'),
    toastErrorMessage: I18N('save_as_template_error_message'),
    onSuccess: (template) => {
      setCreatedTemplateId(template.id)
      closeModal()
    },
  })

  const onCreateTemplate = () => {
    const templatePayload = buildArticleTemplatePayload({
      ...workingCopy,
      requireAcknowledgement: !!workingCopy.acknowledgementDueAt,
      id: undefined,
      // we are going to do draft templates in the future, but for now we just create a new published template
      draft: false,
      copyAttachments: true,
      articleId: workingCopy.id,
    })

    createTemplate(templatePayload)
  }

  const onSaveAsTemplateClick = (e) => {
    const hasAiNodes = workingCopy.content?.includes('class="ai-') || workingCopy.cardContent?.includes('class="ai-')
    if (hasAiNodes) {
      e.stopPropagation()
      setCurrentOpenModal(AI_NODES_WARNING_MODAL)
    } else {
      onCreateTemplate()
    }
  }

  const items = ITEM_COMBINATIONS[location][isGroupPost ? 'group' : 'regular'][article?.status || 'new']

  const isPublished = article?.status === 'published'

  if (_.isEmpty(items)) {
    return null
  }

  return (
    <>
      <DropdownMenuContainer className='ArticleDropdown default-dropdown' menuType='kebab' closeMenuOnItemClick>
        {items.includes(VIEW_ARTICLE) && (
          <section>
            <CardDropdownMenuItem
              link={article.displayPath}
              primaryText={I18N('view_article')}
              icon={<CdnSvg className='mr-2' src='/images/eyeOpenIcon.svg' />}
            />
          </section>
        )}
        {items.includes(EDIT_ARTICLE) && (
          <section>
            <CardDropdownMenuItem
              link={`${basePath}/${article.id}`}
              primaryText={I18N('edit_article')}
              icon={<CdnSvg className='mr-2' src='/images/pencilIcon.svg' />}
            />
          </section>
        )}
        <section>
          {items.includes(COPY_ARTICLE) && (
            <CardDropdownMenuItem
              onClick={() => setCurrentOpenModal(COPY_MODAL)}
              primaryText={I18N('copy_article')}
              icon={<CdnSvg className='mr-2' src='/images/duplicateIcon.svg' />}
            />
          )}
          {items.includes(SEND_PREVIEW_EMAIL) && (
            <CardDropdownMenuItem
              onClick={() => setCurrentOpenModal(PREVIEW_EMAIL_MODAL)}
              primaryText={I18N('send_preview_email')}
              icon={<CdnSvg className='mr-2' src='/images/envelopeOutlineIcon.svg' />}
            />
          )}
          {items.includes(RESEND_NOTIFICATIONS) && (
            <CardDropdownMenuItem
              onClick={() => setCurrentOpenModal(RESEND_NOTIFICATIONS_MODAL)}
              primaryText={I18N('resend_notifications')}
              icon={<CdnSvg className='mr-2' src='/images/bellIcon.svg' />}
            />
          )}
          {items.includes(UPDATE_SLUG) && (
            <CardDropdownMenuItem
              disabled={isPublished}
              onClick={() => setCurrentOpenModal(UPDATE_SLUG_MODAL)}
              primaryText={I18N('update_slug')}
              icon={<CdnSvg className='mr-2' src='/images/linkHorizontalIcon.svg' />}
              tooltipText={isPublished ? I18N('update_published_slug_tooltip') : undefined}
            />
          )}
        </section>
        <section>
          {items.includes(BROWSE_TEMPLATES) && (
            <CardDropdownMenuItem
              onClick={() => setShowTemplatesSidebar(!showTemplatesSidebar)}
              primaryText={I18NCommon(showTemplatesSidebar ? 'hide_templates' : 'browse_templates')}
              icon={<CdnSvg className='mr-2' src='/images/pageIcon.svg' />}
            />
          )}
          {items.includes(SAVE_AS_TEMPLATE) && (
            <CardDropdownMenuItem
              onClick={onSaveAsTemplateClick as any}
              disabled={isCreatingTemplate || !workingCopy?.title}
              primaryText={I18N('save_as_template')}
              icon={<CdnSvg className='mr-2' src='/images/diskIcon.svg' />}
              tooltipText={!workingCopy?.title ? I18N('fill_title_to_create_template_tooltip') : undefined}
            />
          )}
        </section>
        {items.includes(UNPUBLISH_ARTICLE) && (
          <section>
            <CardDropdownMenuItem
              onClick={() => setCurrentOpenModal(UNPUBLISH_MODAL)}
              primaryText={I18N('unpublish_article')}
              icon={<CdnSvg src='/images/eyeStrikethroughIcon.svg' className='mr-2' />}
            />
          </section>
        )}

        {items.includes(CHANGE_EDIT_ACCESS) && canMakeArticlePrivate && (
          <section>
            {/* need to stop propagation to prevent the dropdown from closing */}
            <div onClick={e => e.stopPropagation()}>
              <CardDropdownMenuItem
                onClick={() => {}}
                primaryText={(
                  <ToggleSwitch
                    checked={article.privateEditAccess}
                    text={I18N('private_article')}
                    onChange={() => changeEditAccess()}
                    textPosition='after'
                  />
                )}
              />
            </div>
          </section>
        )}

        {items.includes(VIEW_ANALYTICS) && (
          <section>
            <CardDropdownMenuItem
              link={`${basePath}/${article.id}/stats`}
              primaryText={I18N('view_analytics')}
              icon={<CdnSvg src='/images/analytics/barChartIcon.svg' className='mr-2' />}
            />
          </section>
        )}
        {items.includes(DELETE_ARTICLE) && (
          <section>
            <CardDropdownMenuItem
              className='Card-dropdownMenuItem--danger'
              onClick={() => setCurrentOpenModal(DELETE_MODAL)}
              primaryText={I18N('delete_article')}
              icon={<CdnSvg src='/images/trashIcon.svg' className='mr-2' />}
            />
          </section>
        )}
      </DropdownMenuContainer>
      {currentOpenModal === COPY_MODAL && (
        <CopyArticleModal
          articleTitle={article?.title}
          closeCopyArticleModal={closeModal}
          handleCopyArticle={title => copyArticle({ id: article.id, title })}
          isCopying={isCopying}
          showCopyArticleModal={currentOpenModal === COPY_MODAL}
        />
      )}
      {currentOpenModal === PREVIEW_EMAIL_MODAL && (
        <SendPreviewEmailModal
          visible={currentOpenModal === PREVIEW_EMAIL_MODAL}
          closeModal={closeModal}
          isSending={isSendingPreviewEmail}
          onSend={recipientIds => sendPreviewEmail(article.id, recipientIds)}
          areChangesPresent={false}
          objectName={I18N('article')}
        />
      )}
      {currentOpenModal === RESEND_NOTIFICATIONS_MODAL && (
        <ConfirmationModal
          title={I18N('resend_notifications_modal.title')}
          description={I18N('resend_notifications_modal.body')}
          visible={currentOpenModal === RESEND_NOTIFICATIONS_MODAL}
          toggle={closeModal}
          isSaving={isResendingNotifications}
          onConfirmClick={() => resendNotifications(article.id)}
          confirmText={I18N('resend_notifications_modal.confirm')}
        />
      )}
      {currentOpenModal === UPDATE_SLUG_MODAL && (
        <ConfirmationModal
          title={I18N('update_slug_modal.title')}
          description={I18N('update_slug_modal.body')}
          visible={currentOpenModal === UPDATE_SLUG_MODAL}
          toggle={closeModal}
          isSaving={isUpdatingSlug}
          onConfirmClick={() => updateSlug()}
          confirmText={I18N('update_slug_modal.confirm')}
        >
          <SlugInput
            className='mb-5'
            value={newSlug}
            onChange={(value: string) => setNewSlug(value)}
            errorMessage={slugError?.errors?.slug?.[0] || slugError?.message}
          />
        </ConfirmationModal>
      )}
      {currentOpenModal === AI_NODES_WARNING_MODAL && (
        <ConfirmationModal
          title={I18N('ai_nodes_warning_modal.title')}
          description={I18N('ai_nodes_warning_modal.body')}
          visible={currentOpenModal === AI_NODES_WARNING_MODAL}
          toggle={closeModal}
          onConfirmClick={onCreateTemplate}
          confirmText={I18N('ai_nodes_warning_modal.confirm')}
        />
      )}
      {currentOpenModal === UNPUBLISH_MODAL && (
        <ConfirmationModal
          title={I18N('unpublish_article_modal.title')}
          description={I18N('unpublish_article_modal.body')}
          visible={currentOpenModal === UNPUBLISH_MODAL}
          toggle={closeModal}
          isSaving={isUnpublishingArticle}
          onConfirmClick={unpublishArticle}
        />
      )}
      {currentOpenModal === DELETE_MODAL && (
        <DeleteModal
          deleteText={I18N('delete_article')}
          deleteSecondaryText={I18N('delete_article_description')}
          deleteConfirm={() => deleteArticle({ id: article.id })}
          showDeleteModal={currentOpenModal === DELETE_MODAL}
          closeDeleteModal={closeModal}
          isDeleting={isDeleting}
        />
      )}
    </>
  )
}

export default ArticleDropdown
