import UserLink from 'components/common/userLink'
import React, { useEffect, useMemo, useState } from 'react'
import { TicketType } from 'types/ticketing/ticket'
import { SimpleUserType } from 'types/user'
import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import TipTapRichTextView from 'components/common/tiptap/tiptapRichTextView'
import useCursorPagination from 'components/common/hooks/useCursorPagination'
import API from 'services/api'
import { TicketingMessageType } from 'types/ticketing/message'
import moment from 'moment'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { SIMPLE_EDITOR_CONFIGURATION } from 'components/common/tiptap/configurations'
import { SMALL_TOOLBAR_WITH_ATTACHMENTS } from 'components/common/tiptap/toolbar/toolbarVariations'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { ButtonSmallNarrow } from 'components/common/buttons'
import useApi from 'components/common/hooks/useApi'
import useStickyScroll from 'components/ai/chat/ask_ai_modal/hooks/useStickyScroll'
import CurrentSuggestedMessage from 'components/ticketing/view_ticket_sidebar/currentSuggestedMessage'


type Props = {
  ticket: TicketType
  isAdmin: boolean
}

const I18N = i18nPath('views.ticketing.view_ticket_sidebar')

const TicketMessages = ({ ticket, isAdmin = false }: Props) => {
  const ticketingApi = isAdmin ? API.admin.ticketing : API.ticketing

  const {
    data: rawMessages,
    setData: setMessages,
    isLoading: isLoadingMessages,
    onLoadMore: onLoadMoreMessages,
    hasMore: hasMoreMessages,
  } = useCursorPagination<TicketingMessageType>(
    ticketingApi.messages.fetchAll,
    [ticket.id]
  )

  const messages = useMemo(
    () => _.sortBy(rawMessages, message => moment(message.createdAt).unix()),
    [rawMessages]
  )

  const [newMessageContent, setNewMessageContent] = useState('')
  const [editorKey, setEditorKey] = useState(0)

  const [createMessage, { isLoading: isCreatingMessage }] = useApi(ticketingApi.messages.create, {
    onSuccess: (message) => {
      addMessage(message)
      updateContentMessage('')
    },
    updateEntitySlice: true,
  })

  const addMessage = (message: TicketingMessageType) => {
    setMessages([...messages, message])
  }

  const updateContentMessage = (content: string) => {
    setNewMessageContent(content)
    setEditorKey(prevKey => prevKey + 1)
  }

  const handleCreateMessage = () => {
    createMessage(ticket.id, { content: newMessageContent })
  }

  const { scrollableRef, isScrollAtTheTop } = useStickyScroll()

  useEffect(() => {
    if (isScrollAtTheTop && hasMoreMessages) {
      onLoadMoreMessages()
    }
  }, [isScrollAtTheTop, hasMoreMessages])

  return (
    <div className='TicketMessages d-flex flex-column overflow-y-hidden justify-content-between flex-grow-1'>
      <div className='d-flex flex-column gap-3 overflow-y-auto' ref={scrollableRef}>
        {!isLoadingMessages && !hasMoreMessages && (
          <Message
            user={ticket.creator}
            date={ticket.createdAt}
            html={ticket.description}
          />
        )}
        {isLoadingMessages && <CirclesLoadingIndicator />}
        {messages.map(message => (
          <Message
            key={message.id}
            user={message.user}
            date={message.createdAt}
            html={message.content}
          />
        ))}
        {isAdmin && (
          <CurrentSuggestedMessage
            ticketId={ticket.id}
            addMessage={addMessage}
            updateContentMessage={updateContentMessage}
          />
        )}
      </div>
      <div className='mt-5'>
        <TiptapEditor
          key={editorKey}
          className='TipTapEditor bordered'
          editorContentClassName='p-3'
          onChange={html => setNewMessageContent(html)}
          html={newMessageContent}
          configuration={{
            ...SIMPLE_EDITOR_CONFIGURATION,
            imagesEnabled: true,
            filesEnabled: true,
            userMentionsEnabled: true,
            placeholder: I18N('new_message_placeholder'),
            focusOnInitialize: true,
          }}
          toolbarItems={SMALL_TOOLBAR_WITH_ATTACHMENTS}
        />
        <ButtonSmallNarrow
          showLoadingSpinner={isCreatingMessage}
          onClick={handleCreateMessage}
          disabled={!newMessageContent || isCreatingMessage}
          className='mt-2 float-right'
        >
          {ticket.status === 'closed' ? I18N('send_and_reopen') : I18NCommon('send')}
        </ButtonSmallNarrow>
      </div>
    </div>
  )
}

const Message = ({ user, date, html }: { user?: SimpleUserType, date: string, html: string }) => (
  <div className='bordered px-3 py-2'>
    <div className='d-flex justify-content-between align-items-center mb-3'>
      <div><UserLink user={user} avatarSize='24px' customClassName='text-normal text-primary' /></div>
      <div className='text-small font-weight-600'>{i18nMoment(date).fromNow()}</div>
    </div>
    <div className='mt-2'>
      <TipTapRichTextView
        html={html}
      />
    </div>
  </div>
)

export default TicketMessages
