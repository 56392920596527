
import classNames from 'classnames'
import CreateTicketSidebar from 'components/admin/ticketing/createTicketSidebar'
import { ButtonSecondarySmall } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'
import CloseIcon from 'components/icons/closeIcon'
import React, { useState } from 'react'
import { I18NCommon } from 'utils/i18nHelpers'


interface MenuItemProps {
  active?: boolean
  iconPath?: string
  iconComponent?: React.ReactNode
  label: string
  count: number
  onClick?: () => void
}

const MenuItem = ({
  active, iconPath, iconComponent, label, count, onClick,
}: MenuItemProps) => (
  <ButtonSecondarySmall
    className={
      classNames('Sidebar__MenuItem d-flex mb-1 align-items-center justify-content-between w-100', {
        active,
      })
    }
    onClick={onClick}
  >
    <span>
      {iconComponent}
      {iconPath && <CdnSvg src={iconPath} className='mr-1' />}
      {label}
    </span>

    <span className='Sidebar__MenuItem__Count'>
      {count}
    </span>
  </ButtonSecondarySmall>
)


interface TicketListPageSidebarProps {
  toggleSidebarComponent?: React.ReactNode
  fetchTickets: () => void
  counts: {
    totalCount: number
    assignedToYouCount: number
    unassignedCount: number
    closedCount: number
  }
}

const TicketListPageSidebar = ({ toggleSidebarComponent, fetchTickets, counts }: TicketListPageSidebarProps) => {
  const [selectedTab, setSelectedTab] = useQueryParamState({
    param: 'selectedTab',
    initialValue: 'inbox',
  })
  const [isCreateTicketSidebarOpen, setIsCreateTicketSidebarOpen] = useState(false)

  return (
    <div className='TicketListPageSidebar'>
      <div className='d-flex align-items-center justify-content-between mb-4'>
        <h4 className='mb-0'>{I18NCommon('tickets')}</h4>

        {toggleSidebarComponent}
      </div>

      <ButtonSecondarySmall
        className='Sidebar__Button w-100 justify-content-start'
        onClick={() => setIsCreateTicketSidebarOpen(true)}
      >
        <CdnSvg src='/images/addPencilIcon.svg' className='mr-1' />
        {I18NCommon('create_new')}
      </ButtonSecondarySmall>

      <div className='mt-3'>
        <MenuItem
          active={selectedTab === 'inbox'}
          onClick={() => setSelectedTab('inbox')}
          iconPath='/images/personUnderlineIcon.svg'
          label={I18NCommon('inbox')}
          count={counts?.totalCount}
        />
        <MenuItem
          active={selectedTab === 'assigned_to_you'}
          onClick={() => setSelectedTab('assigned_to_you')}
          iconPath='/images/smileOutlineIcon.svg'
          label={I18NCommon('assigned_to_you')}
          count={counts?.assignedToYouCount}
        />
        <MenuItem
          active={selectedTab === 'unassigned'}
          onClick={() => setSelectedTab('unassigned')}
          iconComponent={<CloseIcon />}
          label={I18NCommon('unassigned')}
          count={counts?.unassignedCount}
        />
        <MenuItem
          active={selectedTab === 'closed'}
          onClick={() => setSelectedTab('closed')}
          iconPath='/images/circledSimpleCheck.svg'
          label={I18NCommon('closed')}
          count={counts?.closedCount}
        />
      </div>
      <CreateTicketSidebar
        isOpen={isCreateTicketSidebarOpen}
        onClose={() => setIsCreateTicketSidebarOpen(false)}
        onCreate={fetchTickets}
      />
    </div>
  )
}

export default TicketListPageSidebar
