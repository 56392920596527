import React from 'react'

import TagsSelect from 'components/common/tagsSelect'
import { TagType } from 'types/article/tag'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.article_settings.news_subscription_settings')

const NewsSubscriptionSettings = ({
  workingCopy,
  setWorkingCopy,
}: {
  workingCopy: TagType[]
  setWorkingCopy: (tags: TagType[]) => void
}) => (
  <div>
    <h6>{I18N('title')}</h6>
    <p className='text-secondary text-small'>{I18N('sub_title')}</p>

    <TagsSelect value={workingCopy} onChange={setWorkingCopy} shouldFetchTags />
    <p className='text-muted text-small mt-1'>{I18N('tags_hint')}</p>
  </div>
)

export default NewsSubscriptionSettings
