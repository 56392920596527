import classNames from 'classnames'
import React from 'react'
import { trackAmplitudeEvent } from 'services/tracker'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.search.search_results.ai_answer')

const Sources = ({ sources }) => {
  if (!sources || sources.length === 0) {
    return null
  }

  return (
    <>
      <div className='text-secondary text-smallest mt-3 font-weight-500'>
        {I18N('sources')}:
      </div>
      <ul className='AiSources d-flex gap-2 flex-wrap'>
        {sources.map((source, index) => (
          <li key={index} className='mt-2 text-secondary text-small'>
            <a
              href={source.url}
              className='text-secondary text-small bg-white border-radius d-flex align-items-center border-color-ai-light'
              onClick={() => trackAmplitudeEvent('ai_assistant_answer:source_clicked', { type: source.type })}
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={source.iconUrl}
                alt={source.title}
                className={classNames('mr-2', source.type === 'User' && 'bordered-thumbnail')}
                width={16}
                height={16}
              />
              <span>{source.title}</span>
            </a>
          </li>
        ))}
      </ul>
    </>
  )
}

export default Sources
