import React from 'react'
import { useParams } from 'react-router-dom'

import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import ReactionsTab from 'components/admin/other_settings/reactionsTab'
import { i18nPath } from 'utils/i18nHelpers'
import OrganizationTab from 'components/admin/other_settings/organizationTab'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import UserPrivacyTab from 'components/admin/other_settings/userPrivacyTab'
import PeopleTab from 'components/admin/other_settings/people/peopleTab'

const I18N = i18nPath('views.admin.other_settings')

const AI = 'ai'
const DEFAULT_USER_PRIVACY = 'default_user_privacy'
const ORGANIZATION = 'organization'
const PEOPLE = 'people'
const REACTIONS = 'reactions'

const OtherSettingsPage = () => {
  const { selectedTab } = useParams()
  const { permissions } = useCurrentUser()

  const tabs = [
    { id: ORGANIZATION, text: I18N('organization'), hidden: !permissions.superAdmin },
    { id: REACTIONS, text: I18N('reactions'), hidden: !permissions.superAdmin },
    { id: DEFAULT_USER_PRIVACY, text: I18N('user_privacy'), hidden: !permissions.superAdmin },
    { id: PEOPLE, text: I18N('people'), hidden: !permissions.superAdmin },
  ]

  return (
    <div className='OtherSettingsPage'>
      <header className='AdminHeader'>
        <h3>{I18N('title')}</h3>
      </header>

      <main className='AdminContent'>
        <HistoryChangingPillTabSelector
          baseUrl='/admin/other_settings'
          tabs={tabs}
          className='mb-3'
          historyKeyToChange='pathname'
          pillClasses='text-normal'
        />
        {selectedTab === REACTIONS && <ReactionsTab />}
        {selectedTab === ORGANIZATION && <OrganizationTab />}
        {selectedTab === DEFAULT_USER_PRIVACY && <UserPrivacyTab />}
        {selectedTab === PEOPLE && <PeopleTab />}
      </main>
    </div>
  )
}

export default OtherSettingsPage
