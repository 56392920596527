import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import SmartTable from 'components/common/tables/smartTable'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import TimeRangeFilter from 'components/analytics/common/timeRangeFilter'
import { GROUP_BY_DAY } from 'components/analytics/common/groupByTabs'
import ForwardArrowIcon from 'components/icons/forwardArrowIcon'
import { I18NCommon } from 'utils/i18nHelpers'
import ExportXlsxButton from 'components/analytics/common/exportXlsxButton'
import { AxiosResponse } from 'axios'

interface CardWithTableProps {
  title: string
  tableColumns: any[]
  tableData: any[]
  classname?: string
  isLoading?: boolean
  showTimeFilter?: boolean
  timeFilterValue?: { id: string, value: string, text: string }
  onFilterChange?: (value: any) => void
  showSeeMoreLink?: boolean
  seeMorePath?: string
  onExport?: (email: string) => Promise<AxiosResponse<any, any>>
}

const CardWithTable = ({
  title,
  tableColumns,
  tableData,
  classname,
  isLoading,
  showTimeFilter = false,
  timeFilterValue,
  onFilterChange,
  showSeeMoreLink = false,
  seeMorePath,
  onExport,
}: CardWithTableProps) => (
  <div className={classNames('analytics-card', classname)}>
    <header className='d-flex flex-row justify-content-between pb-1'>
      <h3>{title}</h3>
      {showTimeFilter && (
        <TimeRangeFilter
          value={timeFilterValue}
          onFilterChange={onFilterChange}
          optionsKey={GROUP_BY_DAY}
        />
      )}
      {onExport && (
        <ExportXlsxButton
          className='ml-1'
          onSubmit={onExport}
        />
      )}
    </header>

    {isLoading ? (
      <CirclesLoadingIndicator className='w-100' />
    ) : (
      <>
        <SmartTable
          columns={tableColumns}
          data={tableData}
          className='analytics-table'
        />

        {showSeeMoreLink && (
          <footer className='pt-3'>
            <Link to={seeMorePath}>
              {I18NCommon('see_more')} <ForwardArrowIcon className='ml-2' fillColor='var(--link-color)' />
            </Link>
          </footer>
        )}
      </>
    )}

  </div>
)

export default CardWithTable
