import React from 'react'
import TabHeader from 'components/analytics/helpers/tabHeader'
import useTabHeader from 'components/analytics/helpers/useTabHeader'
import Overview from 'components/analytics/ai_answers/aiAnswersOverview'
import Answers from 'components/analytics/ai_answers/aiAnswers'
import AiAnswersInsights from 'components/analytics/ai_answers/aiAnswersInsights'
import ConversationsTable from 'components/analytics/ai_answers/conversationsTable'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { I18NCommon } from 'utils/i18nHelpers'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { useIsGlobalAnalytics } from 'pages/admin/global_analytics/globalAnalyticsProvider'
import classNames from 'classnames'

enum Tab {
  ANSWERS = 'answers',
  CHAT = 'chat',
}

type AnswersTabProps = {
  periodStart: string
  periodEnd?: string
  companyId?: string
}

const AnswersTab = ({
  periodStart,
  periodEnd,
  companyId,
}: AnswersTabProps) => {
  const isGlobal = useIsGlobalAnalytics()

  return (
    <>
      {!isGlobal && (
        <Overview
          periodStart={periodStart}
          periodEnd={periodEnd}
        />
      )}

      <Answers
        params={{
          period: { periodStart, periodEnd },
          companyId,
        }}
      />
    </>
  )
}

const AiAnswersTab = () => {
  const {
    periodStart,
    setPeriodStart,
    periodEnd,
    setPeriodEnd,
    timeRange,
    setTimeRange,
    companyId,
    setCompanyId,
  } = useTabHeader()

  const isGlobal = useIsGlobalAnalytics()

  const {
    answerTab,
  } = useQueryParams()

  const currentCompany = useCurrentCompany()
  const isChatbotEnabled = currentCompany.settings.aiChatbot?.enabled

  const tabs = [
    { text: I18NCommon('answers'), id: Tab.ANSWERS },
    { text: I18NCommon('chat'), id: Tab.CHAT },
  ]

  return (
    <main className={classNames('AiAnswersTab', { 'grid-extended': isGlobal })}>
      <div className='d-flex grid-span-12 justify-content-end align-items-center'>
        <TabHeader
          periodStart={periodStart}
          setPeriodStart={setPeriodStart}
          periodEnd={periodEnd}
          setPeriodEnd={setPeriodEnd}
          timeRange={timeRange}
          setTimeRange={setTimeRange}
          companyId={companyId}
          setCompanyId={setCompanyId}
        />
      </div>

      {!isGlobal && (
        <AiAnswersInsights
          startDate={periodStart}
          endDate={periodEnd}
        />
      )}

      {(isChatbotEnabled || isGlobal) ? (
        <>
          <HistoryChangingPillTabSelector
            paramName='answerTab'
            tabs={tabs}
            pillClasses='text-normal flex-shrink-0'
          />

          {answerTab === Tab.CHAT && (
            <ConversationsTable
              periodStart={periodStart}
              periodEnd={periodEnd}
              companyId={companyId}
            />
          )}

          {answerTab === Tab.ANSWERS && (
            <AnswersTab
              periodStart={periodStart}
              periodEnd={periodEnd}
              companyId={companyId}
            />
          )}
        </>
      ) : (
        <AnswersTab
          periodStart={periodStart}
          periodEnd={periodEnd}
          companyId={companyId}
        />
      )}
    </main>
  )
}

export default AiAnswersTab
