import React, { useEffect } from 'react'

import Card from 'components/common/card'
import TriggerOrDateSelect from 'components/admin/journeys/triggerOrDateSelect'
import FormCheck from 'components/form_fields/formCheck'
import AdvancedSettings from 'components/admin/journeys/advancedSettings'
import CalendarMeetingDetailsSection from 'components/admin/journeys/common/calendarMeetingDetailsSection'

import FromAndToCorrespondentSelector from 'components/admin/journeys/common/fromAndToCorrespondentSelector'
import CalendarEventDetailsTitleAndDescriptionSection from 'components/admin/journeys/common/calendarEventDetailsTitleAndDescriptionSection'

import {
  i18nPath
} from 'utils/i18nHelpers'

import StepTemplateFormButtonsContainer from 'components/admin/journeys/common/stepTemplateFormButtonsContainer'
import { Radio, RadioGroup } from 'react-radio-group'
import { STEP_COMPLETION } from 'components/admin/journeys/stepTemplateTriggerSelector'
import { defaultTriggerMilestone } from 'utils/journeys/templatesDefaultHelpers'
import CdnSvg from 'components/common/cdnSvg'
import TriggerScheduleSection from 'components/admin/journeys/common/triggerScheduleSection'
import WeekendTriggerOptions from 'components/admin/journeys/common/weekendTriggerOptions'

const I18N = i18nPath('views.admin.journeys.meeting_templates')
const I18NStepTemplates = i18nPath('views.admin.journeys.step_templates')


export const nonRequiredFields = [
  'addToAllInstances',
  'scheduleSpecificallyAfterNumDays',
  'rangeStartAfterNumDays',
  'rangeEndAfterNumDays',
  'scheduleWhenAttendeesAvailable',
  'createVideoConference',
  'weekendScheduleSpecificallyShift',
]

export const MeetingsHelpCard = ({
  journeyBlueprintType = 'onboarding',
}) => (
  <div className='MeetingsHelpCard px-5 d-flex justify-content-between mb-5'>
    <div className='d-flex flex-column py-3 w-50 justify-content-center mr-5'>
      <h3 className='text-white'>{I18N(`${journeyBlueprintType}.help_widget.title`)}</h3>
      <p className='text-white mb-0'>{I18N(`${journeyBlueprintType}.help_widget.description`)}</p>
    </div>
    <div className='confetti-background d-flex justify-content-center align-items-center w-50 py-2 position-relative'>
      <CdnSvg src='/images/meetingsImage.svg' className='MeetingImage' />
    </div>
  </div>
)

const SchedulingTriggerSection = ({
  workingCopy,
  updateWorkingCopy,
  isDisabled,
  isStepTemplate,
  journeyBlueprint,
}) => {
  const onChange = (value) => {
    updateWorkingCopy({
      scheduleWhenAttendeesAvailable: value,
    })
  }

  const isTriggerAfterStepCompletion = workingCopy?.triggerMilestone === STEP_COMPLETION
  const isAutomaticSchedulingSelected = workingCopy?.scheduleWhenAttendeesAvailable

  useEffect(() => {
    if (isStepTemplate) {
      const {
        rangeStartMilestoneInitialState,
        rangeEndMilestoneInitialState,
        scheduleSpecificallyMilestoneInitialState: sSMInitialState,
      } = workingCopy

      // when updating back from step completion have to set to initial state for these values
      // otherwise it loses the saved values and goes back to default values
      // which is also a problem when loading the page due to the nature of useEffect
      updateWorkingCopy({
        rangeStartMilestone: isTriggerAfterStepCompletion ? STEP_COMPLETION : rangeStartMilestoneInitialState,
        rangeEndMilestone: isTriggerAfterStepCompletion ? STEP_COMPLETION : rangeEndMilestoneInitialState,
        scheduleSpecificallyMilestone: isTriggerAfterStepCompletion ? STEP_COMPLETION : sSMInitialState,
      })
    }
  }, [isTriggerAfterStepCompletion])

  return (
    <>
      <RadioGroup
        className='SchedulingTriggerSection d-flex'
        name='scheduleWhenAttendeesAvailable'
        selectedValue={isAutomaticSchedulingSelected}
        onChange={onChange}
      >
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <Radio className='mx-2' value={true} disabled={isDisabled} />
            {I18N('automatically_schedule_calendar_invites')}
          </div>
          {isAutomaticSchedulingSelected && (
            <div className='ml-4'>
              <p className='text-small text-secondary'>{I18N('automatically_schedule_calendar_invites_footnote')}</p>
              <TriggerOrDateSelect
                workingCopy={workingCopy}
                updateWorkingCopy={updateWorkingCopy}
                label={I18NStepTemplates('trigger')}
                isDisabled={isDisabled}
                useTriggerSelect={isStepTemplate}
                showTriggerAfterCompletionSelection={false}
                triggers={[
                  { name: 'rangeStart', label: I18N('automatically_schedule_when_start') },
                  { name: 'rangeEnd', label: I18N('automatically_schedule_when_lastest') },
                ]}
                journeyBlueprint={journeyBlueprint}
                showTimezoneDropdown={false}
              />
            </div>
          )}
          <div className='d-flex align-items-center'>
            <Radio className='mx-2' value={false} disabled={isDisabled} />
            {I18N('schedule_at_specific_time')}
          </div>
          {!isAutomaticSchedulingSelected && (
            <div className='ml-4 mt-2'>
              <TriggerOrDateSelect
                workingCopy={workingCopy}
                updateWorkingCopy={updateWorkingCopy}
                label={I18NStepTemplates('trigger')}
                isDisabled={isDisabled}
                useTriggerSelect={isStepTemplate}
                showTriggerAfterCompletionSelection={false}
                triggers={[
                  { name: 'scheduleSpecifically', label: null },
                ]}
                journeyBlueprint={journeyBlueprint}
                showTimezoneDropdown={false}
              />

              {isStepTemplate && (
                <WeekendTriggerOptions
                  workingCopy={workingCopy}
                  updateWorkingCopy={updateWorkingCopy}
                  fieldName='weekendScheduleSpecificallyShift'
                />
              )}
            </div>
          )}
        </div>
      </RadioGroup>
    </>
  )
}

const MeetingForm = ({
  onSave,
  workingCopy,
  updateWorkingCopy,
  currentState = 'draft',
  isStepTemplate = true,
  areChangesPresent = true,
  isDisabled = false,
  forUser,
  journeyBlueprint,
  helpCard = <></>,
  isSaving = false,
}) => {
  const journeyBlueprintType = journeyBlueprint?.type
  const isAutomaticSchedulingSelected = workingCopy?.scheduleWhenAttendeesAvailable
  const triggerOnStepCompletion = workingCopy?.triggerMilestone === 'step_completion'

  return (
    <div className='MeetingForm'>
      {helpCard}
      <div className='font-weight-600 text-secondary mb-2'>{I18N('calendar_event_details')}</div>
      <Card className='mb-4'>
        <FromAndToCorrespondentSelector
          workingCopy={workingCopy}
          updateWorkingCopy={updateWorkingCopy}
          isDisabled={isDisabled}
          journeyBlueprint={journeyBlueprint}
          showCompanyCorrespondents={false}
          fromCorrespondentLabel={I18N('organizer')}
          toCorrespondentLabel={I18N('recipient')}
        />
        <CalendarEventDetailsTitleAndDescriptionSection
          workingCopy={workingCopy}
          updateWorkingCopy={updateWorkingCopy}
          journeyBlueprint={journeyBlueprint}
          isDisabled={isDisabled}
          scheduleMeetingsAutomatically
          isIntroductionTemplate={false}
        />
        <SchedulingTriggerSection
          workingCopy={workingCopy}
          updateWorkingCopy={updateWorkingCopy}
          isDisabled={isDisabled}
          isStepTemplate={isStepTemplate}
          journeyBlueprint={journeyBlueprint}
        />
      </Card>

      <TriggerScheduleSection
        workingCopy={workingCopy}
        updateWorkingCopy={updateWorkingCopy}
        isDisabled={isDisabled}
        isStepTemplate={isStepTemplate}
        journeyBlueprint={journeyBlueprint}
      />

      <CalendarMeetingDetailsSection
        cardTitle={I18N('calendar_meeting_configurations')}
        workingCopy={workingCopy}
        updateWorkingCopy={updateWorkingCopy}
        isDisabled={isDisabled}
        journeyBlueprint={journeyBlueprint}
        scheduleMeetingsAutomatically
        isIntroductionTemplate={false}
      />
      <AdvancedSettings
        className='mt-4'
        workingCopy={workingCopy}
        updateWorkingCopy={updateWorkingCopy}
        isStepTemplate={isStepTemplate}
        journeyBlueprintType={journeyBlueprint?.type}
      >
        <label className='text-secondary font-weight-600 mb-3'>{I18NStepTemplates('error_handling')}</label>
        <>
          {isStepTemplate && journeyBlueprintType && !triggerOnStepCompletion && (
            <FormCheck
              name='create_after_trigger_period'
              label={I18N(`advanced_settings.${journeyBlueprintType}.create_after_trigger_period`)}
              checked={workingCopy.triggerImmediatelyIfPastTrigger}
              onChange={({ target }) => updateWorkingCopy({ triggerImmediatelyIfPastTrigger: target.checked })}
              id='create_after_trigger_period'
              className='mb-3'
            />
          )}
        </>
        <FormCheck
          name='variables_missing'
          label={I18N('advanced_settings.trigger_if_variables_missing')}
          checked={!workingCopy.triggerIfVariablesMissing}
          onChange={({ target }) => updateWorkingCopy({ triggerIfVariablesMissing: !target.checked })}
          id='variables_missing'
          className='mb-3'
          disabled={isDisabled}
        />
      </AdvancedSettings>
      <StepTemplateFormButtonsContainer
        onSave={onSave}
        isSaving={isSaving}
        workingCopy={workingCopy}
        currentState={currentState}
        isStepTemplate={isStepTemplate}
        areChangesPresent={areChangesPresent}
        isDisabled={isDisabled}
        forUser={forUser}
        nonRequiredFields={nonRequiredFields}
        stepType='meeting'
        postTriggerNames={
          isAutomaticSchedulingSelected
            ? ['rangeStart', 'rangeEnd'] : ['scheduleSpecifically']
        }
      />
    </div>
  )
}

export default MeetingForm
