import useApi from 'components/common/hooks/useApi'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import API from 'services/api'
import useFetch from 'components/common/hooks/useFetch'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import CdnSvg from 'components/common/cdnSvg'
import FullPageEditor from 'components/common/fullPageEditor'
import EditorNavbar from 'components/common/editorNavbar'
import ExitButton from 'components/common/exitButton'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { ButtonNarrow } from 'components/common/buttons'
import Sidebar from 'pages/admin/workflows/workflowEditPage/sidebar'
import { Workflow } from 'services/api/admin/workflows'
import Actions from 'pages/admin/workflows/workflowEditPage/actions'

const I18N = i18nPath('views.admin.workflows.edit_page')

const WorkflowEditPage = () => {
  const { workflowId } = useParams()
  const [workflowWorkingCopy, setWorkflowWorkingCopy] = useState<Partial<Workflow>>({})

  const {
    data: workflow,
    isLoading,
    isLoaded,
    callApi: fetchWorkflow,
  } = useFetch(API.admin.workflows.fetch, [workflowId])

  const [updateWorkflow, { isLoading: isUpdatingWorkflow }] = useApi(API.admin.workflows.update, {
    toastSuccessMessage: I18N('updated'),
  })

  const handleWorkflowSave = () => {
    updateWorkflow({ id: workflowId, ...workflowWorkingCopy })
  }

  useEffect(() => {
    if (!workflow) return

    setWorkflowWorkingCopy(workflow)
  }, [workflow])

  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div className='WorkflowEditPage'>
      <FullPageEditor
        header={(
          <>
            <EditorNavbar
              left={(
                <ExitButton
                  url='/admin/workflows'
                  className='ExitButton'
                />
              )}
              title={_.isEmpty(workflow.name) ? I18N('title') : workflow.name}
              right={(
                <ButtonNarrow showLoadingSpinner={isUpdatingWorkflow} onClick={handleWorkflowSave}>
                  <CdnSvg src='/images/diskIcon.svg' className='mr-2 saveIcon' />
                  {I18NCommon('save')}
                </ButtonNarrow>
              )}
            />
          </>
        )}
        sidebar={(
          <Sidebar
            workingCopy={workflowWorkingCopy}
            setWorkingCopy={setWorkflowWorkingCopy}
          />
        )}
        sidebarPosition='right'
      >
        <>
          {isLoading && !isLoaded ? (
            <CirclesLoadingIndicator />
          ) : (
            <>
              <div className='WorkflowEditPage__react-flow-wrapper position-relative'>
                <Actions workflow={workflow} fetchWorkflow={fetchWorkflow} />

                {workflow.draft && (
                  <div className='position-absolute top-0 left-0 w-100 p-2'>
                    <div className='WorkflowEditPage__draft-warning d-flex align-items-center'>
                      <CdnSvg src='/images/infoIcon.svg' className='mr-2' />
                      {I18N('draft')}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      </FullPageEditor>
    </div>
  )
}

export default WorkflowEditPage
