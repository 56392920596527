import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import arrayMove from 'array-move'

import useApi from 'components/common/hooks/useApi'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import { ArticleTypeType } from 'types/article/articleType'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import SortableTable from 'components/common/tables/sortableTable'
import { ButtonLink } from 'components/common/buttons'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import ButtonWithIcon from 'components/common/buttons/buttonWithIcon'

const I18N = i18nPath('views.admin.article_settings.article_type_settings')

const getColumns = (groupDisplayName: string) => [
  {
    header: I18N('name'),
    accessor: articleType => (
      <Link to={`/admin/article_types/${articleType.id}`}>
        <span>{articleType.name}</span>
      </Link>
    ),
    style: { width: '25%' },
  },
  {
    header: I18NCommon('active'),
    accessor: articleType => I18NCommon(articleType.active ? 'yes' : 'no'),
    style: { width: '15%' },
  },
  {
    header: I18N('group_article_type', { groupDisplayName }),
    accessor: articleType => I18NCommon(articleType.groupArticleType ? 'yes' : 'no'),
    style: { width: '15%' },
  },
  {
    header: I18N('description'),
    accessor: articleType => articleType.description,
    style: { width: '20%' },
  },
  {
    header: I18N('image'),
    accessor: articleType => <img style={{ height: '45px' }} src={articleType.imageUrl} />,
    style: { width: '10%' },
  },
  {
    header: I18N('created'),
    accessor: articleType => i18nMoment(articleType.createdAt).format('ll'),
    style: { width: '15%' },
  },
]

const ArticleTypeSettings = () => {
  const { groupDisplayName } = useCurrentCompany()
  const [articleTypes, setArticleTypes] = useState<ArticleTypeType[]>([])
  const { isLoading } = useFetch(() => API.admin.articleTypes.fetchAll(), [], {
    onSuccess: response => setArticleTypes(response),
  })

  const [updateArticleType, { isLoading: isSaving }] = useApi(API.admin.articleTypes.update)

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setArticleTypes(arrayMove(articleTypes, oldIndex, newIndex))

    const newArticleType = { ...articleTypes[oldIndex], orderPosition: newIndex }
    updateArticleType(newArticleType)
  }

  if (isLoading) {
    return <section><CirclesLoadingIndicator /></section>
  }

  return (
    <>
      <div className='d-flex'>
        <ButtonLink
          to='/admin/article_types/new'
          Component={ButtonWithIcon}
          className='mb-4'
        >
          {I18N('new_article_type')}
        </ButtonLink>
      </div>

      <SortableTable
        data={articleTypes}
        columns={getColumns(groupDisplayName)}
        onSortEnd={onSortEnd}
        isSaving={isSaving}
      />
    </>
  )
}

export default ArticleTypeSettings
