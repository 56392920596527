import { useAskAiContext } from 'components/ai/chat/ask_ai_modal/askAiContext'
import { askAiI18n } from 'components/ai/chat/ask_ai_modal/askAiUtils'
import { NewConversationOrigin } from 'components/ai/chat/ask_ai_modal/types'
import { ButtonSecondary } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import React from 'react'

const AskAiNavbarButton = () => {
  const { setShowAskAiModal, newConversationTrackingRef } = useAskAiContext()

  return (
    <ButtonSecondary
      onClick={() => {
        newConversationTrackingRef.current = {
          origin: NewConversationOrigin.ASK_AI_BUTTON,
        }
        setShowAskAiModal(true)
      }}
      className='AskAiNavbarButton border-color-ai ml-3 flex-shrink-0'
    >
      <span className='d-inline-block px-3'>
        <CdnSvg className='mr-1' src='/images/illustrations/aiGradientIcon.svg' />
        <span className='text-ai flex-shrink-0'>{askAiI18n('ask_ai')}</span>
      </span>
    </ButtonSecondary>
  )
}

export default AskAiNavbarButton
