/* eslint-disable import/prefer-default-export */

import { NewConversationOrigin } from 'components/ai/chat/ask_ai_modal/types'
import { useHistory } from 'react-router-dom'

interface CreateNewChatConversationMessageProps {
  message: string
  origin: NewConversationOrigin
  query?: string
}

export const useCreateNewChatConversationMessage = () => {
  const history = useHistory()

  const createNewChatConversationMessage = ({ message, origin, query }: CreateNewChatConversationMessageProps) => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('newChatConversationMessage', message)
    searchParams.set('origin', origin)
    if (query) {
      searchParams.set('searchQuery', query)
    }
    history.push(`${window.location.pathname}?${searchParams.toString()}`)
  }

  return createNewChatConversationMessage
}
