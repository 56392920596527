import { ButtonSmallNarrow } from 'components/common/buttons'
import ClearyCard from 'components/common/card'
import useApi from 'components/common/hooks/useApi'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'
import useSSE from 'components/common/hooks/useSSE'
import AiAnswerContent from 'components/search/ai/aiAnswerContent'
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showToastMessage } from 'redux/slices/toasts'
import API from 'services/api'

const formatJSON = (jsonString: string) => {
  try {
    return JSON.stringify(JSON.parse(jsonString), null, 2)
  } catch {
    return jsonString
  }
}

type MessageType = {
  role: string
  content: string
}

const MessageEditor = ({ value, onChange }: { value: MessageType, onChange: (value: MessageType) => void }) => (
  <div>
    <div>Role: {value.role}</div>
    <textarea
      className='w-100 p-2'
      value={value.content}
      onChange={e => onChange({ ...value, content: e.target.value })}
      rows={10}
    />
  </div>
)

const LlmCall = ({ call }: { call: string }) => {
  const [value, setValue] = useState(call)

  const formattedValue = useMemo(() => formatJSON(value), [value])
  const parsedValue = useMemo(() => JSON.parse(formattedValue), [formattedValue])
  const [response, setResponse] = useState(parsedValue.response)

  const [callLLM, { isLoading }] = useApi(API.admin.ai.callLLM, {
    onSuccess(data) {
      if (typeof data === 'object') {
        setResponse(JSON.stringify(data, null, 2))
      } else {
        setResponse(data)
      }
    },
  })

  const onCallLLM = () => {
    callLLM({ args: value })
  }

  const messages = JSON.parse(value).messages

  const setMessage = (index: number, message: MessageType) => {
    setValue((prev) => {
      const parsed = JSON.parse(prev)
      parsed.messages[index] = message
      return JSON.stringify(parsed)
    })
  }

  const renderResponse = () => {
    if (Array.isArray(response)) {
      return <pre>{formatJSON(JSON.stringify(response))}</pre>
    }

    if (parsedValue.response_format?.type === 'json_schema') {
      return <pre>{response}</pre>
    }

    return <AiAnswerContent content={response} />
  }

  return (
    <ClearyCard className='mb-4'>
      <div>
        {messages?.length && (
          <div>
            Messages:

            {messages.map((message, index) => (
              <MessageEditor
                key={index}
                value={message}
                onChange={message => setMessage(index, message)}
              />
            ))}
          </div>
        )}

        <div>
          Final payload:
          <textarea
            className='w-100 p-2'
            value={formattedValue}
            onChange={e => setValue(e.target.value)}
            rows={10}
          />
        </div>

        <ButtonSmallNarrow onClick={onCallLLM} showLoadingSpinner={isLoading} className='mt-4'>
          Call LLM
        </ButtonSmallNarrow>

        <div className='mt-4'>
          Response:

          {renderResponse()}
        </div>
      </div>
    </ClearyCard>
  )
}

const LlmCallOrLog = ({ call }: { call: string }) => {
  const parsedJson = useMemo(() => JSON.parse(call), [call])

  if (parsedJson.method) {
    return <LlmCall call={call} />
  }

  return <pre>{formatJSON(call)}</pre>
}

const RunSearch = ({
  query,
  userId,
  useExperimentalAiAssistant,
  setContent,
  setLlmCalls,
  setIsLoading,
}: {
  query: string
  userId: string
  useExperimentalAiAssistant: boolean
  setContent: React.Dispatch<React.SetStateAction<string>>
  setLlmCalls: React.Dispatch<React.SetStateAction<string[]>>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const dispatch = useDispatch()

  useSSE('/api/admin/ai/assistant/llm/run_search', {
    options: {
      headers: { 'Content-Type': 'application/json', 'X-CSRF-TOKEN': API.csrfToken() },
      method: 'POST',
      payload: JSON.stringify({ query, user_id: userId, use_experimental_ai_assistant: useExperimentalAiAssistant }),
    },
    listeners: {
      'delta-received': (event) => {
        setContent(prev => prev + event.data)
      },
      'llm-call': (event) => {
        setLlmCalls(prev => [...prev, event.data])
      },
      'workflow-finished': (event) => {
        const data = JSON.parse(event.data)
        setContent(data.message)
        setIsLoading(false)
      },
      'error': (event) => {
        dispatch(showToastMessage({ message: 'Error. See console for details.', type: 'error' }))
        console.error(event)
      },
    },
    listen: !!query,
  })

  return null
}

const AiAnswerGeneratorPage = () => {
  const currentUser = useCurrentUser()

  const [query, setQuery] = useQueryParamState({
    param: 'query',
    initialValue: '',
  })

  const [userId, setUserId] = useQueryParamState({
    param: 'userId',
    initialValue: currentUser?.id,
  })

  const [useExperimentalAiAssistant, setUseExperimentalAiAssistant] = useQueryParamState({
    param: 'useExperimentalAiAssistant',
    initialValue: '0',
  })

  const [runningCount, setRunningCount] = useState(0)

  const [content, setContent] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [llmCalls, setLlmCalls] = useState<string[]>([])

  return (
    <div>
      <h1>AI Answer Generator {isLoading ? 'Loading...' : ''}</h1>
      <p>
        Query:
        <input
          value={query}
          onChange={e => setQuery(e.target.value)}
        />
      </p>
      <p>
        User ID:
        <input
          value={userId}
          onChange={e => setUserId(e.target.value)}
        />
      </p>

      <p>
        Use Experimental AI Assistant:
        <input
          type='checkbox'
          checked={useExperimentalAiAssistant === '1'}
          onChange={e => setUseExperimentalAiAssistant(useExperimentalAiAssistant === '1' ? '0' : '1')}
        />
      </p>

      <ButtonSmallNarrow
        onClick={() => {
          setContent('')
          setLlmCalls([])
          setIsLoading(true)
          setRunningCount(prev => prev + 1)
        }}
      >
        Run Search
      </ButtonSmallNarrow>

      <RunSearch
        key={runningCount}
        query={query}
        userId={userId}
        useExperimentalAiAssistant={useExperimentalAiAssistant === '1'}
        setContent={setContent}
        setLlmCalls={setLlmCalls}
        setIsLoading={setIsLoading}
      />

      <div className='my-4'>
        Generated Response: <br />
        {content && <AiAnswerContent content={content} />}
      </div>
      <div className='space-y-4'>
        <h2 className='text-lg font-semibold'>LLM Calls:</h2>
        {llmCalls.map((call, index) => (
          <LlmCallOrLog call={call} key={index} />
        ))}
      </div>
    </div>
  )
}

export default AiAnswerGeneratorPage
