import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import useApi from 'components/common/hooks/useApi'
import AppModal from 'components/common/modal'
import CancelButton from 'components/common/cancelButton'
import { ButtonNarrow } from 'components/common/buttons'
import { Workflow } from 'services/api/admin/workflows'

const I18N = i18nPath('views.admin.workflows.add_workflow_modal')

interface AddWorkflowModalProps {
  onCancel: () => void,
}

const AddWorkflowModal = ({ onCancel }: AddWorkflowModalProps) => {
  const history = useHistory()
  const [workingCopy, setWorkingCopy] = useState<Partial<Workflow>>({ name: '', description: '' })
  const [createWorkflow, { isLoading: isSaving }] = useApi(
    API.admin.workflows.create,
    { onSuccess: data => history.push(`${data.displayPath}/edit`) }
  )

  const connect = (field: string) => ({
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setWorkingCopy({
      ...workingCopy,
      [field]: e.target.value,
    }),
    value: workingCopy[field],
  })

  const handleSave = () => {
    createWorkflow(workingCopy)
  }

  return (
    <AppModal
      visible
      modalContentClassName='container d-flex flex-column w-100'
    >
      <>
        <div className='d-flex flex-column align-items-start justify-content-between mb-3'>
          <h3 className='mb-0'>{I18N('title')}</h3>
        </div>
        <section className='mb-3'>
          <label className='text-smallest text-secondary'>{I18N('name')}</label>
          <input type='text' className='w-100' {...connect('name')} />
        </section>

        <section className='mb-3'>
          <label className='text-smallest text-secondary'>{I18N('description')}</label>
          <textarea className='w-100' {...connect('description')} />
        </section>

        <div className='d-flex justify-content-end align-items-center'>
          <CancelButton onClick={onCancel} />
          <ButtonNarrow
            onClick={handleSave}
            disabled={isSaving}
            showLoadingSpinner={isSaving}
          >
            {I18NCommon('save')}
          </ButtonNarrow>
        </div>
      </>
    </AppModal>
  )
}

export default AddWorkflowModal
