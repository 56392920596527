import React from 'react'
import { Link } from 'react-router-dom'

import API from 'services/api'
import SmartTable from 'components/common/tables/smartTable'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import useFetch from 'components/common/hooks/useFetch'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { present } from 'components/common/utils'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { UserBillingAccessLevelSnapshotType } from 'types/billing/userBillingAccessLevelSnapshot'

const I18N = i18nPath('views.billing.list_page')

const BillingListPage = () => {
  const currentCompany = useCurrentCompany()
  const isSoftLaunchEnabled = currentCompany.settings.softLaunchWhitelist.enabled

  const {
    isLoading, data: snapshots, paginationData, callApi: fetchSnapshots,
  } = useFetch<UserBillingAccessLevelSnapshotType[]>(API.admin.userBillingAccessLevelSnapshots.fetchAll)

  const columns = [
    {
      header: I18N('created_at'),
      accessor: (snapshot: UserBillingAccessLevelSnapshotType) => i18nMoment(snapshot.snapshotDate).format('MMM D, YYYY HH:mm'),
    },
    {
      header: I18N('full_platform'),
      accessor: (snapshot: UserBillingAccessLevelSnapshotType) => snapshot.fullPlatformCount,
    },
    {
      header: I18N('onboarding_only'),
      accessor: (snapshot: UserBillingAccessLevelSnapshotType) => snapshot.onboardingOnlyCount,
    },
    isSoftLaunchEnabled && {
      header: I18N('no_access_visible'),
      accessor: (snapshot: UserBillingAccessLevelSnapshotType) => snapshot.noAccessVisibleCount,
    },
    {
      accessor: (snapshot: UserBillingAccessLevelSnapshotType) => (
        <Link to={`/admin/billing/${snapshot.id}`}>
          {I18N('view_details')}
        </Link>
      ),
    },
  ].filter(present)

  return (
    <div className='BillingListPage'>
      <header className='AdminHeader'>
        <h3>{I18N('title')}</h3>
      </header>

      <main className='AdminContent'>
        {isLoading ? (
          <CirclesLoadingIndicator />
        ) : (
          <SmartTable
            columns={columns}
            data={snapshots}
            className='white-bg-table'
            showPagination
            page={paginationData.page}
            pages={paginationData.totalPages}
            totalCount={paginationData.total}
            perPage={paginationData.perPage}
            onPaginationClick={data => fetchSnapshots(data)}
          />
        )}
      </main>
    </div>
  )
}

export default BillingListPage
