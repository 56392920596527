import AiAnswersTab from 'components/analytics/ai_answers/aiAnswersTab'
import { GlobalAnalyticsProvider } from 'pages/admin/global_analytics/globalAnalyticsProvider'
import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.global_analytics')

const GlobalAnalyticsPage = () => (
  <main className='PlatformAnalyticsPage grid-extended'>
    <header className='my-4'>
      <div className='d-flex align-items-center mb-2'>
        <h2 className='mb-0'>
          {I18N('title')}
        </h2>
      </div>
    </header>

    <GlobalAnalyticsProvider isGlobal>
      <AiAnswersTab />
    </GlobalAnalyticsProvider>
  </main>
)

export default GlobalAnalyticsPage
