import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { present } from 'components/common/utils'
import { i18nPath } from 'utils/i18nHelpers'

export interface LeftNavItemType {
  label?: string
  icon: string
  to: string
  exact?: boolean
  activePaths?: string[]
  subItems?: LeftNavSubItem[]
}

interface LeftNavSubItem {
  label: string
  to: string
}

const I18N = i18nPath('views.navbar')

const createLeftNavItems = (
  pageDisplayName: string,
  pluralGroupDisplayName: string,
  eventDisplayName: string,
  settings
) => ({
  home: {
    label: I18N('home'),
    icon: '/images/navbar/homeIcon.svg',
    to: '/',
    exact: true,
  },
  people: {
    label: I18N('people'),
    icon: '/images/navbar/peopleIcon.svg',
    to: '/people',
    subItems: [
      settings.orgChart.enabled && { label: I18N('org_chart'), to: '/people/orgchart' },
      settings.groups.enabled && { label: pluralGroupDisplayName, to: '/people/teams' }, // pluralGroupDisplayName is always present, it has a default in the serializer
      settings.shoutouts.enabled && { label: I18N('shoutouts'), to: '/people/shoutouts' },
      settings.celebrations.enabled && { label: I18N('celebrations'), to: '/people/celebrations' },
      settings.celebrations.newEmployees.enabled && { label: I18N('new_employees'), to: '/people/new_employees' },
      settings.celebrations.workAnniversaries.enabled && { label: I18N('work_anniversaries'), to: '/people/work_anniversaries' },
      settings.celebrations.birthdays.enabled && { label: I18N('birthdays'), to: '/people/birthdays' },
      settings.badges.enabled && { label: I18N('badges'), to: '/people/badges' },
    ].filter(present),
  },
  knowledgeBase: {
    label: I18N('knowledge_base'),
    icon: '/images/navbar/knowledgeBaseIcon.svg',
    to: '/pages',
    activePaths: ['/pages', '/news', '/golinks'],
    subItems: [
      settings.pages.enabled && { label: pageDisplayName || I18N('wiki'), to: '/pages' },
      settings.news.enabled && { label: I18N('news'), to: '/news' },
      settings.golinks.enabled && { label: I18N('golinks'), to: '/golinks' },
    ].filter(present),
  },
  events: {
    label: eventDisplayName || I18N('events'),
    icon: '/images/navbar/eventsIcon.svg',
    to: '/events',
  },
})

const useLeftNavItems = () => {
  const currentUser = useCurrentUser()
  const {
    settings,
    eventDisplayName,
    pageDisplayName,
    pluralGroupDisplayName,
  } = useCurrentCompany()

  const leftNavItemsMap = createLeftNavItems(
    pageDisplayName,
    pluralGroupDisplayName,
    eventDisplayName,
    settings
  )

  const inPreboardingExperience = currentUser.inPreboardingExperience
  // We only add home nav link to users in preboarding experience
  if (inPreboardingExperience) return settings.feed.enabled ? [leftNavItemsMap.home] : []

  const navItems: LeftNavItemType[] = [
    settings.feed.enabled && leftNavItemsMap.home,
    settings.people.enabled && leftNavItemsMap.people.subItems?.length > 0 && leftNavItemsMap.people,
    leftNavItemsMap.knowledgeBase.subItems?.length > 0 && leftNavItemsMap.knowledgeBase,
    settings.qna.enabled && leftNavItemsMap.events,
  ].filter(present)

  return navItems
}

export default useLeftNavItems
