import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { SIMPLE_EDITOR_CONFIGURATION } from 'components/common/tiptap/configurations'
import { SMALL_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'
import { Button } from 'components/common/buttons'
import ReactSelect from 'components/common/react_select'

const ClearyRichTextInput = ({ onChange, value }) => (
  <TiptapEditor
    configuration={{
      ...SIMPLE_EDITOR_CONFIGURATION,
      imagesEnabled: true,
      placeholder: 'The PDF template text. You can use double curly braces to insert variables. For example, {{variable_name}}',
    }}
    toolbarItems={SMALL_TOOLBAR}
    onChange={onChange}
    html={value}
  />
)

const TextInput = ({ onChange, value }) => (
  <input type='text' onChange={e => onChange(e.target.value)} value={value} />
)

const TextareaInput = ({ onChange, value }) => (
  <textarea onChange={e => onChange(e.target.value)} value={value} />
)

const FileInput = ({ onChange }) => (
  <input type='file' onChange={e => onChange(e.target.files?.[0])} />
)

const componentMap = {
  cleary_rich_text: ClearyRichTextInput,
  text: TextInput,
  textarea: TextareaInput,
  file: FileInput,
}

const ActionForm = ({ action, isLoading, onSave }) => {
  const [settings, setSettings] = useState(action.settings)
  const [configuration, setConfiguration] = useState(action.configuration)
  const [activeKey, setActiveKey] = useState<string | undefined>('settings')
  const [configurationSetup, setConfigurationSetup] = useState({})

  useEffect(() => {
    const newConfigurationSetup = Object.keys(action.configuration).reduce((acc, key) => {
      acc[key] = typeof action.configuration[key] === 'object'

      return acc
    }, {})

    setConfigurationSetup(newConfigurationSetup)
  }, [action])

  return (
    <div>
      <h1>{action.name}</h1>

      <Tabs
        activeKey={activeKey}
        className='mb-3'
        onSelect={key => setActiveKey(key as string)}
      >
        <Tab
          eventKey='settings'
          title='Settings'
        >
          {Object.keys(action.settingsMetadata).map((key) => {
            const Component = componentMap[action.settingsMetadata[key].type]

            return (
              <>
                <p>{key}</p>
                <Component
                  value={settings[key]}
                  onChange={value => setSettings({ ...settings, [key]: value })}
                />
              </>
            )
          })}

          <Button
            onClick={() => onSave(settings, configuration)}
            disabled={isLoading}
            showLoadingSpinner={isLoading}
            className='mt-3'
          >
            Save settings
          </Button>
        </Tab>

        {!_.isEmpty(action.configurationMetadata) && (
          <Tab
            eventKey='configuration'
            title='Configuration'
          >
            {Object.keys(action.configurationMetadata).map((key) => {
              const Component = componentMap[action.configurationMetadata[key].type]
              const options = action
                .availableConfigurationInputs
                .filter(input => input.outputMetadata.type === action.configurationMetadata[key].type)

              return (
                <>
                  <p>{key}</p>
                  {configurationSetup[key] ? (
                    <ReactSelect
                      getOptionLabel={option => (
                        <>
                          {option.outputKey}
                          <span className='ml-2 text-secondary text-small'>{option.name}</span>
                        </>
                      )}
                      options={options}
                      value={configuration[key]}
                      onChange={value => setConfiguration({ ...configuration, [key]: value })}
                    />
                  ) : (
                    <Component
                      value={configuration[key]}
                      onChange={value => setConfiguration({ ...configuration, [key]: value })}
                    />
                  )}
                  <input
                    type='checkbox'
                    onChange={(e) => {
                      setConfigurationSetup({ ...configurationSetup, [key]: e.target.checked })
                      setConfiguration({ ...configuration, [key]: undefined })
                    }}
                    checked={configurationSetup[key]}
                  />
                </>
              )
            })}

            <Button
              onClick={() => onSave(settings, configuration)}
              disabled={isLoading}
              showLoadingSpinner={isLoading}
              className='mt-3'
            >
              Save configuration
            </Button>
          </Tab>
        )}
      </Tabs>
    </div>
  )
}

export default ActionForm
