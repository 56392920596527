import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.workflows.triggerable_types')

const TRIGGERABLE_TYPES = {
  'Ticketing::Ticket': {
    icon: '/images/ticket.svg',
    label: I18N('ticket'),
  },
}

const TriggerableLabel = ({ triggerableType }) => (
  <>
    <CdnSvg src={TRIGGERABLE_TYPES[triggerableType]?.icon} className='mr-2' />
    <span className='text-small'>{TRIGGERABLE_TYPES[triggerableType]?.label}</span>
  </>
)

export default TriggerableLabel
