import { AiMessageWrapper } from 'components/ai/chat/ask_ai_modal/aiMessage'
import { useAskAiContext } from 'components/ai/chat/ask_ai_modal/askAiContext'
import { askAiI18n } from 'components/ai/chat/ask_ai_modal/askAiUtils'
import GeneratingAiMessage from 'components/ai/chat/ask_ai_modal/generatingAiMessage'
import { ButtonSecondary } from 'components/common/buttons'
import React from 'react'

const AiGreetingMessage = () => {
  const {
    myPrompts,
    onNewMessage,
    isLoadingMyPrompts,
    isCreatingMessage,
    isCreatingConversation,
    isLoadingMessages,
    messages,
    newConversationTrackingRef,
  } = useAskAiContext()

  const showGreetingMessage = messages.length === 0
    && !isLoadingMessages
    && !isCreatingMessage
    && !isCreatingConversation

  if (!showGreetingMessage) return null
  if (isLoadingMyPrompts) return <GeneratingAiMessage />

  const prompts = (
    <div className='mt-3'>
      {myPrompts?.map((prompt, index) => (
        <ButtonSecondary
          key={`${prompt}-${index}`}
          className='mr-3 mb-3 AiGreetingMessage-button text-small'
          onClick={() => {
            if (newConversationTrackingRef.current) {
              newConversationTrackingRef.current.prompt = prompt
            }

            onNewMessage({
              content: prompt,
            })
          }}
        >
          {prompt}
        </ButtonSecondary>
      ))}
    </div>
  )

  return (
    <AiMessageWrapper footer={prompts} aiBorder={false}>
      <p className='mb-0'>
        {myPrompts?.length
          ? askAiI18n('greeting_options')
          : askAiI18n('greeting_without_options')}
      </p>
    </AiMessageWrapper>
  )
}

export default AiGreetingMessage
