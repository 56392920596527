import React, { useState } from 'react'
import AppModal from 'components/common/modal'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import ReactSelect from 'components/common/react_select'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useFetch from 'components/common/hooks/useFetch'
import CancelButton from 'components/common/cancelButton'
import { ButtonNarrow } from 'components/common/buttons'

const CreateActionModal = ({ workflowId, onCancel, onCreate }) => {
  const [actionType, setActionType] = useState<any>(null)

  const { data: availableActions, isLoading, isLoaded } = useFetch(
    API.admin.workflows.availableActions.fetchAll
  )

  const [createAction, { isLoading: isCreatingAction }] = useApi(
    API.admin.workflows.actions.create,
    {
      onSuccess: onCreate,
    }
  )

  const handleSelectActionType = (actionType) => {
    setActionType(actionType.value)
  }

  const handleSave = () => {
    createAction(workflowId, { type: actionType })
  }

  return (
    <AppModal
      visible
      modalContentClassName='container d-flex flex-column w-100'
    >
      <>
        <div className='d-flex flex-column align-items-start justify-content-between mb-3'>
          <h3 className='mb-0'>Choose an action</h3>
        </div>
        <section className='mb-5'>
          <label className='text-smallest text-secondary'>Action type</label>
          {isLoading || !isLoaded ? (
            <CirclesLoadingIndicator />
          ) : (
            <ReactSelect
              options={availableActions?.map(action => ({ label: action.name, value: action.id }))}
              onChange={handleSelectActionType}
            />
          )}
        </section>

        <div className='d-flex justify-content-end align-items-center'>
          <CancelButton onClick={onCancel} />
          <ButtonNarrow
            onClick={handleSave}
            disabled={isCreatingAction}
            showLoadingSpinner={isCreatingAction}
          >
            Save
          </ButtonNarrow>
        </div>
      </>
    </AppModal>
  )
}

export default CreateActionModal
