import AssigneeSelect from 'components/admin/ticketing/assigneeSelect'
import HoverAndClickPopover from 'components/common/hoverAndClickPopover'
import UserOrUnassigned from 'components/ticketing/userOrUnassigned'
import React from 'react'
import { TicketType } from 'types/ticketing/ticket'

type Props = {
  ticket: TicketType
}

const AssignTicketPopover = ({ ticket }: Props) => (
  <div className='AssignTicketPopover' onClick={e => e.stopPropagation()}>
    <HoverAndClickPopover
      target={<UserOrUnassigned user={ticket.assignee} />}
      popoverClassName='AssignTicketPopover-Popover'
    >
      <div className='p-3'>
        <AssigneeSelect ticket={ticket} />
      </div>
    </HoverAndClickPopover>
  </div>
)

export default AssignTicketPopover
