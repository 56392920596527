import { PromptCollectionType } from 'components/ai/chat/ask_ai_modal/types'
import CheckBox from 'components/common/buttons/checkBox'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useFetch from 'components/common/hooks/useFetch'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import SmartTable from 'components/common/tables/smartTable'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import API from 'services/api'
import { audienceSummaryText } from 'utils/audience'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { Button, ButtonSecondarySmall } from 'components/common/buttons'
import useApi from 'components/common/hooks/useApi'
import { buildCompanyConfigurationPayload } from 'redux/slices/companyConfigurations'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import CdnSvg from 'components/common/cdnSvg'
import ConfirmationModal from 'components/common/modals/confirmationModal'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'
import FormEditableField from 'components/form_fields/formEditableField'

const I18N = i18nPath('views.admin.cleary_ai_settings.tabs.ask_ai')


const PromptCollectionsSection = () => {
  const {
    data: promptCollections,
    isLoading,
    paginationData,
    callApi: fetchPromptCollections,
    removeItem: removePromptCollectionFromList,
  } = useFetch<PromptCollectionType[]>(paginationData => API.admin.ai.chat.promptCollections.fetchAll({
    ...paginationData,
    perPage: 10,
  }))

  const targetingOptions = useTargetingOptions()

  const [deletePromptCollection, { isLoading: isDeleting }] = useApi(API.admin.ai.chat.promptCollections.destroy, {
    toastSuccessMessage: I18NCommon('deleted_successfully'),
    toastErrorMessage: I18NCommon('something_went_wrong'),
    onSuccess: () => {
      removePromptCollectionFromList(deletingPromptCollectionId!)
      setDeletingPromptCollectionId(null)
    },
  })

  const onDelete = () => {
    deletePromptCollection({ id: deletingPromptCollectionId! })
  }

  const [deletingPromptCollectionId, setDeletingPromptCollectionId] = useState<string | null>(null)

  const columns = [
    {
      header: I18N('prompt_collections.table.headers.name'),
      col: 'col-name',
      accessor: (promptCollection: PromptCollectionType) => (
        <Link to={`/admin/cleary_ai_settings/ask_ai/prompt_collections/${promptCollection.id}`}>
          {promptCollection.name}
        </Link>
      ),
    },
    {
      header: I18N('prompt_collections.table.headers.audience'),
      col: 'col-audience',
      accessor: (promptCollection: PromptCollectionType) => audienceSummaryText(
        promptCollection.targetingRules,
        targetingOptions
      ),
    },
    {
      header: I18N('prompt_collections.table.headers.prompts'),
      col: 'col-prompts',
      accessor: (promptCollection: PromptCollectionType) => promptCollection.prompts.length,
    },
    {
      col: 'col-actions',
      accessor: (promptCollection: PromptCollectionType) => (
        <>
          <DropdownMenuContainer className='default-dropdown'>
            <CardDropdownMenuItem
              primaryText={I18NCommon('delete')}
              onClick={() => setDeletingPromptCollectionId(promptCollection.id)}
              className='Card-dropdownMenuItem--danger'
              icon={<CdnSvg src='/images/trashIcon.svg' className='mr-2' />}
            />
          </DropdownMenuContainer>

          {deletingPromptCollectionId === promptCollection.id && (
            <ConfirmationModal
              visible={!!deletingPromptCollectionId}
              toggle={() => setDeletingPromptCollectionId(null)}
              onConfirmClick={onDelete}
              title={I18NCommon('confirmation_modal.title')}
              description={I18NCommon('confirmation_modal.description')}
              isSaving={isDeleting}
            />
          )}
        </>
      ),
    },
  ]


  return (
    <div className='white-card p-3 mt-4'>
      <div className='d-flex justify-content-between'>
        <div>
          <div className='d-flex align-items-center mb-2'>
            <h6 className='mb-0'>{I18N('prompt_collections.title')}</h6>
          </div>
          <p className='text-small text-secondary mb-3'>
            {I18N('prompt_collections.description')}
          </p>
        </div>

        <div>
          <ButtonSecondarySmall>
            <Link to='/admin/cleary_ai_settings/ask_ai/prompt_collections/new'>
              {I18N('prompt_collections.new_audience')}
            </Link>
          </ButtonSecondarySmall>
        </div>
      </div>

      <TableLoadingContainer isLoading={isLoading}>
        <SmartTable
          className='ChannelConfigurationsTable white-bg-table bordeless-table'
          data={promptCollections}
          columns={columns}
          totalCount={paginationData.total}
          perPage={paginationData.perPage}
          page={paginationData.page}
          pages={paginationData.totalPages}
          onPaginationClick={fetchPromptCollections}
        />
      </TableLoadingContainer>
    </div>
  )
}

const ChatSection = ({
  workingCopy, setWorkingCopy,
}) => (
  <div className='white-card p-3'>
    <h6 className='mb-0'>{I18N('title')}</h6>

    <CheckBox
      id='ai-search-enabled'
      checked={workingCopy.enabled}
      onChange={value => setWorkingCopy({ ...workingCopy, enabled: value })}
      label={I18N('enable_chatbot')}
    />

    {workingCopy.enabled && (
      <FormEditableField
        fieldName='rolloutPercentage'
        label={I18N('rollout_percentage')}
        currentValue={workingCopy.rolloutPercentage}
        inputType='number'
        className='mt-3'
        onChange={e => setWorkingCopy({ ...workingCopy, rolloutPercentage: e.target.value })}
      />
    )}
  </div>
)

const AskAiTab = () => {
  const { settings } = useCurrentCompany()
  const [workingCopy, setWorkingCopy] = useState(settings.aiChatbot)

  const [updateCompanyConfig, { isLoading: isSaving }] = useApi(API.admin.companyConfigurations.update, {
    toastSuccessMessage: I18NCommon('updated_successfully'),
    toastErrorMessage: I18NCommon('something_went_wrong'),
    onSuccess: () => window.location.reload(),
  })

  const areChangesPresent = !_.isEqual(settings.aiChatbot, workingCopy)

  const handleSaveChanges = () => {
    updateCompanyConfig(buildCompanyConfigurationPayload('ai_chatbot', workingCopy))
  }

  return (
    <section className='AskAiTab'>
      <ChatSection
        workingCopy={workingCopy}
        setWorkingCopy={setWorkingCopy}
      />

      {workingCopy.enabled && <PromptCollectionsSection />}

      <div className='d-flex justify-content-end mt-5'>
        <Button
          onClick={handleSaveChanges}
          showLoadingSpinner={isSaving}
          disabled={isSaving || !areChangesPresent}
        >
          {I18NCommon('save_changes')}
        </Button>
      </div>
    </section>
  )
}

export default AskAiTab
