import classNames from 'classnames'
import CustomTimeRange from 'components/analytics/common/customTimeRange'
import GroupByTabs from 'components/analytics/common/groupByTabs'
import { TimeRangeValue } from 'components/analytics/common/timeRangeFilter'
import useCompletedTargetingRules from 'components/analytics/helpers/useCompletedTargetingRules'
import AudienceModal from 'components/common/audience/audienceModal'
import AudienceRulesSelector from 'components/common/audience/audienceRulesSelector'
import { Button } from 'components/common/buttons'
import CompaniesAsyncSelect from 'components/common/companiesAsyncSelect'
import useApi from 'components/common/hooks/useApi'
import useAudienceUserIds from 'components/common/hooks/useAudienceUserIds'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'
import { useIsGlobalAnalytics } from 'pages/admin/global_analytics/globalAnalyticsProvider'
import React, { useEffect, useState } from 'react'
import API from 'services/api'
import TargetingRulesType from 'types/audience/targetingRules'
import { CompanyType } from 'types/company'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.platform_analytics.tab_header')


export const EstimatedAudience = ({ targetingRules }: {targetingRules: TargetingRulesType}) => {
  const { isLoading, audienceUserIds } = useAudienceUserIds({ targetingRules, moduleName: null })
  const [showAudienceModal, setShowAudienceModal] = useState(false)

  if (isLoading && audienceUserIds.length === 0) { return null }

  const text = I18N('estimated_audience_count', { count: audienceUserIds.length })

  return (
    <div className='EstimatedAudience mt-n3'>
      {audienceUserIds.length > 0 ? (
        <Button variant='link px-0 link-color' onClick={() => setShowAudienceModal(true)}>
          {text}
        </Button>
      ) : (
        <p className='text-secondary mb-0'>{text}</p>
      )}

      <AudienceModal
        userIds={audienceUserIds}
        closeModal={() => setShowAudienceModal(false)}
        showAudienceModal={showAudienceModal}
        summary=''
      />
    </div>
  )
}

interface TargetingFilterProps {
  targetingRules: TargetingRulesType
  setTargetingRules: (targetingRules: TargetingRulesType) => void
}

const TargetingFilter = ({
  targetingRules,
  setTargetingRules,
}: TargetingFilterProps) => {
  const targetingOptions = useTargetingOptions()
  const completedTargetingRules = useCompletedTargetingRules(targetingRules)

  return (
    <div className='d-flex align-items-start pr-4 flex-grow-1'>
      <span className='mr-2 mt-2 text-secondary flex-shrink-0'>
        {I18NCommon('filter_by')}
      </span>

      <div className='flex-grow-1'>
        <AudienceRulesSelector
          targetingRules={targetingRules}
          targetingOptions={targetingOptions}
          onChange={setTargetingRules}
          className='AnalyticsAudienceRulesSelector flex-grow-1'
          canTargetSpecificUsers={false}
          hasInlineAddRule
        />

        {completedTargetingRules && <EstimatedAudience targetingRules={completedTargetingRules} />}
      </div>
    </div>
  )
}

interface TabHeaderProps {
  targetingRules?: TargetingRulesType
  setTargetingRules?: (targetingRules: TargetingRulesType) => void
  setGroupBy?: (groupBy: string) => void
  periodStart?: string
  setPeriodStart?: (periodStart: string) => void
  periodEnd?: string
  setPeriodEnd?: (periodEnd?: string) => void
  className?: string
  timeRange?: TimeRangeValue
  setTimeRange?: (timeRange: TimeRangeValue) => void
  setCompanyId?: (companyId: string) => void
  companyId?: string
}

const TabHeader = ({
  targetingRules,
  setTargetingRules,
  setGroupBy,
  periodStart,
  setPeriodStart,
  periodEnd,
  setPeriodEnd,
  className,
  timeRange,
  setTimeRange,
  setCompanyId,
  companyId,
}: TabHeaderProps) => {
  const isGlobal = useIsGlobalAnalytics()

  const [innerCompany, setInnerCompany] = useState<CompanyType | null>(null)

  const handleSetCompany = (company: CompanyType | null) => {
    setInnerCompany(company || null)
    setCompanyId?.(company?.id || '')
  }

  const [loadCompany] = useApi(
    API.admin.company.fetch,
    {
      onSuccess: (company) => {
        setInnerCompany(company)
      },
    }
  )

  useEffect(() => {
    if (companyId && innerCompany?.id !== companyId) {
      loadCompany(companyId)
    }
  }, [companyId])

  return (
    <div className={classNames(className, 'grid-span-12 d-flex justify-content-between align-items-start')}>
      {(targetingRules && setTargetingRules) ? (
        <TargetingFilter setTargetingRules={setTargetingRules} targetingRules={targetingRules} />
      ) : <div />}

      <div className='d-flex align-items-center flex-shrink-0'>
        {isGlobal && (
          <CompaniesAsyncSelect
            onChange={handleSetCompany}
            value={innerCompany}
            className='mr-4'
          />
        )}
        {setGroupBy && (<GroupByTabs onChange={setGroupBy} />)}

        {periodStart && setPeriodStart && setPeriodEnd && setTimeRange && (
          <CustomTimeRange
            periodStart={periodStart}
            setPeriodStart={setPeriodStart}
            periodEnd={periodEnd}
            setPeriodEnd={setPeriodEnd}
            timeRange={timeRange}
            setTimeRange={setTimeRange}
          />
        )}
      </div>
    </div>
  )
}

export default TabHeader
