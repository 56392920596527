import BackButton from 'components/common/backButton'
import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import GeneralArticleSettings from 'pages/admin/articles/settings/generalArticleSettings'
import ArticleTypeSettings from 'pages/admin/articles/settings/articleTypeSettings'
import TagSettings from 'pages/admin/articles/settings/tagSettings'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import useQueryParams from 'components/common/hooks/useQueryParams'

const GENERAL = 'general'
const ARTICLE_TYPES = 'article_types'
const TAGS = 'tags'

const I18N = i18nPath('views.admin.article_settings')

const ArticleSettingsPage = () => {
  const { selectedTab } = useQueryParams()

  const tabs = [
    { id: GENERAL, text: I18N('general') },
    { id: ARTICLE_TYPES, text: I18N('article_types') },
    { id: TAGS, text: I18N('tags') },
  ]

  return (
    <div className='ArticleSettingsPage'>
      <header className='AdminHeader'>
        <h3 className='mb-0'>{I18N('title')}</h3>
      </header>

      <main className='AdminContent'>
        <BackButton
          url='/admin/articles/'
          className='link-color bold mb-4'
        />

        <HistoryChangingPillTabSelector
          tabs={tabs}
          className='mb-3'
        />

        {selectedTab === GENERAL && <GeneralArticleSettings />}
        {selectedTab === ARTICLE_TYPES && <ArticleTypeSettings />}
        {selectedTab === TAGS && <TagSettings />}
      </main>
    </div>
  )
}

export default ArticleSettingsPage
