import { ConversationType } from 'components/ai/chat/ask_ai_modal/types'
import ConversationHistory from 'components/analytics/ai_answers/conversationHistory'
import CardWithPaginatedTable from 'components/analytics/common/cardWithPaginatedTable'
import useFetch from 'components/common/hooks/useFetch'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'
import { RootSidebar } from 'components/common/sidebar'
import UserLink from 'components/common/userLink'
import { present } from 'components/common/utils'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import useDebouncedClose from 'hooks/shared/useDebouncedClose'
import { useIsGlobalAnalytics } from 'pages/admin/global_analytics/globalAnalyticsProvider'
import React, { useState } from 'react'
import API from 'services/api'
import { SimpleUserType } from 'types/user'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.platform_analytics.ai_answers.chat')


const columns = (setSelectedConversationId: (conversationId: string) => void, isGlobal: boolean) => ([
  isGlobal && {
    header: I18N('table.headers.company'),
    col: 'col-company',
    style: { width: 150 },
    accessor: (conversation: ConversationType) => conversation.company?.name,
  },
  {
    header: I18N('table.headers.title'),
    col: 'col-title',
    accessor: (conversation: ConversationType) => (
      <div
        className='AiAnswersTab__Answers__query truncate-text-at-2-lines'
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()

          setSelectedConversationId(conversation.id)
        }}
      >
        {conversation.title}
      </div>
    ),
  },
  {
    header: I18N('table.headers.author'),
    style: { width: '20%' },
    col: 'col-author',
    accessor: (conversation: ConversationType) => <UserLink user={conversation.user} />,
  },
  {
    header: I18N('table.headers.date'),
    style: { width: 200 },
    col: 'col-date',
    id: 'created_at',
    accessor: (conversation: ConversationType) => i18nMoment(conversation.createdAt).format('lll'),
  },
].filter(present))

interface ConversationsTableProps {
  periodStart: string
  periodEnd?: string
  companyId?: string
}

const ConversationsTable = ({ periodStart, periodEnd, companyId }: ConversationsTableProps) => {
  const isGlobal = useIsGlobalAnalytics()

  const [pagination, setPagination] = useState({
    page: 1, sortBy: 'created_at', sortDir: 'desc',
  })

  const [selectedConversationId, setSelectedConversationId] = useQueryParamState<string>({
    param: 'conversationId',
    initialValue: '',
  })
  const [selectedEmployee, setSelectedEmployee] = useState<SimpleUserType | null>(null)
  const [isClosingSidebar, onCloseSidebar] = useDebouncedClose(() => setSelectedConversationId(''))

  const {
    data: conversations = [],
    isLoading,
    paginationData,
  } = useFetch<ConversationType[]>(
    () => API.admin.ai.chat.conversations.fetchAll({
      ...pagination,
      perPage: 10,
      userId: selectedEmployee?.id,
      createdAtStart: periodStart,
      createdAtEnd: periodEnd,
      isGlobal,
      companyId,
    }),
    [pagination, selectedEmployee, periodStart, periodEnd, companyId]
  )

  return (
    <div className='AiAnswersTab__AskAIConversations grid-span-12'>
      <CardWithPaginatedTable
        title={I18N('title')}
        tableColumns={columns(setSelectedConversationId, isGlobal)}
        tableData={conversations}
        isLoading={isLoading}
        showPagination={(paginationData.totalPages || 0) > 1}
        page={paginationData.page || 1}
        totalPages={paginationData.totalPages || 0}
        onPaginationClick={setPagination}
        onSortChange={setPagination}
        sortKey={pagination.sortBy || 'created_at'}
        sortedAsc={pagination.sortDir === 'asc'}
        headerActions={!isGlobal && (
          <EmployeeSearch
            onChange={setSelectedEmployee}
            selectedEmployee={selectedEmployee}
          />
        )}
      />

      <RootSidebar
        onClose={onCloseSidebar}
        title=''
        className='AiAnswersTab__Conversations__Sidebar'
        isOpen={!!selectedConversationId && !isClosingSidebar}
      >
        {selectedConversationId && (
          <ConversationHistory
            conversationId={selectedConversationId}
            key={selectedConversationId}
          />
        )}
      </RootSidebar>
    </div>
  )
}

export default ConversationsTable
