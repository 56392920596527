import { Button } from 'components/common/buttons'
import CancelButton from 'components/common/cancelButton'
import CdnSvg from 'components/common/cdnSvg'
import useApi from 'components/common/hooks/useApi'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useUpdatableState from 'components/common/hooks/useUpdatableState'
import { RootSidebar } from 'components/common/sidebar'
import { SIMPLE_EDITOR_CONFIGURATION } from 'components/common/tiptap/configurations'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { SMALL_TOOLBAR_WITH_ATTACHMENTS } from 'components/common/tiptap/toolbar/toolbarVariations'
import DatePicker from 'components/form_fields/datePicker'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import moment from 'moment'
import React from 'react'
import API from 'services/api'
import { TicketType } from 'types/ticketing/ticket'
import { SimpleUserType } from 'types/user'
import { i18nPath } from 'utils/i18nHelpers'

type Props = {
  isOpen: boolean
  onClose: () => void
  onCreate: () => void
}

const I18N = i18nPath('views.ticketing.create_ticket_form')

const generateDefaultWorkingCopy = (currentUser: SimpleUserType): Partial<TicketType> => ({
  title: '',
  user: undefined,
  assignee: currentUser,
  dueDate: moment().add(7, 'day').toISOString(),
  description: '',
})

const CreateTicketSidebar = ({ isOpen, onClose, onCreate }: Props) => {
  const currentUser = useCurrentUser()
  const [
    workingCopy, setWorkingCopy, updateWorkingCopy,
  ] = useUpdatableState<Partial<TicketType>>(generateDefaultWorkingCopy(currentUser))

  const {
    title, user, assignee, dueDate, description,
  } = workingCopy

  const isMissingRequiredFields = !title || !user

  const [createTicket, { isLoading }] = useApi(API.admin.ticketing.tickets.create, {
    onSuccess: () => {
      onCreate()
      onClose()
      setWorkingCopy(generateDefaultWorkingCopy(currentUser))
    },
    toastSuccessMessage: I18N('ticket_created'),
  })

  const handleCreateTicket = () => {
    createTicket({
      title,
      userId: user?.id,
      assigneeId: assignee?.id,
      dueDate,
      description,
      origin: 'admin',
    })
  }

  return (
    <RootSidebar
      title={(
        <div className='p-1'>
          <input
            type='text'
            value={title}
            onChange={e => updateWorkingCopy({ title: e.target.value })}
            className='border-0 w-100 text-large font-weight-700 px-0'
            placeholder={I18N('title_placeholder')}
            autoFocus
          />
          <div className='text-secondary'>
            <CdnSvg src='/images/logos/clearyLogoNoText.svg' className='mr-1' />
            {I18N('created_manually')}
          </div>
        </div>
      )}
      onClose={onClose}
      isOpen={isOpen}
    >
      <div className='CreateTicketSidebar d-flex gap-3 flex-column p-1'>
        <div className='w-50'>
          <label className='text-small text-secondary'>
            {I18N('requester')}
          </label>
          <EmployeeSearch
            selectedEmployee={user}
            onChange={employee => updateWorkingCopy({ user: employee })}
          />
        </div>
        <div className='d-flex gap-3'>
          <div className='w-50'>
            <label className='text-small text-secondary'>
              {I18N('assign_to')}
            </label>
            <EmployeeSearch
              selectedEmployee={assignee}
              onChange={employee => updateWorkingCopy({ assignee: employee })}
            />
          </div>
          <div className='w-50'>
            <label className='text-small text-secondary'>
              {I18N('due_date')}
            </label>
            <DatePicker
              selected={dueDate ? moment(dueDate) : undefined}
              onChange={date => updateWorkingCopy({ dueDate: date?.toISOString() })}
              className='w-100'
            />
          </div>
        </div>
        <TiptapEditor
          className='TipTapEditor bordered'
          editorContentClassName='p-3'
          onChange={html => updateWorkingCopy({ description: html })}
          html={description}
          configuration={{
            ...SIMPLE_EDITOR_CONFIGURATION,
            imagesEnabled: true,
            filesEnabled: true,
            userMentionsEnabled: true,
            placeholder: I18N('description_placeholder'),
          }}
          toolbarItems={SMALL_TOOLBAR_WITH_ATTACHMENTS}
        />
        <div className='d-flex justify-content-end gap-3 align-items-center'>
          <CancelButton
            onClick={onClose}
          />
          <Button
            onClick={handleCreateTicket}
            showLoadingSpinner={isLoading}
            disabled={isLoading || isMissingRequiredFields}
          >
            {I18N('create_ticket')}
          </Button>
        </div>
      </div>
    </RootSidebar>
  )
}

export default CreateTicketSidebar
