import { useAskAiContext } from 'components/ai/chat/ask_ai_modal/askAiContext'
import { useCreateNewChatConversationMessage } from 'components/ai/chat/ask_ai_modal/hooks/useCreateNewChatConversationMessage'
import { NewConversationOrigin } from 'components/ai/chat/ask_ai_modal/types'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import TipTapRichTextView from 'components/common/tiptap/tiptapRichTextView'
import { marked } from 'marked'
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useHistory } from 'react-router-dom'

const AiAnswerContent = ({ content }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [questionsPortals, setQuestionsPortals] = useState<React.ReactPortal[]>([])

  const { inChat, onNewMessage, newConversationTrackingRef } = useAskAiContext()
  const history = useHistory()
  const currentCompany = useCurrentCompany()
  const createNewChatConversationMessage = useCreateNewChatConversationMessage()
  const chatbotEnabled = currentCompany.settings.aiChatbot?.enabled

  const handleQuestionClick = (question: string) => {
    if (inChat) {
      onNewMessage({ content: question })
    } else if (chatbotEnabled) {
      createNewChatConversationMessage({
        message: question,
        origin: NewConversationOrigin.SEARCH_RESULT_PROMPT,
      })
    } else {
      history.push(`/search?query=${question}&filter=all`)
    }
  }

  useEffect(() => {
    if (ref.current) {
      const questions = Array.from(ref.current.querySelectorAll('question'))
      const portals = questions.map(question => (
        ReactDOM.createPortal(
          <a onClick={() => handleQuestionClick(question.textContent!)}>{question.textContent}</a>,
          question
        )
      ))
      questions.forEach(question => question.textContent = '')
      setQuestionsPortals(portals)
    }
  }, [content])

  return (
    <div ref={ref}>
      <TipTapRichTextView html={marked.parse(content || '') as string} />
      {questionsPortals}
    </div>
  )
}

export default AiAnswerContent
